import { Supplier } from '../models/Supplier';
import { ScannedDocument } from '../models/ScannedDocument';

const defaultInitialState: ScannedDocument = {
  supplier: null,
  reference: '',
  items: [],
};

const initialState = loadInfo();

interface ClearAllAction {
  type: 'CLEAR_ALL';
}

interface NewItemAction {
  type: 'NEW_ITEM';
  item: string;
}

interface SetSupplierAction {
  type: 'SET_SUPPLIER';
  supplier: Supplier;
}

interface SetReferenceAction {
  type: 'SET_REFERENCE';
  reference: string;
}

interface DeleteItemAction {
  type: 'DELETE_ITEM';
  item: string;
}

type ReceptacleAction =
  | ClearAllAction
  | NewItemAction
  | SetSupplierAction
  | SetReferenceAction
  | DeleteItemAction;

export function receipting(
  state = initialState,
  action: ReceptacleAction,
): ScannedDocument {
  const newState = reducer(state, action);
  saveInfo(newState);
  return newState;
}

function reducer(
  state: ScannedDocument,
  action: ReceptacleAction,
): ScannedDocument {
  switch (action.type) {
    case 'SET_SUPPLIER':
      return {
        ...state,
        supplier: action.supplier,
      };
    case 'NEW_ITEM':
      return {
        ...state,
        items: state.items.concat([action.item]),
      };
    case 'CLEAR_ALL': {
      return defaultInitialState;
    }
    case 'SET_REFERENCE':
      return {
        ...state,
        reference: action.reference,
      };
    case 'DELETE_ITEM':
      return {
        ...state,
        items: state.items.filter((item: string) => item !== action.item),
      };
    default:
      return state;
  }
}

function saveInfo(state: ScannedDocument): void {
  localStorage.setItem('receipting-info', JSON.stringify(state));
}

function loadInfo(): ScannedDocument {
  const info = localStorage.getItem('receipting-info');
  return info ? JSON.parse(info) : defaultInitialState;
}
