import React, { useCallback, useState } from 'react';
import { ReceiptLinesDisplay } from '../ReceiptLinesDisplay';
import {
  Container,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
  ButtonGroup,
  Button,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { Supplier } from '../../../models/Supplier';

import styled from 'styled-components';

import { AppState } from '../../../state';
import gql from 'graphql-tag';
import { useSuppliersQuery } from '../../../generated/graphql';
import {
  BackConfirmDialog,
  useReceiptingBackToHome,
} from '../BackConfirmDialog';
import { DelayedLinearProgress } from '../../DelayedLinearProgress';

gql`
  query Suppliers {
    suppliers: receiptSuppliers {
      id
      name
    }
  }
`;

export interface BackButtonPrompt {
  open: boolean;
  close: () => void;
}

export const ReceiptCreator: React.FC = () => {
  const dispatch = useDispatch();
  const handleBack = useReceiptingBackToHome();

  const { loading, data } = useSuppliersQuery({
    fetchPolicy: 'cache-and-network',
  });

  const suppliers = data?.suppliers ?? [];
  const supplier = useSelector<AppState, Supplier | null>(
    (s) => s.receipting.supplier,
  );

  // If they load the page and there is already a supplier, send them straight
  // to the scanning page
  const [scanning, setScanning] = useState(supplier != null);
  const [confirmingBack, setConfirmingBack] = useState(false);

  const handleConfirmBack = useCallback(() => {
    setConfirmingBack(true);
  }, []);

  return (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: 12,
      }}
    >
      {scanning ? (
        <ReceiptLinesDisplay handleBack={handleConfirmBack} />
      ) : (
        <ScanHeaderStyle>
          <div style={{ marginBottom: 12 }}>
            <Typography variant="h5" className="Heading" color="primary">
              Enter Required Fields
            </Typography>
            <div className="FieldHeader">Supplier:</div>
            <SelectStyled
              value={supplier?.id ?? ''}
              onChange={(e: any) => {
                dispatch({
                  type: 'SET_SUPPLIER',
                  supplier: {
                    id: e.target.value,
                    name: suppliers.find((r) => r.id === e.target.value)?.name,
                  },
                });
              }}
              placeholder="Supplier"
              fullWidth
              input={<OutlinedInput name="supplier" id="supplier" />}
            >
              {suppliers.map((supplier) => (
                <MenuItem value={supplier.id} key={supplier.id}>
                  {supplier.name}
                </MenuItem>
              ))}
            </SelectStyled>
            {loading && <DelayedLinearProgress style={{ marginTop: -4 }} />}
          </div>
          <ButtonStyle>
            <ButtonGroup
              className="Buttons"
              variant="contained"
              disableElevation
              color="primary"
              aria-label="text primary button group"
              size="large"
              style={{ justifyContent: 'center' }}
            >
              <Button
                style={{ width: '100%', height: '32px' }}
                color="error"
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>

              <Button
                style={{ width: '100%', height: '32px' }}
                color="primary"
                variant="contained"
                onClick={() => setScanning(true)}
                disabled={supplier == null}
              >
                Scan
              </Button>
            </ButtonGroup>
          </ButtonStyle>
        </ScanHeaderStyle>
      )}
      <BackConfirmDialog
        open={confirmingBack}
        onComplete={(action) =>
          action === 'back' ? handleBack() : setConfirmingBack(false)
        }
      />
    </Container>
  );
};

export const ButtonStyle = styled.div`
  .Buttons {
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    align-self: center;
    width: 80%;
    margin: 0 auto;
  }
`;

const ScanHeaderStyle = styled.div`
  margin-top: 40px;
  align-self: center;
  .FieldHeader {
    margin-bottom: 7px;
  }
  .Heading {
    margin-bottom: 35px;
  }
`;

const SelectStyled = styled(Select)`
  .MuiOutlinedInput-input {
    padding: 8px;
  }
`;
