import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import styled from 'styled-components';

export interface OptiLineDisplayProps {
  packId: ID;
  productCode: string;
  deleteItem: (packId: ID) => void;
}

export const OptiLineDisplay: React.FC<OptiLineDisplayProps> = ({
  packId,
  productCode,
  deleteItem,
}) => (
  <OptiItemStyle>
    <ListItem
      onClick={() => deleteItem(packId)}
      key={packId}
      dense
      divider
      alignItems="flex-start"
      button
    >
      <ListItemText primary={packId} secondary="Pack ID" />
      <ListItemText
        className="ProductCode"
        style={{ paddingLeft: 20 }}
        primary={productCode}
        secondary="Product Code"
      />
    </ListItem>
  </OptiItemStyle>
);

const OptiItemStyle = styled.div`
  .DeleteIcon {
    transform: translate(7px, -5px);
  }
  .MuiIconButton-root {
    padding: 0px;
  }
  .MuiIconButton-edgeEnd {
    margin-right: 0px;
  }
  .ProductCode {
    text-align-last: right;
  }
`;
