import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import * as Integrations from '@sentry/integrations';
import './global.css';
import 'sweetalert2/dist/sweetalert2.css';
import { Error } from './components/Error';
import { env, releaseProfile } from './runtime-environment';
import apollo from './services/apollo';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { UserSelectors } from './selectors/user';
import { ApolloProvider } from '@apollo/client';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { Route } from 'react-router-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { store } from './store';
import SelectWorkstation from './components/Pages/SelectWorkstation';
import OptiAdmin from './components/Opti/Pages/OptiAdmin';
import FilletingAdmin from './components/Filleting/Pages/FilletingAdmin';
import KilnAdmin from './components/Kiln/Pages/KilnAdmin';
import KilnMoveIn from './components/Kiln/Pages/KilnMoveIn';
import OptiScan from './components/Opti/Pages/OptiScan';
import LoginPage from './components/Pages/LoginPage';
import StocktakeAdmin from './components/Stocktake/Pages/StocktakeAdmin';
import StocktakeScan from './components/Stocktake/Pages/StocktakeScan';
import { VersionNumber } from './components/VersionNumber';
import { ReceiptCreator } from './components/Receipting/Pages/ReceiptCreator';
import ReceiptFinish from './components/Receipting/Pages/ReceiptFinish';
import { OfflineSimulator } from './components/OfflineSimulator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import styled from 'styled-components';

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * The entry point of the app.  If the user is not logged in, they will return
 * to the Login page
 */
export const AppInner: React.FC = () => {
  const loggedIn = useSelector(UserSelectors.loggedIn);
  if (!loggedIn) return <LoginPage />;

  return (
    <>
      <SentryRoute exact path="/" component={SelectWorkstation} />
      <SentryRoute exact path="/workstations" component={SelectWorkstation} />
      <SentryRoute exact path="/opti/:optiStation" component={OptiAdmin} />
      <SentryRoute
        exact
        path="/opti/:optiStation/:runId/:date"
        component={OptiScan}
      />
      <SentryRoute exact path="/stocktake" component={StocktakeAdmin} />
      <SentryRoute
        exact
        path="/stocktake/:stocktakeId"
        component={StocktakeScan}
      />
      <SentryRoute exact path="/filleting/:runId" component={FilletingAdmin} />
      <SentryRoute exact path="/kilns/:locationName" component={KilnAdmin} />
      <SentryRoute
        exact
        path="/kilns/:kilnName/:kilnCharge/"
        component={KilnMoveIn}
      />
      <SentryRoute exact path="/receipting" component={ReceiptCreator} />
      <SentryRoute exact path="/receipting/finish" component={ReceiptFinish} />
    </>
  );
};

const history = createBrowserHistory();

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://de10c5aa04944f77baad8dc41b3fdf34@o925760.ingest.sentry.io/5933693',
    release: `timbersmart-runner@${env.version}`,
    environment: releaseProfile(),
    normalizeDepth: 10,
    // We add them with more detail through apollo-link-sentry
    beforeBreadcrumb: excludeGraphQLFetch,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: ['localhost', '.genia.co.nz'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Integrations.CaptureConsole({
        levels: ['warn', 'error'],
      }),
      new Integrations.Offline(),
    ],
    tracesSampleRate: 0.05,
  });
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#375954',
      light: '#638680',
      dark: '#0d302b',
    },
    secondary: {
      main: '#d8ccc2',
      light: '#fffff5',
      dark: '#a69b92',
    },
    error: {
      main: '#bc2f5e',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
});

interface RootContainerProps {
  env: string;
}

const RootContainer = styled.div<RootContainerProps>`
  height: 100%;
  ${props => props.env === 'staging' && 'border: 5px solid red;'}
`;

export const Root: React.FC = () => (
  <RootContainer env={env.profile}>
    <Sentry.ErrorBoundary
      fallback={<Error fullscreen errorText="Uh-oh. The app crashed." />}
      showDialog
    >
      <ReduxProvider store={store}>
        <ApolloProvider client={apollo()}>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Router history={history as any}>
                <VersionNumber />
                <OfflineSimulator />
                <AppInner />
              </Router>
            </ThemeProvider>
          </StylesProvider>
        </ApolloProvider>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  </RootContainer>
);

ReactDOM.render(<Root />, document.getElementById('root'));
