import {
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import gql from 'graphql-tag';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useKilnContractCustomersQuery } from '../../generated/graphql';
import { Error } from '../Error';

const MM_PER_INCH = 25.4;

gql`
  query KilnContractCustomers {
    result: kilnContractCustomers {
      id
      name
    }
  }
`;

export interface ContractDetailsFormProps {
  handleCreateContractCharge: (
    customer: string,
    reference: string,
    thickness: number,
    geniaFilleted: boolean,
  ) => void;
}

type Thickness =
  | { unit: 'mm'; value: string }
  | { unit: 'in'; numerator: string; denominator: string };

function getThicknessMm(thickness: Thickness): number | null {
  if (thickness.unit === 'mm') {
    const v = Number(thickness.value);
    if (!isNaN(v) && v > 0) return v;
    return null;
  }

  const num = Number(thickness.numerator);
  const denom = Number(thickness.denominator);
  if (isNaN(num) || isNaN(denom) || num <= 0 || denom <= 0) {
    return null;
  }

  const inchThickness = (num / denom) * MM_PER_INCH;
  return Math.round(inchThickness * 100) / 100;
}

export const ContractDetailsForm: React.FC<ContractDetailsFormProps> = ({
  handleCreateContractCharge,
}) => {
  const [thickness, setThickness] = useState<Thickness>({
    unit: 'mm',
    value: '',
  });
  const [customer, setCustomer] = useState('');
  const [reference, setReference] = useState('');
  const [geniaFilleted, setGeniaFilleted] = useState(false);

  const parsedThickness = getThicknessMm(thickness);

  const handleChangeThicknessUnit = (
    event: React.MouseEvent<HTMLElement>,
    newThicknessUnit: 'mm' | 'in',
  ) => {
    setThickness((th) => {
      if (th.unit === newThicknessUnit) return th;
      if (newThicknessUnit === 'mm') {
        return { unit: 'mm', value: '' };
      }
      return {
        unit: 'in',
        numerator: '',
        denominator: '',
      };
    });
  };

  const history = useHistory();
  const focusInput = (ref: RefObject<HTMLInputElement>) => {
    ref.current && ref.current.focus();
  };

  const handleSelectCustomer = (event: SelectChangeEvent<string>) => {
    setCustomer(event.target.value as string);
  };
  const referenceRef = useRef<HTMLInputElement>(null);
  const thicknessRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    customer !== '' && focusInput(referenceRef);
  }, [customer]);

  const { data: customers, loading, error } = useKilnContractCustomersQuery();
  if (loading || !customers?.result) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          left: 'calc(50% - 20px)',
          top: 'calc(50% - 20px)',
        }}
      />
    );
  }
  if (error) return <Error />;

  return (
    <AdminFormStyle>
      <FormControl className="SelectCustomer">
        <InputLabel id="customer">Customer</InputLabel>
        <Select
          labelId="customer"
          id="customer"
          value={customer}
          onChange={handleSelectCustomer}
        >
          {customers?.result.map((c) => (
            <MenuItem key={c.id} value={c.name}>
              {c.name}
            </MenuItem>
          ))}
        </Select>

        <TextField
          id="Reference"
          name="Reference"
          label="Reference"
          inputRef={referenceRef}
          value={reference}
          onKeyDown={(e) => e.key === 'Enter' && focusInput(thicknessRef)}
          autoComplete="off"
          onChange={(e) => setReference(e.target.value)}
        />
        <div className="thickness">
          {thickness.unit === 'mm' ? (
            <TextField
              id="Thickness"
              inputRef={thicknessRef}
              name="Thickness"
              label="Thickness"
              className="thickness-input"
              type="number"
              value={thickness.value}
              autoComplete="off"
              onChange={(e) =>
                setThickness({ unit: 'mm', value: e.target.value })
              }
            />
          ) : (
            <div className="inches-container">
              <TextField
                id="outlined-size-normal"
                type="number"
                variant="outlined"
                value={thickness.numerator}
                className="numerator"
                autoComplete="off"
                size="small"
                onChange={(e) =>
                  setThickness((th) => ({ ...th, numerator: e.target.value }))
                }
              />
              <div className="bar" />
              <TextField
                id="outlined-size-normal"
                type="number"
                variant="outlined"
                value={thickness.denominator}
                autoComplete="off"
                className="denominator"
                size="small"
                onChange={(e) =>
                  setThickness((th) => ({ ...th, denominator: e.target.value }))
                }
              />
            </div>
          )}

          <ToggleButtonGroup
            color="primary"
            value={thickness.unit}
            exclusive
            orientation="vertical"
            onChange={handleChangeThicknessUnit}
          >
            <ToggleButton value="mm">mm</ToggleButton>
            <ToggleButton value="inches">inches</ToggleButton>
          </ToggleButtonGroup>
        </div>

        <FormControlLabel
          control={
            <Checkbox
              checked={geniaFilleted}
              onChange={(e) => setGeniaFilleted(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Filleted by Genia"
          sx={{ '& .MuiSvgIcon-root': { fontSize: 38 } }}
        />
      </FormControl>
      <ButtonGroup
        className="Buttons"
        variant="contained"
        disableElevation
        color="primary"
        aria-label="text primary button group"
        size="large"
        style={{ justifyContent: 'center' }}
      >
        <Button color="error" onClick={() => history.push('/')}>
          Back
        </Button>
        <Button
          disabled={!customer || parsedThickness == null}
          color="primary"
          onClick={() => {
            handleCreateContractCharge(
              customer,
              reference,
              parsedThickness!,
              geniaFilleted,
            );
          }}
        >
          Create Charge
        </Button>
      </ButtonGroup>
    </AdminFormStyle>
  );
};

const AdminFormStyle = styled.div`
  align-self: center;
  .DialogContainer {
    text-align: center;
    height: 100vh;
  }
  .Heading {
    text-align: center;
  }
  .Buttons {
    margin: 0 auto;
    align-self: center;
    justify-content: center;
    right: 0;
    left: 0;
  }

  .SelectCustomer {
    width: 250px;
    margin: 20px auto;
    display: flex;
    .thickness {
      display: flex;
      margin: 20px 0rem;
      .inches-container {
        padding-right: 6rem;
        .denominator,
        .numerator {
          padding: 0px 1rem;
        }
        .bar {
          margin: 0.4rem 0rem;
          height: 2px;
          background: darkgray;
        }
      }
      .thickness-input {
        padding-right: 4rem;
      }
    }
  }

  .sigImage {
    margin-top: 20px;
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
  }
`;
