import React, { useState } from 'react';
import { AppState } from '../../state';
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonGroup,
  Button,
  Input,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ReceiptLineDisplay } from './ReceiptLineDisplay';
import styled from 'styled-components';
import { ButtonStyle } from './Pages/ReceiptCreator';
import { useHistory } from 'react-router-dom';

export interface ReceiptLinesDisplayProps {
  handleBack: () => void;
}

type ExtraMessageDisplay = {primaryMessage: string, secondaryMessage: string}

export const ReceiptLinesDisplay: React.FC<ReceiptLinesDisplayProps> = ({
  handleBack,
}) => {
  const dispatch = useDispatch();
  const appendLine = (barcode: string) =>
    dispatch({
      type: 'NEW_ITEM',
      item: barcode,
    });

  const removeLine = (id: string) =>
    dispatch({
      type: 'DELETE_ITEM',
      item: id,
    });

  // error handling state
  const [erroneousPack, setErroneousPack] = useState< ExtraMessageDisplay | null >(null);
  const [showExtraMessage, setShowExtraMessage] = useState(false);

  const lines = useSelector<AppState, string[]>((s) => s.receipting.items);
  const [barcode, setBarcode] = useState('');

  const handleDelete = (deleteId: string | null) => {
    removeLine(deleteId!);
    setDeleteConfirmationId(null);
  };

  const onSubmitBarcode = () => {
    setShowExtraMessage(false);
    setErroneousPack(null);
    if (barcode === '') return;
    // Duplicate entry
    if (lines.find((l) => l === barcode)) {
      const ERRONEOUS_SCAN: ExtraMessageDisplay = {
        primaryMessage: 'pack is already scanned',
        secondaryMessage: 'please scan next pack',
      }
      // state updates with duplicate object and status
      setErroneousPack(ERRONEOUS_SCAN);
      setShowExtraMessage(true);
      setBarcode('');
      return;
    }
    appendLine(barcode);
    setBarcode('');
  };

  const onBarcodeKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') onSubmitBarcode();
  };

  const [deleteConfirmationId, setDeleteConfirmationId] = useState<
    string | null
  >(null);
  const history = useHistory();
  const lineToDelete = lines.find((l: string) => l === deleteConfirmationId);

  const reference = useSelector<AppState, string>(
    (s) => s.receipting.reference,
  );
  
  return (
    <ScanStyle>
      <div className="FieldContainer">
        <Input
          id="referenceId"
          placeholder="Please enter a reference"
          className="Reference"
          autoComplete="off"
          name="referenceId"
          value={reference || ''}
          onChange={(e) => {
            dispatch({
              type: 'SET_REFERENCE',
              reference: e.target.value,
            });
          }}
        />

        <TextField
          autoComplete="off"
          autoFocus
          id="barcode"
          className="Scan"
          placeholder={`Scan Barcode... (${lines.length})`}
          value={barcode}
          onChange={(e) => setBarcode(e.target.value.toUpperCase())}
          onKeyUp={onBarcodeKeyUp}
          fullWidth
        />
      </div>

      <List className="ReceiptSection">
      {showExtraMessage && (
          <ListItem divider alignItems="center" style={{ color: 'red', backgroundColor: 'lightgrey', textAlign: 'center' }}>     
            <ListItemText primary={erroneousPack?.primaryMessage} secondary={erroneousPack?.secondaryMessage} style={{color: 'red'}} />      
          </ListItem>
      )}
        {lines &&
          lines
            .slice()
            .reverse()
            .map((line) => (
              <ReceiptLineDisplay
                id={line}
                barcode={line}
                remove={setDeleteConfirmationId}
              />
            ))}
      </List>
      <ButtonStyle>
        <ButtonGroup
          className="Buttons"
          variant="contained"
          disableElevation
          color="primary"
          aria-label="text primary button group"
          size="large"
          style={{ justifyContent: 'center' }}
        >
          <Button
            style={{ width: '100%', height: '32px' }}
            color="error"
            variant="contained"
            onClick={() => handleBack()}
          >
            Back
          </Button>

          <Button
            style={{ width: '100%', height: '32px' }}
            color="primary"
            variant="contained"
            onClick={() => history.push('/receipting/finish')}
            disabled={lines.length === 0 || reference.trim() === ''}
          >
            Finish
          </Button>
        </ButtonGroup>
      </ButtonStyle>
      <Dialog open={deleteConfirmationId !== null}>
        <DialogTitle>Delete?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove
            {lineToDelete !== undefined ? ` (${lineToDelete})` : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationId(null)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(deleteConfirmationId)}
            color="error"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </ScanStyle>
  );
};

const ScanStyle = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  margin-top: 18px;

  .ReceiptSection {
    overflow-y: scroll;
    max-height: 280px;
  }

  .FieldContainer {
    margin-left: 10px;
    .Scan,
    .Reference {
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;
