import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  ButtonGroup,
  Typography,
  List,
  ListItemText,
  ListItem,
  TextField,
  FormControl,
} from '@mui/material';

import { Loading } from '../../Loading';
import { Error } from '../../Error';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';

import dateFormat from 'dateformat';
import {
  useGetStocktakesQuery,
  useStocktakeCreateMutation,
} from '../../../generated/graphql';
import Swal from 'sweetalert2';
import { DelayedLinearProgress } from '../../DelayedLinearProgress';

gql`
  query GetStocktakes($onlyActive: Boolean) {
    stocktakes(onlyActive: $onlyActive) {
      id
      dateStarted
      dateFinished
      location
    }
  }
`;

gql`
  mutation StocktakeCreate($input: StocktakeCreateInput!) {
    stocktakeCreate(input: $input)
  }
`;

/** The admin page of the Stocktake process, where a unique user will create a new Stocktake run */
const StocktakeAdmin: React.FC = () => {
  const history = useHistory();
  const { data, loading, error, refetch } = useGetStocktakesQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      onlyActive: true,
    },
  });
  const stocktakeRuns = data?.stocktakes;
  const [stocktakeLocation, setStocktakeLocation] = useState('');
  const [
    stocktakeCreate,
    { loading: creatingStocktake },
  ] = useStocktakeCreateMutation();
  const [openLocation, setOpenLocation] = useState(false);
  useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading && !data) return <Loading />;
  if (error || !data) {
    return <Error fullscreen />;
  }
  if (creatingStocktake) return <Loading message="Creating Stocktake..." />;

  const handleClose = () => {
    setOpenLocation(false);
  };

  const renderError = (msg: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oh no!',
      text: msg,
    });
  };
  const createStocktakeRun = () => {
    stocktakeCreate({
      variables: {
        input: {
          location: stocktakeLocation,
        },
      },
    })
      .then((s: any) => {
        if (s.errors) {
          Sentry.captureMessage(
            'Failed to create stocktake',
            Sentry.Severity.Error,
          );
          handleClose();
          renderError('Could not create Stocktake run!');
        } else {
          handleClose();
          history.push(`/stocktake/${s.data.stocktakeCreate}`);
        }
      })
      .catch((error) => {
        handleClose();
        Sentry.captureException(error);
        renderError('Could not create Stocktake run!');
      });
  };

  return (
    <Container>
      {loading && <DelayedLinearProgress wholeScreen />}
      <Typography variant="h5" className="Heading">
        Select Stocktake
      </Typography>
      {stocktakeRuns?.length === 0 && (
        <span className="NoActive">
          There are no active runs for this workstation.
        </span>
      )}

      <List dense={true} component="nav" className="StocktakeRuns">
        {stocktakeRuns?.map((r) => (
          <Link
            key={r.id}
            to={`stocktake/${r.id}`}
            style={{ all: 'unset', width: '100%' }}
          >
            <ListItem dense={true} divider alignItems="flex-start" button>
              <ListItemText primary={r.id} secondary="ID" />
              <ListItemText primary={r.location} secondary="Location" />
              <ListItemText
                className="StartDate"
                primary={dateFormat(r.dateStarted, 'dd/mm/yyyy')}
                secondary="Start Date"
              />
            </ListItem>
          </Link>
        ))}
      </List>

      <ButtonGroup
        variant="contained"
        color="primary"
        disableElevation
        aria-label="text primary button group"
        size="large"
        className="Buttons"
      >
        <Button color="error" onClick={() => history.push('/')}>
          Back
        </Button>
        <Button
          size="large"
          color="primary"
          onClick={() => setOpenLocation(true)}
        >
          Create New
        </Button>
      </ButtonGroup>

      <Dialog
        fullScreen
        open={openLocation}
        onClose={() => setOpenLocation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StocktakeLocation>
          <DialogContent className="DialogContainer">
            <Typography variant="h6" className="Header">
              Fill in Location
            </Typography>
            <FormControl required className="FormStyle">
              <TextField
                id="Location"
                label="Location"
                onChange={(e) => setStocktakeLocation(e.target.value)}
                value={stocktakeLocation}
                autoFocus
              />
            </FormControl>
          </DialogContent>

          <DialogActions className="Buttons">
            <ButtonGroup
              variant="contained"
              disableElevation
              color="primary"
              aria-label="text primary button group"
              size="large"
            >
              <Button
                color="error"
                onClick={() => {
                  setStocktakeLocation('');
                  setOpenLocation(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={stocktakeLocation === ''}
                onClick={() => createStocktakeRun()}
              >
                Begin
              </Button>
            </ButtonGroup>
          </DialogActions>
        </StocktakeLocation>
      </Dialog>
    </Container>
  );
};

export default StocktakeAdmin;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .StartDate {
    text-align: end;
  }

  .NoActive {
    margin: 50px auto;
    font-size: 20px;
    text-align: center;
  }

  .Heading {
    text-align: center;
    margin-top: 15px;
  }
  .Buttons {
    margin: 0 auto;
    align-self: center;
    justify-content: center;
  }

  .StocktakeRuns {
    margin-top: 15px;
    height: 325px;
    overflow-y: scroll;
  }
`;

const StocktakeLocation = styled.div`
  .DialogContainer {
    text-align: center;
    height: 100vh;
    max-width: 600px;
    margin: auto auto;
    margin-top: 10px;
    display: block;
  }
  .Header {
    text-align: center;
    margin-bottom: 10px;
  }
  .Buttons {
    position: absolute;
    bottom: 10px;
    align-self: center;
    justify-content: center;
    left: 0;
    right: 0;
  }

  .FormStyle {
    margin: 40px auto;
    display: flex;
    max-width: 300px;
    flex-direction: column;
  }
`;
