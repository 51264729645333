import React, { useState, useEffect } from 'react';

import { Typography, TextField, Fade } from '@mui/material';
import { useDispatch } from 'react-redux';
import { User } from '../../models/User';
import styled from 'styled-components';
import {
  UserActionCreators,
  getCachedPin,
  setCachedPin,
  clearCachedPin,
} from '../../state/user';
import { MeQuery, MeQueryVariables, MeDocument } from '../../generated/graphql';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { releaseProfile } from '../../runtime-environment';
import { Loading } from '../Loading';

enum LoginStatus {
  NotStarted,
  Pending,
  Success,
  Fail,
}

gql`
  query Me {
    me {
      id
      givenName
      familyName
    }
  }
`;

/** This deals with logging-in, used in several other MLC apps */
export const LoginPage = () => {
  const [pin, setPin_] = useState('');
  const [loginStatus, setLoginStatus] = useState(LoginStatus.Pending);
  const [errorText, setErrorText] = useState('');

  const setLoginFailure = (error: string) => {
    setPin_('');
    setErrorText(error);
    setLoginStatus(LoginStatus.Fail);
  };

  const dispatch = useDispatch();

  // Try and use the cached pin if there is one to automatically log in
  useEffect(() => {
    const cachedPin = getCachedPin();
    if (cachedPin !== null) startLogin(cachedPin);
    else setLoginStatus(LoginStatus.NotStarted);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const client = useApolloClient();
  const startLogin = (pin: string) => {
    setLoginStatus(LoginStatus.Pending);
    setCachedPin(pin);
    client
      .query<MeQuery, MeQueryVariables>({
        query: MeDocument,
        fetchPolicy: 'no-cache',
      })
      .then((r) => {
        if (r.errors) {
          setLoginFailure('Invalid PIN');
          clearCachedPin();
          setTimeout(() => setLoginStatus(LoginStatus.NotStarted), 1000);
        } else {
          const userData = r.data!.me!;
          const userDataWithPin = { ...userData, pin: pin } as User;
          completeLogin(userDataWithPin);
        }
      })
      .catch(() => {
        // No sentry reporting here because it is most likely due to user
        // error (wrong PIN)
        setLoginFailure('Invalid PIN');
        clearCachedPin();
        setTimeout(() => setLoginStatus(LoginStatus.NotStarted), 1000);
      });
  };

  const completeLogin = (user: User) => {
    setLoginStatus(LoginStatus.Success);
    setTimeout(() => dispatch(UserActionCreators.login(user)), 800);
  };

  const setPin = (value: string) => {
    if (!/^[0-9]*$/.test(value)) return;
    setPin_(value);
    if (value.length === 4) startLogin(value);
  };

  const profile = releaseProfile();
  let state = '';
  if (profile === 'localhost') state = 'local';
  if (profile === 'staging') state = 'staging';
  if (profile === 'development') state = 'development';

  const content = (() => {
    if (loginStatus === LoginStatus.NotStarted) {
      return (
        <>
          <div style={{ paddingBottom: '4rem' }}>
            <Typography
              align="center"
              variant="h3"
              style={{ fontSize: '2.5rem' }}
            >
              TS Runner
            </Typography>
            {state && (
              <Typography
                align="center"
                variant="h6"
                style={{ textTransform: 'uppercase' }}
              >
                {state}
              </Typography>
            )}
          </div>
          <TextField
            id="pin"
            value={pin}
            type="password"
            onBlurCapture={(e) => e.target.focus()}
            onChange={(e) => setPin(e.target.value)}
            placeholder="Enter Your PIN"
            inputProps={{
              pattern: '\\d*',
              style: {
                textAlign: 'center',
              },
            }}
            autoFocus
          />
        </>
      );
    }

    if (loginStatus === LoginStatus.Fail) {
      return (
        <Typography align="center" variant="h2" color="error">
          {errorText}
        </Typography>
      );
    }

    if (loginStatus === LoginStatus.Success) {
      return (
        <LoginStyle>
          <span role="img" className="waver">
            👋🏻
          </span>
        </LoginStyle>
      );
    }

    if (loginStatus === LoginStatus.Pending) {
      return (
        <Fade in unmountOnExit style={{ transitionDelay: '300ms' }}>
          <div>
            <Loading message="Please wait..." />
          </div>
        </Fade>
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _exhaustiveCheck: never = loginStatus;
  })();

  return (
    <LoginContentStyle style={{ flexDirection: 'column', padding: '2em' }}>
      {content}
    </LoginContentStyle>
  );
};

export default LoginPage;

const LoginStyle = styled.div`
  .waver {
    font-size: 30pt;
    text-align: center;

    transform-origin: 50% 90%;
    animation-timing-function: ease-in-out;
  }
  animation: waver 0.4s alternate infinite;
  @keyframes waver {
    0% {
      transform: rotate(-25deg);
    }
    100% {
      transform: rotate(30deg);
    }
  }
`;

const LoginContentStyle = styled.div`
  flex-direction: column;
  padding: 2em;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
`;
