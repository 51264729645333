import React, { useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@mui/material';

import styled from 'styled-components';

import { Loading } from '../../Loading';
import { Error } from '../../Error';
import { Link, useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useRouteMatch } from 'react-router-dom';
import { useRunsQuery } from '../../../generated/graphql';
import { DelayedLinearProgress } from '../../DelayedLinearProgress';

/** This component is for handling the creation of a new Opti Run, or to complete an existing Opti Run  */
const OptiAdmin: React.FC = () => {
  const [untilDate] = useState(addDays(new Date(), 7).toISOString());

  // Grabs the Opti Workstation ID
  const { optiStation } = useRouteMatch<{ optiStation: ID }>().params;

  const history = useHistory();

  // Only show the active runs
  const { data, loading, error } = useRunsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      workstationId: optiStation,
      untilDate,
    },
  });

  const optiRuns = [...(data?.runs ?? [])].sort((one, two) =>
    one.date > two.date ? -1 : 1,
  );

  if (loading && !data) return <Loading message="Loading run data..." />;
  if (error || !data) {
    return <Error fullscreen />;
  }

  return (
    <Container>
      {loading && <DelayedLinearProgress wholeScreen />}
      <div className="HeaderContainer">
        <Typography variant="h5" className="Heading" color="primary">
          Select Run
        </Typography>
      </div>
      {optiRuns?.length === 0 && (
        <span className="NoActive">
          There are no active runs for this workstation.
        </span>
      )}

      <List dense={true} component="nav" className="Runs">
        {optiRuns
          ?.slice()
          .sort((a, b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          })
          .map((r) => (
            <Link
              key={r.id}
              to={`${optiStation}/${r.id}/${r.date}`}
              style={{ all: 'unset', width: '100%' }}
            >
              <ListItem dense={true} divider alignItems="flex-start" button>
                <ListItemText
                  className="RunID"
                  primary={r.description}
                  secondary={r.id}
                />
                <div>
                  <ListItemText primary={r.location.name} />
                  <ListItemText primary={r.date} />
                </div>
              </ListItem>
            </Link>
          ))}
      </List>
      <Button
        className="BackButton"
        color="error"
        variant="contained"
        onClick={() => history.push('/')}
      >
        Back
      </Button>
    </Container>
  );
};

export default OptiAdmin;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .NoActive {
    margin: 50px auto;
    font-size: 20px;
    text-align: center;
  }

  .RunID {
    .MuiListItemText-primary {
      font-weight: 500;
    }
  }

  .HeaderContainer {
    background-color: #d8ccc2;
    padding: 12px 0;
    z-index: 1500;
    margin-bottom: 5px;
  }

  .Runs {
    height: 325px;
    overflow-y: scroll;
  }

  .Heading {
    text-align: center;
  }
  .BackButton {
    align-self: center;
  }
`;

gql`
  query Runs($workstationId: ID, $workstationType: String, $untilDate: Date) {
    runs(
      workstationId: $workstationId
      workstationType: $workstationType
      untilDate: $untilDate
    ) {
      id
      date
      location {
        id
        name
      }

      infeed {
        packs {
          packId
        }
      }
      outfeed {
        packs {
          packId
        }
      }
      description
      workstation {
        id
        name
        type
      }
    }
  }
`;

const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};
