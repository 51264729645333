import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from '@sentry/react';
import { rootReducer, AppState } from './state';
import thunk from 'redux-thunk';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state: AppState) => {
    const { user } = state.user;
    if (user == null) {
      scope.setUser(null);
      scope.setTag('user.auth', 'logged-out');
    } else {
      scope.setUser({
        id: user.id,
        username: `${user.givenName} ${user.familyName}`,
      });

      scope.setTag('user.auth', 'logged-in');
    }
  },
});

const middleware = applyMiddleware(thunk);
export const store = createStore(
  rootReducer,
  composeEnhancers(middleware, sentryReduxEnhancer),
);
