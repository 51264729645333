import React, { useState } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Loading } from '../Loading';
import { Error } from '../Error';

import {
  List,
  ListItem,
  ListItemText,
  Button,
  Collapse,
  Typography,
} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { UserSelectors } from '../../selectors/user';
import { UserActionCreators } from '../../state/user';
import {
  useKilnsQuery,
  useGetWorkstationsQuery,
  useRunsQuery,
  useRunCreateMutation,
  WorkstationType,
} from '../../generated/graphql';
import Swal from 'sweetalert2';
import RunnerAnimation from '../../assets/RunnerAnimation';
import { DelayedLinearProgress } from '../DelayedLinearProgress';

gql`
  query GetWorkstations {
    workstations {
      id
      name
      type
      labelPrinterId
    }
  }
`;
gql`
  mutation RunCreate($input: RunCreateInput!) {
    runCreate(input: $input)
  }
`;

/** The user's starting point, provided they successfully authenticate. */
const SelectWorkstation: React.FC = () => {
  const history = useHistory();
  const [openOpti, setOpenOpti] = useState(false);
  const [openKiln, setOpenKiln] = useState(false);
  const [displayHome, setDisplayHome] = useState(true);
  const [openFilleting, setOpenFilleting] = useState(false);
  const [runCreate] = useRunCreateMutation();
  const user = useSelector(UserSelectors.current);
  const dispatch = useDispatch();
  const logout = () => dispatch(UserActionCreators.logout());

  const handleOpti = () => {
    setOpenOpti(!openOpti);
  };
  const handleKiln = () => {
    setOpenKiln(!openKiln);
  };

  const handleFilleting = () => {
    setOpenFilleting(!openFilleting);
  };

  const { data, loading, error} = useKilnsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: data1,
    loading: loading1,
    error: error1,
  } = useGetWorkstationsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const kilns = data?.kilns;
  
  const kilnLocations = [...new Set(kilns?.map((k) => k.locationName))];

  const workstations = data1?.workstations;
  const optiWorkstations = workstations?.filter(
    (r) => r.type === WorkstationType.FactoryIn,
  );

  const filletingWorkstations = workstations?.filter(
    (r) => r.type === 'FILLETING',
  );

  const {
    data: activeFilletingData,
    loading: loading2,
    error: error2,
  } = useRunsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      workstationType: 'FILLETING',
      untilDate: null,
    },
  });

  if ( !data || !data1 || !activeFilletingData) {
    return <Loading message="Getting ready..." />;
  }

  if (error || error1 || error2) {
    return <Error fullscreen />;
  }

  const renderError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oh no!',
      text: 'Unable to create a filleting run at this time.',
    });
  };

  const createFilletingRun = (filletingId: ID) => {
    runCreate({
      variables: {
        input: {
          workstationId: filletingId,
          date: new Date().toISOString(),
        },
      },
    }).then((c: any) => {
      if (c.errors) {
        renderError();
      } else {
        history.push(`/filleting/${c.data.runCreate}`);
      }
    });
  };

  /**  Depending on if there is an active Filleting Run associated with a workstation (length > 0),
   *  then it will show either `Create New` or `Continue`, with the appropriate onClick function
   */
  const filletingAction = (filletingWorkstationId: ID) => {
    const runsLength = activeFilletingData?.runs?.length;
    if (runsLength == null) return;

    for (let i = 0; i < runsLength; i++) {
      if (
        activeFilletingData?.runs[i].workstation.id === filletingWorkstationId
      ) {
        const runId = activeFilletingData.runs[i].id;
        return (
          <Button
            className="FilletingStatusButton"
            variant="contained"
            color="primary"
            size="medium"
            disableElevation
            onClick={() => history.push(`/filleting/${runId}`)}
          >
            Continue
          </Button>
        );
      }
    }

    return (
      <Button
        className="FilletingStatusButton"
        variant="contained"
        color="primary"
        size="medium"
        disableElevation
        onClick={() => createFilletingRun(filletingWorkstationId)}
      >
        Create New
      </Button>
    );
  };

  const renderHome = () => {
    return (
      <HomeBrandingStyle>
        <Typography color="primary" className="Title">
          TS RUNNER
        </Typography>
        <RunnerAnimation homepage={true} />
        <Button
          color="secondary"
          size="large"
          variant="contained"
          className="HomeButton"
          onClick={() => setDisplayHome(false)}
        >
          Select Task
        </Button>
      </HomeBrandingStyle>
    );
  };

  const renderSelectWorkstation = () => {
    return (
      <>
        <ListStyle>
          <List component="nav" className="DropdownMenu">
            <ListItem className="Opti" divider button onClick={handleOpti}>
              <ListItemText primary="Factory-In" />
              {openOpti ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openOpti} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {optiWorkstations?.map((r) => (
                  <ListItem divider button className="Nested" key={r.name}>
                    <Link
                      to={`Opti/${r.id}`}
                      style={{ all: 'unset', width: '100%', padding: '1rem 0' }}
                    >
                      {r.name}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <ListItem
              className="Filleting"
              divider
              button
              onClick={handleFilleting}
            >
              <ListItemText primary="Filleting" />
              {openFilleting ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openFilleting} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {filletingWorkstations?.map((r) => (
                  <ListItem divider className="Nested" key={r.id}>
                    {r.name}
                    {filletingAction(r.id)}
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <ListItem className="Kiln" divider button onClick={handleKiln}>
              <ListItemText primary="Kiln" />
              {openKiln ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openKiln} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  {kilnLocations?.map((location) => (
                    <ListItem divider button className="Nested" key={location}>
                      <Link to={`kilns/${location}`} style={{ all: 'unset', width: '100%', padding: '1rem 0' }}>
                        {location}
                      </Link>
                    </ListItem>
                  ))}
              </List>
            </Collapse>
            <ListItem className="Stocktake" divider button>
              <Link
                to="stocktake"
                style={{ width: '100%', fontSize: 'xxLarge' }}
              >
                Stocktake
              </Link>
            </ListItem>
            <ListItem className="Receipting" divider button>
              <Link
                to="receipting"
                style={{
                  width: '100%',
                  fontSize: 'xxLarge',
                }}
              >
                Receipting
              </Link>
            </ListItem>
          </List>
        </ListStyle>
      </>
    );
  };

  return (
    <Container>
      {(loading || loading1 || loading2) && <DelayedLinearProgress wholeScreen />}
      <div className="AccountInfo">
        <div className="NameHeading">
          <AccountBoxIcon className="Icon" />
          {user?.givenName}{' '}
        </div>
        <Button
          size="small"
          variant="contained"
          color="error"
          className="LogoutButton"
          onClick={logout}
        >
          logout
        </Button>
      </div>
      {displayHome ? renderHome() : renderSelectWorkstation()}
    </Container>
  );
};

export default SelectWorkstation;

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto;

  .FilletingStatusButton {
    max-width: 90px;
    position: absolute;
    right: 15px;
    height: 60px;
  }

  .AccountInfo {
    display: flex;
    justify-content: flex-end;

    .NameHeading {
      color: #326384;
      font-size: 17px;
      margin-right: 15px;
      font-weight: 500;
      margin-top: 7px;
    }
    .LogoutButton {
      margin: 10px 10px 0px 0px;
    }
    .Icon {
      transform: translateY(5px);
    }
  }

  .FilletingStatus {
    font-size: 15px;
    margin-left: 30px;
    color: #ff5c5c;
  }

  .Logout {
    bottom: 20px;
    align-self: center;
  }
`;

const ListStyle = styled.div`
  margin-top: 17px;
  .DropdownMenu {
    margin: 0px auto 50px auto;
    width: 100%;
    max-width: 600px;

    overflow-y: auto;
    .MuiListItem-root {
      a {
        text-decoration: none;
        color: black;
        font-size: xx-large;
      }
    }
  }

  .Nested {
    font-weight: 500;
  }

  .MuiListItemText-primary {
    font-size: xx-large;
    font-weight: 500;
  }

  .MuiListItem-root {
    height: 75px;
  }

  .Opti {
    background: #f1f6f9;
  }
  .Filleting {
    background: #e7ecef;
  }
  .Kiln {
    background: #f1f6f9;
  }

  .Stocktake {
    background: #e7ecef;
  }
  .Receipting {
    background: #f1f6f9;
  }

  .Opti,
  .Kiln,
  .Filleting,
  .Receipting,
  .Stocktake {
    font-weight: 500;
  }
`;

const HomeBrandingStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;

  .Title {
    font-weight: 600;
    font-size: 30px;
    transform: translateY(80px);
  }
  .HomeButton {
    position: absolute;
    margin-top: 45vh;
    transform: scale(1.3);
  }
`;
