import React from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  DialogContentText,
} from '@mui/material';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export interface BackButtonPrompt {
  open: boolean;
  onComplete: (action: 'none' | 'back') => void;
}

export const BackConfirmDialog: React.FC<BackButtonPrompt> = ({
  open,
  onComplete,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onComplete('none')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Going back will erase your progress
          </DialogContentText>
        </DialogContent>
        <ButtonStyle>
          <DialogActions>
            <Button onClick={() => onComplete('none')} color="primary">
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => onComplete('back')}
            >
              Go Back
            </Button>
          </DialogActions>
        </ButtonStyle>
      </Dialog>
    </div>
  );
};

const ButtonStyle = styled.div`
  .MuiDialogActions-root {
    justify-content: center;
  }
`;

export const useReceiptingBackToHome = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return () => {
    dispatch({ type: 'CLEAR_ALL' });
    history.push('/');
  };
};
