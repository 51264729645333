import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export interface ReceiptLineDisplayProps {
  id: string;
  barcode: string;
  remove: (id: string) => void;
}

export const ReceiptLineDisplay: React.FC<ReceiptLineDisplayProps> = ({
  id,
  barcode,
  remove,
}) => (
  <ListItem>
    <ListItemText
      primary={barcode}
      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
    />
    <ListItemSecondaryAction>
      <IconButton onClick={() => remove(id)} edge="end" aria-label="delete">
        <DeleteIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);
