import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItemText,
  ListItem,
} from '@mui/material';
import styled from 'styled-components';
import { KilnCharge } from '../../generated/graphql';

interface ViewItemsModalProps {
  open: boolean;
  close: () => void;
  kilnCharge: KilnCharge;
}

export const ViewItemsModal: React.FC<ViewItemsModalProps> = ({
  open,
  close,
  kilnCharge,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => close()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ViewItemsModalStyle>
        <DialogContent className="DialogContainer">
          <Typography variant="h6" className="Header">
            Items in #{kilnCharge.chargeNumber}
          </Typography>
          <List>
            {kilnCharge.chargeItems.map((item) => {
              return (
                <ListItem key={item.id}>
                  <ListItemText primary={item.packId} secondary={'Pack ID'} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions className="Buttons">
          <Button
            size="large"
            variant="contained"
            color="error"
            onClick={close}
          >
            Back
          </Button>
        </DialogActions>
      </ViewItemsModalStyle>
    </Dialog>
  );
};

const ViewItemsModalStyle = styled.div`
  .Buttons {
    justify-content: center;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
  }

  .Header {
    padding-top: 60px;
    text-align: center;
  }

  .DialogContainer {
    text-align: center;
    max-height: 390px;
  }
`;
