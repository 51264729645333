import React, { useState } from 'react';
import { Typography, ButtonGroup, Button } from '@mui/material';
import { Loading } from '../Loading';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import {
  useKilnChargeCreateMutation,
  KilnChargeType,
} from '../../generated/graphql';
import Swal from 'sweetalert2';
import { ContractDetailsForm } from './ContractDetailsForm';
import { InternalDetailsForm } from './InternalDetailsForm';

gql`
  mutation KilnChargeCreate($input: KilnChargeCreateInput!) {
    kilnChargeCreate(input: $input)
  }
`;

export interface KilnChooseTypeProps {
  kilnName: string;
  kilnId: string;
}

type Page = 'contract-input' | 'genia-input' | 'kiln-type';

export const KilnChooseType: React.FC<KilnChooseTypeProps> = ({
  kilnName,
  kilnId,
}) => {
  const history = useHistory();
  const [creatingCharge, setCreatingCharge] = useState(false);
  const [page, setPage] = useState<Page>('kiln-type');
  const [kilnChargeCreate] = useKilnChargeCreateMutation();

  const createCharge = (
    id: number,
    kilnType: KilnChargeType,
    customer?: string,
    thickness?: number,
    reference?: string,
    alternateChargeNumber?: string,
    geniaFilleted?: boolean,
  ) => {
    setCreatingCharge(true);
    kilnChargeCreate({
      variables: {
        input: {
          kilnId: id,
          type: kilnType,
          customer,
          reference,
          thickness,
          alternateChargeNumber,
          geniaFilleted,
        },
      },
    }).then((c) => {
      if (c.errors) {
        Swal.fire({
          icon: 'error',
          title: 'Oh no!',
          text: 'Unable to create a kiln charge at this time.',
        });
      } else {
        history.push(`${kilnName}/${c.data?.kilnChargeCreate}`);
      }
    });
  };

  const handleCreateContractCharge = (
    customer: string,
    reference: string,
    thickness: number,
    geniaFilleted: boolean,
  ) => {
    createCharge(
      parseInt(kilnId, 10),
      KilnChargeType.Contract,
      customer,
      thickness,
      reference,
      undefined,
      geniaFilleted,
    );
  };

  if (creatingCharge) return <Loading message="Creating Kiln charge..." />;

  if (page === 'kiln-type') {
    return (
      <>
        <div className="HeaderContainer">
          <Typography variant="h5" className="Heading">
            Select Kiln type
          </Typography>
        </div>
        <ButtonGroup
          className="KilnType"
          orientation="vertical"
          fullWidth
          aria-label="vertical outlined primary button group"
        >
          <Button
            style={{
              height: 100,
              backgroundColor: '#e2eef3',
              fontSize: 'x-large',
              fontWeight: 500,
            }}
            size="large"
            onClick={() => setPage('genia-input')}
          >
            Genia
          </Button>
          <Button
            style={{
              height: 100,
              backgroundColor: '#ced9de',
              fontSize: 'x-large',
              fontWeight: 500,
            }}
            size="large"
            onClick={() => setPage('contract-input')}
          >
            CONTRACT
          </Button>
        </ButtonGroup>
      </>
    );
  }

  if (page === 'contract-input') {
    return (
      <>
        <div className="HeaderContainer">
          <Typography variant="h5" className="Heading">
            Fill in Contract Info
          </Typography>
        </div>
        <ContractDetailsForm
          handleCreateContractCharge={handleCreateContractCharge}
        />
      </>
    );
  }

  return (
    <>
      <div className="HeaderContainer">
        <Typography variant="h5" className="Heading">
          Fill in Genia Info
        </Typography>
      </div>
      <InternalDetailsForm
        handleCreateCharge={(reference, alternateChargeNumber) =>
          createCharge(
            parseInt(kilnId, 10),
            KilnChargeType.Standard,
            undefined,
            undefined,
            reference,
            alternateChargeNumber,
          )
        }
      />
    </>
  );
};
