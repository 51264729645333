import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  ButtonGroup,
  Button,
} from '@mui/material';

import FilletingInfeed from '../FilletingInfeed';
import FilletingOutfeed from '../FilletingOutfeed';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../Loading';
import { Error } from '../../Error';
import dateFormat from 'dateformat';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import { useRouteMatch } from 'react-router-dom';
import {
  useRunQuery,
  useRunRemovePackMutation,
  useRunCompleteMutation,
  Run,
} from '../../../generated/graphql';
import { DelayedLinearProgress } from '../../DelayedLinearProgress';

/**
 * This page is the main (Parent) component for the Filleting process.  The main children are two components,
 * "FilletingInfeed" and "FilletingOutfeed".  It includes a dynamic param in the URL from the SelectWorkstation page
 */
gql`
  query Run($runId: ID!) {
    run(id: $runId) {
      id
      date
      supplierName
      certificationCode
      location {
        id
        name
      }

      infeed {
        packs {
          packId
          processDate
          lastUpdate
          gcsId
          width
          thickness
          productCode
          processId
          processDescription
          tally {
            pieces
            length
          }
        }
      }
      outfeed {
        packs {
          packId
          processDate
          lastUpdate
          gcsId
          productCode
          processId
          processDescription
          tally {
            pieces
            length
          }
        }
      }
      description
      complete
      workstation {
        id
        name
        type
      }
    }
  }
`;

gql`
  mutation RunRemovePack($input: RunRemovePackInput!) {
    runRemovePack(input: $input)
  }
`;

const FilletingAdmin: React.FC = () => {
  // Generated via the counters service (Genia API) from the SelectWorkstation page
  const { runId } = useRouteMatch<{ runId: ID }>().params;
  const [pane, setPane] = useState<'infeed' | 'outfeed'>('infeed');
  const [finish, setFinish] = useState(false);
  const history = useHistory();
  const [closeRun, { loading: closeLoading }] = useRunCompleteMutation();
  const [removePack, { loading: deletingPack }] = useRunRemovePackMutation();
  const [deleteItemConfirm, setDeleteItemConfirm] = useState(false);
  const [deletePackId, setDeletePackId] = useState('' as ID);

  // Gets the current run info based on the runId
  const { data, loading, error, refetch } = useRunQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { runId },
  });

  if (loading && !data) return <Loading message="Hang tight..." />;
  if (error || !data) return <Error fullscreen />;
  if (deletingPack) return <Loading message="Deleting pack..." />;
  if (closeLoading) return <Loading message="Closing run..." />;

  const handleClearState = () => {
    setDeleteItemConfirm(false);
    setDeletePackId('' as ID);
  };
  const deleteItem = (packId: ID) => {
    setDeleteItemConfirm(true);
    setDeletePackId(packId);
  };

  const deletePack = (deletePackId: ID, runId: ID, outfeed?: boolean) => {
    removePack({
      variables: {
        input: {
          packId: deletePackId,
          runId: runId,
          outfeed: outfeed ?? true,
        },
      },
    })
      .then((r) => {
        if (r.errors) {
          Sentry.captureMessage('Failed to remove pack', Sentry.Severity.Error);
          renderError('Unable to remove this pack.');
        } else {
          refetch();
        }
        handleClearState();
      })
      .catch((error) => {
        handleClearState();
        Sentry.captureException(error);
        renderError('Unable to remove this pack from the scan.');
      });
  };

  const renderError = (msg: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oh no!',
      text: msg,
    });
  };

  const handleCloseRun = () => {
    setFinish(false);
    closeRun({
      variables: { input: { runId, costSpread: true } },
    })
      .then((p) => {
        if (p.errors) {
          Sentry.captureMessage('Failed to close run', Sentry.Severity.Error);
          renderError('Could not close run!  Please contact IT or go back.');
        }
        Swal.fire({
          icon: 'success',
          title: 'Yay!',
          text: 'Run successfully closed.',
        });
        history.goBack();
      })
      .catch((error) => {
        Sentry.captureException(error);
        renderError('Could not close run!  Please contact IT or go back.');
      });
  };

  const runData = data?.run;

  return (
    <Container>
      {loading && <DelayedLinearProgress wholeScreen />}
      <FilletingSelectStyle>
        <div
          className="Infeed"
          style={{
            borderBottom: pane === 'infeed' ? '#ada5a5 8px solid' : undefined,
          }}
          onClick={() => setPane('infeed')}
        >
          <div className="InnerText">Infeed</div>
        </div>
        <div
          className="Outfeed"
          style={{
            borderBottom: pane === 'infeed' ? undefined : '#ada5a5 8px solid',
          }}
          onClick={() => setPane('outfeed')}
        >
          <div className="InnerText">Outfeed</div>
        </div>
      </FilletingSelectStyle>
      <FilletingContent>
        {runData !== null && (
          <div>
            <div className="RunHeaderInfo">
              <span>{runData?.id}</span>
              <span>{dateFormat(runData?.date, 'dd/mm/yyyy')}</span>
            </div>
            <div className="RunHeaderInfo">
              <span>{runData?.supplierName}</span>
              <span>
                {runData?.infeed.packs.length
                  ? `${runData?.infeed.packs[0].width}x${runData?.infeed.packs[0].thickness}`
                  : ''}
              </span>
              <span>{runData?.certificationCode}</span>
            </div>
          </div>
        )}

        {pane === 'infeed' ? (
          <FilletingInfeed
            deleteItem={deleteItem}
            deleteItemConfirm={deleteItemConfirm}
            run={runData as Run}
            refetch={refetch}
            finish={finish}
          />
        ) : (
          <FilletingOutfeed
            deleteItem={deleteItem}
            deleteItemConfirm={deleteItemConfirm}
            run={runData as Run}
            refetch={refetch}
            finish={finish}
          />
        )}

        <ButtonGroup
          className="Buttons"
          variant="contained"
          disableElevation
          color="primary"
          aria-label="text primary button group"
          size="medium"
        >
          <Button color="error" onClick={() => window.history.back()}>
            Back
          </Button>
          <Button color="primary" onClick={() => setFinish(true)}>
            Close Run
          </Button>
        </ButtonGroup>
      </FilletingContent>
      <Dialog open={deleteItemConfirm}>
        <DialogTitle>Delete from scan?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete pack <b>{deletePackId}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteItemConfirm(false)} color="error">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deletePack(deletePackId, runId, pane === 'outfeed' && true);
            }}
            variant="contained"
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={finish}>
        <DialogTitle>Finish run?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure? Press Submit to save and close the run.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFinish(false)} color="error">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleCloseRun();
            }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FilletingAdmin;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .Buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .DeleteInfo {
    margin-bottom: 10px;
  }
`;

const FilletingContent = styled.div`
  position: fixed;
  margin-top: 40px;
  display: block;
  width: 95%;
  @media only screen and (min-width: 450px) {
    height: 100%;
    .Buttons {
      position: absolute;
      bottom: 130px;
      right: 0;
      left: 0;
    }
  }

  .RunHeaderInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: small;
  }

  .MuiAlert-root {
    padding: 0px 10px;
  }
`;

const FilletingSelectStyle = styled.div`
  display: inline-flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 55px;
  overflow: none;
  z-index: 2000;

  .Infeed,
  .Outfeed {
    touch-action: none;
    font-size: 17px;
    flex-grow: 1;
    :hover,
    :active {
      cursor: pointer;
      filter: brightness(0.85);
    }
    :nth-child(1) {
      background-color: #eaeaea;
    }
    :nth-child(2) {
      background-color: #dae2db;
    }
    .InnerText {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
