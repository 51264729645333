import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import { TextField, List, ListItem, ListItemText } from '@mui/material';


import { Loading } from '../Loading';
import { useRunAddInfeedPacksMutation, Run } from '../../generated/graphql';
import { FilletingInfeedItemDisplay } from './FilletingInfeedItemDisplay';

export interface FilletingInfeedProps {
  run: Run | null | undefined;
  refetch: () => void;
  deleteItem: (packId: ID) => void;
  deleteItemConfirm: boolean;
  finish: boolean;
}

type ExtraMessageDisplay = {primaryMessage: string, secondaryMessage: string}

export const FilletingInfeed: React.FC<FilletingInfeedProps> = ({
  run,
  refetch,
  deleteItem,
  deleteItemConfirm,
  finish,
}) => {
  const [infeedPackState, setInfeedPackState] = useState('');
  const [addInfeedPackItems, { loading }] = useRunAddInfeedPacksMutation();
  const [erroneousPack, setErroneousPack] = useState< ExtraMessageDisplay | null >(null);
  const [showExtraMessage, setShowExtraMessage] = useState(false);

  if (loading) return <Loading message="Adding pack..." />;

  const onSubmitInfeedPack = () => {
    setShowExtraMessage(false);
    if (infeedPackState === '') return;
    addInfeedPackItems({
      variables: {
        input: {
          runId: run!.id,
          packs: [
            {
              packId: infeedPackState as ID,
              processDate: new Date().toISOString(),
            },
          ],
        },
      },
    })
      .then((r) => {
        const error = r.data?.runAddInfeedPacks?.error;
        const errorCode = r.data?.runAddInfeedPacks?.errorCode;
        if (error) {

          const ERRONEOUS_SCAN: ExtraMessageDisplay = {
            primaryMessage: `${error.toLocaleUpperCase()}`,
            secondaryMessage: '',
          }

          errorCode
            ? (ERRONEOUS_SCAN.secondaryMessage = `ERROR CODE:${errorCode.toLocaleUpperCase()}`)
            : (ERRONEOUS_SCAN.secondaryMessage = `NO ERROR CODE PROVIDED`);
          

          setErroneousPack(ERRONEOUS_SCAN);
          setShowExtraMessage(true);
        } else if (r.errors) {
          
          const ERRONEOUS_SCAN: ExtraMessageDisplay = {
          primaryMessage: 'UNKOWN ERROR TRY AGAIN',
          secondaryMessage: 'IT HAS BEEN NOTIFIED',
          }

          setErroneousPack(ERRONEOUS_SCAN);
          setShowExtraMessage(true);
        } else {
          // success
          refetch();
          return;
        }

        Sentry.captureMessage(
          'Failed to add pack to run',
          Sentry.Severity.Error,
        );
      })
      .catch((error) => {
        Sentry.captureException(error);

        const ERRONEOUS_SCAN: ExtraMessageDisplay = {
          primaryMessage: 'THE PROCESS FAILED',
          secondaryMessage: 'TRY AGAIN',
        }
        setErroneousPack(ERRONEOUS_SCAN);
        setShowExtraMessage(true);
      });

    setInfeedPackState('');
  };

  const onInfeedPackKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') onSubmitInfeedPack();
  };

  const runInfeed = [...(run?.infeed?.packs ?? [])].sort(
    (a, b) =>
      new Date(b!.lastUpdate).getTime() - new Date(a!.lastUpdate).getTime(),
  );

  const packsScannedPrettier = () => {
    const packsExist = runInfeed?.length && runInfeed?.length > 0;
    return `Scan Infeed Packs... (${packsExist && runInfeed?.length})`;
  };

  return (
    <Container>
      <TextField
        autoComplete="off"
        id="BarcodeInput"
        placeholder={packsScannedPrettier()}
        onChange={(e) => setInfeedPackState(e.target.value)}
        onKeyUp={onInfeedPackKeyUp}
        autoFocus
        value={infeedPackState}
        onBlur={() =>
          !finish &&
          !deleteItemConfirm &&
          document.getElementById('BarcodeInput')?.focus()
        }
      />
      <div className="InfeedPacks">
        {runInfeed ? (
          <List component="nav">
            {showExtraMessage && (
              <ListItem divider alignItems="center" style={{ color: 'red', backgroundColor: 'lightgrey', textAlign: 'center' }}>     
                <ListItemText primary={erroneousPack?.primaryMessage} secondary={erroneousPack?.secondaryMessage} style={{color: 'red'}} />      
              </ListItem>
              )
            }
            {runInfeed.map((p) => (
              <FilletingInfeedItemDisplay
                key={p.packId}
                packId={p.packId}
                productCode={p.productCode}
                deleteItem={deleteItem}
              />
            ))}
          </List>
        ) : (
          <div className="NoActiveMessage">No packs scanned yet.</div>
        )}
      </div>
    </Container>
  );
};

export default FilletingInfeed;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .Heading {
    text-align: center;

    left: 0;
    right: 0;
  }

  .InfeedPacks {
    height: 245px;
    overflow-y: scroll;
    .NoActiveMessage {
      margin-top: 20px;
    }

    @media only screen and (min-width: 450px) {
      height: calc(100vh - 320px);
    }
  }
`;
