import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { KilnChargeType, KilnCharge } from '../../generated/graphql';
import {
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Divider,
  DialogContentText,
  Button,
  ListItem,
  ListItemText,
  DialogTitle,
} from '@mui/material';

import { ViewItemsModal } from './ViewItemsModal';
export interface KilnChargeItemDisplayProps {
  kilnCharge: KilnCharge;
  handleMoveOut: (id: ID, type: KilnChargeType) => void;
  handleRemoveCharge: (kilnChargeId: ID) => void;
}

export const KilnChargeItemDisplay: React.FC<KilnChargeItemDisplayProps> = ({
  kilnCharge,
  handleMoveOut,
  handleRemoveCharge,
}) => {
  const history = useHistory();
  const [removeChargeConfirm, setRemoveChargeConfirm] = useState(false);
  const [viewItems, setViewItems] = useState(false);

  const renderButtons = (kilnIn: boolean) => {
    return kilnIn ? (
      <ButtonGroup
        className="ChargeActionButton"
        variant="contained"
        disableElevation
        aria-label="text primary button group"
        size="medium"
      >
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={() => setViewItems(true)}
        >
          View
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => handleMoveOut(kilnCharge.id, kilnCharge.type)}
          size="medium"
        >
          Move Out
        </Button>
      </ButtonGroup>
    ) : (
      <ButtonGroup
        className="ChargeActionButton"
        variant="contained"
        disableElevation
        aria-label="text primary button group"
        size="medium"
      >
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={() => setRemoveChargeConfirm(true)}
        >
          Remove
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() =>
            history.push(`${kilnCharge.kiln.name}/${kilnCharge.id}`)
          }
        >
          Scan
        </Button>
      </ButtonGroup>
    );
  };

  const renderItem = () => {
    return (
      <ListItem
        className="Rows"
        key={kilnCharge.id}
        dense
        button
        alignItems="flex-start"
      >
        <div className="Row Row1">
          <ListItemText primary={kilnCharge.kiln.name} />

          {kilnCharge.type === KilnChargeType.Contract ? (
            <ListItemText primary={kilnCharge.customer || 'N/A'} />
          ) : (
            <ListItemText style={{ textAlign: 'end' }} primary="Genia" />
          )}

          {kilnCharge.type === KilnChargeType.Contract && (
            <ListItemText
              style={{ textAlign: 'end' }}
              primary={
                kilnCharge.thickness ? `${kilnCharge.thickness}mm` : 'N/A'
              }
            />
          )}
        </div>

        <div className="Row Row2">
          <ListItemText primary={kilnCharge.chargeNumber} />
          <ListItemText
            style={{ textAlign: 'end' }}
            primary={kilnCharge.reference || '---'}
          />
        </div>
        <div className="Row Row3">
          {kilnCharge.alternateChargeNumber && (
            <ListItemText primary={kilnCharge.alternateChargeNumber} />
          )}
          {renderButtons(kilnCharge.kilnIn)}
        </div>
        <Divider className="Divider" />
      </ListItem>
    );
  };

  return (
    <KilnChargeItemStyle>
      {renderItem()}
      {removeChargeConfirm && (
        <Dialog open={true}>
          <DialogTitle>Remove charge?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the charge{' '}
              <b>
                {kilnCharge.chargeNumber} ({kilnCharge.id})
              </b>
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRemoveChargeConfirm(false)} color="error">
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleRemoveCharge(kilnCharge.id);
              }}
              variant="contained"
              color="primary"
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ViewItemsModal
        open={viewItems}
        close={() => setViewItems(false)}
        kilnCharge={kilnCharge}
      />
    </KilnChargeItemStyle>
  );
};

const KilnChargeItemStyle = styled.div`
  .ChargeActionButton {
    height: 40px;
    align-self: center;
  }
  .Divider {
    width: 100%;
    margin: 10px 0px 10px 0px;
    background: #326384;
  }
  .Rows {
    display: flex;
    flex-direction: column;
    .Row {
      margin: 5px 0px;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
    .Row3 {
      justify-content: flex-end;
    }
  }
`;
