import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import {
  TextField,
  ButtonGroup,
  Button,
  FormControl,
  CircularProgress,
} from '@mui/material';

import { useHistory } from 'react-router-dom';
import { useKilnContractCustomersQuery } from '../../generated/graphql';
import { Error } from '../Error';

gql`
  query KilnContractCustomers {
    result: kilnContractCustomers {
      id
      name
    }
  }
`;

export interface InternalDetailsFormProps {
  handleCreateCharge: (
    reference: string,
    alternateChargeNumber: string,
  ) => void;
}

export const InternalDetailsForm: React.FC<InternalDetailsFormProps> = ({
  handleCreateCharge,
}) => {
  const [reference, setReference] = useState('');
  const history = useHistory();

  const { data: customers, loading, error } = useKilnContractCustomersQuery();
  if (loading || !customers?.result) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          left: 'calc(50% - 20px)',
          top: 'calc(50% - 20px)',
        }}
      />
    );
  }
  if (error) return <Error />;

  return (
    <AdminFormStyle>
      <FormControl className="Field">
        <TextField
          style={{ marginTop: 30 }}
          id="Reference"
          name="Reference"
          label="Reference"
          value={reference}
          onChange={(e) => setReference(e.target.value)}
        />
      </FormControl>

      <ButtonGroup
        className="Buttons"
        variant="contained"
        disableElevation
        color="primary"
        aria-label="text primary button group"
        size="large"
        style={{ justifyContent: 'center' }}
      >
        <Button color="error" onClick={() => history.push('/')}>
          Back
        </Button>
        <Button
          color="primary"
          onClick={() => handleCreateCharge(reference, '')}
        >
          Create Charge
        </Button>
      </ButtonGroup>
    </AdminFormStyle>
  );
};

const AdminFormStyle = styled.div`
  .Buttons {
    display: flex;
    place-content: center;
  }

  .Field {
    height: 100%;
    width: 250px;
    margin: 20px auto;
    display: flex;
  }
`;
