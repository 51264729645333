import React from 'react';
import { ListItem, ListItemText } from '@mui/material';

import styled from 'styled-components';
import { RunOutfeedPack } from '../../generated/graphql';

export interface FilletingOutfeedItemDisplayProps {
  pack: RunOutfeedPack;
  openOutfeedItemModal: (pack: RunOutfeedPack) => void;
}

export const FilletingOutfeedItemDisplay: React.FC<FilletingOutfeedItemDisplayProps> =
  ({ pack, openOutfeedItemModal }) => (
    <FilletingItemStyle>
      <ListItem
        onClick={() => openOutfeedItemModal(pack)}
        dense
        key={pack.packId}
        divider
        button
        alignItems="flex-start"
      >
        <ListItemText primary={pack.packId} secondary="ID" />
        <ListItemText
          primary={pack.tally?.[0]?.pieces ?? null}
          secondary="PCE"
        />
        <ListItemText
          primary={pack.tally?.[0]?.length ?? null}
          secondary="LEN"
        />
      </ListItem>
    </FilletingItemStyle>
  );

const FilletingItemStyle = styled.div`
  .DeleteIcon {
    position: absolute;
    right: 10px;
    font-size: large;
    bottom: 12px;
  }
  .MuiIconButton-root {
    padding: 0px;
  }
  .MuiIconButton-edgeEnd {
    margin-right: 0px;
  }
  .ProductCode {
    text-align-last: right;
  }
`;
