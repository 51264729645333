import { User } from '../models/User';

export interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

interface SetUserAction {
  type: 'SET_USER';
  user: User | null;
}

type Action = SetUserAction;

export const clearCachedPin = () => sessionStorage.removeItem('user-pin');
export const setCachedPin = (pin: string) =>
  sessionStorage.setItem('user-pin', pin);
export const getCachedPin = () => sessionStorage.getItem('user-pin');

export const UserActionCreators = {
  setUser: (user: User | null): SetUserAction => {
    return { type: 'SET_USER', user };
  },

  login: (user: User) => (dispatch: any) => {
    dispatch(UserActionCreators.setUser(user));
    if (user.pin) {
      setCachedPin(user.pin);
    }
  },
  logout: () => (dispatch: any) => {
    dispatch(UserActionCreators.setUser(null));
    clearCachedPin();
  },
};

export function user(state = initialState, action: Action): UserState {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
}
