import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { WifiPendingIndicator } from '../WifiPendingIndicator';

export interface KilnItemDisplayProps {
  id: string;
  packId: string;
  m3: number | null | undefined;
  pending: boolean;
  maybeOffline: boolean;
  deleteItem: (packId: string) => void;
}

export const KilnItemDisplay: React.FC<KilnItemDisplayProps> = ({
  id,
  packId,
  m3,
  pending,
  maybeOffline,
  deleteItem,
}) => (
  <ListItem key={id} alignItems="center">
    <>
      {pending ? (
        maybeOffline ? (
          <WifiPendingIndicator text="Waiting for internet connection:" />
        ) : (
          <CircularProgress
            color="error"
            size={30}
            style={{ marginRight: 20 }}
          />
        )
      ) : (
        <>
          <ListItemText primary={packId} />
          <ListItemText
            primary={m3?.toFixed(2)}
            style={{ textAlign: 'right' }}
          />
        </>
      )}
    </>
    <ListItemSecondaryAction>
      <IconButton
        onClick={() => deleteItem(packId)}
        edge="end"
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);
