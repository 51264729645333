import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Button,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

interface IcprDialogProps {
  open: boolean;
  close: () => void;
  handleConfirm: (isIcpr: boolean) => void;
}

export const IcprDialog: React.FC<IcprDialogProps> = ({
  open,
  close,
  handleConfirm,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => close()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IcprStyle>
        <DialogContent className="DialogContainer">
          <Typography variant="h6" className="Header">
            Do these packs conform to ICPR standards?
          </Typography>
          <DialogActions className="Buttons">
            <ButtonGroup
              style={{ marginTop: '80px', transform: 'scale(2.5)' }}
              variant="contained"
              disableElevation
              color="primary"
              orientation="vertical"
              aria-label="text primary button group"
              size="large"
            >
              <Button
                size="large"
                color="primary"
                onClick={() => handleConfirm(true)}
              >
                Yes
              </Button>

              <Button
                size="large"
                color="error"
                onClick={() => handleConfirm(false)}
              >
                No
              </Button>
            </ButtonGroup>
          </DialogActions>
        </DialogContent>
      </IcprStyle>
    </Dialog>
  );
};

const IcprStyle = styled.div`
  .Buttons {
    justify-content: center;
  }

  .Header {
    padding-top: 60px;
    text-align: center;
  }

  .DialogContainer {
    text-align: center;
    max-width: 400px;
    max-height: 550px;
    height: 100vh;
  }
`;
