import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ButtonGroup,
  Typography,
} from '@mui/material';

import { Loading } from '../../Loading';
import { Error } from '../../Error';
import KilnMoveOut from '../KilnMoveOut';
import gql from 'graphql-tag';
import {
  useKilnsQuery,
  useGetKilnChargesQuery,
  KilnChargeType,
  useKilnChargeRemoveChargeMutation,
  KilnCharge,
} from '../../../generated/graphql';
import { KilnChooseType } from '../KilnChooseType';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { KilnChargeItemDisplay } from '../KilnChargeItemDisplay';
import Swal from 'sweetalert2';
import { DelayedLinearProgress } from '../../DelayedLinearProgress';

const CONTRACT_PREFIX = 'CTR';

gql`
  query GetKilnCharges($ids: [ID!], $onlyActive: Boolean) {
    kilnCharges(ids: $ids, onlyActive: $onlyActive) {
      chargeNumber
      alternateChargeNumber
      id
      type
      inDate
      kilnIn
      kiln {
        name
      }
      kilnOut
      outDate
      thickness
      reference
      customer
      chargeItems {
        id
        packId
        gcsCode
        gcsId
        metersCubed
      }
    }
  }

  query Kilns {
    kilns {
      id
      name
      locationName
    }
  }

  query GetCounter($input: ID!) {
    counter(id: $input) {
      id
    }
  }

  mutation KilnChargeRemoveCharge($input: KilnChargeRemoveChargeInput!) {
    kilnChargeRemoveCharge(input: $input)
  }
`;

/** This component is for handling the creation of a new Kiln Charge,
 *  or to complete an existing Kiln Charge */

const KilnAdmin: React.FC = () => {
  const history = useHistory();
  const [selectedKilnId, setSelectedKilnId] = useState<string | null>(null);

  const [removeKilnCharge, { loading: removing }] =
    useKilnChargeRemoveChargeMutation();

  const kilnsQuery = useKilnsQuery({
    fetchPolicy: 'cache-and-network',
  });
  
  // grab kiln locationName from url
  const  kilnLocationObj = useRouteMatch<{ locationName: string }>().params;

  // extract location from loccationName object
  const kilnLocation = kilnLocationObj.locationName;
  
  // generate array of kiln names from kiln location to use for filtering displayed charges
  const accessibleKilnNames = kilnsQuery.data?.kilns.filter(kiln => kiln.locationName === kilnLocation).map(kiln => kiln.name);
  
  const { data, loading, error, refetch } = useGetKilnChargesQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      onlyActive: true,
    },
  });

  const [selectedKilnCharge, setSelectedKilnCharge] = useState<null | ID>(null);

  // Arbitrarily defaulted to the `Standard` Kiln Charge Type, as opposed to `Contract`
  const [selectedKilnChargeType, setSelectedKilnChargeType] = useState(
    KilnChargeType.Standard,
  );

  type DisplayType = 'list' | 'move-out' | 'kiln' | 'kiln-type';
  const [display, setDisplay] = useState<DisplayType>('list');

  if ((loading && !data) || (kilnsQuery.loading && !kilnsQuery.data)) {
    return <Loading />;
  }

  if (error || !data) {
    return <Error fullscreen />;
  }

  if (removing) {
    return <Loading message="Removing item..." />;
  }
  const kilnCharges = [...data.kilnCharges.filter(charge => accessibleKilnNames?.includes(charge.kiln.name))].sort((a, b) =>
    b!.chargeNumber
      .replace(CONTRACT_PREFIX, '')
      .localeCompare(a!.chargeNumber.replace(CONTRACT_PREFIX, '')),
  );

  const renderMoveOut = () => {
    const kilnCharge = kilnCharges.find((r) => r.id === selectedKilnCharge)!;
    return (
      <KilnMoveOut
        setMoveOut={() => setDisplay('list')}
        kilnChargeId={selectedKilnCharge!}
        kilnChargeType={selectedKilnChargeType}
        kilnCharge={kilnCharge as KilnCharge}
        refetch={refetch}
      />
    );
  };

  const handleMoveOut = (kilnChargeId: ID, kilnChargeType: KilnChargeType) => {
    setSelectedKilnCharge(kilnChargeId);
    setSelectedKilnChargeType(kilnChargeType);
    setDisplay('move-out');
  };

  const renderError = (msg: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oh no!',
      text: msg,
    });
  };

  const handleRemoveCharge = (kilnChargeId: ID) => {
    removeKilnCharge({
      variables: {
        input: { kilnChargeId },
      },
    })
      .then((r) => {
        if (r.errors) {
          Sentry.captureMessage(
            'Unable to remove charge',
            Sentry.Severity.Error,
          );
          renderError('Unable to remove this charge.');
        } else {
          refetch();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        renderError('Unable to remove this charge.');
      });
  };

  // filter kilns using kilnLocation const, these are the kilns that will be rendered for selection
  const kilns = kilnsQuery.data?.kilns.filter(kiln => kiln.locationName === kilnLocation) ?? [];
  
  const setRender = () => {
    if (display === 'kiln') {
      return (
        <SelectKiln
          onChoose={(id) => {
            setDisplay('kiln-type');
            setSelectedKilnId(id);
          }}
          kilns={kilns?.map((kiln) => ({
            id: kiln.id,
            name: kiln.name,
            location: kiln.locationName,
          }))}
        />
      );
    }

    if (display === 'kiln-type') {
      if (selectedKilnId == null) return;
      const kilnName = kilns?.find((kiln) => kiln.id === selectedKilnId)?.name ?? '??';
      return <KilnChooseType kilnName={kilnName} kilnId={selectedKilnId} />;
    }

    return renderKilnCharges();
  };

  const handleBackButton = () => {
    if (display === 'list') {
      history.push('/');
    } else if (display === 'kiln') {
      setDisplay('list');
    } else {
      setDisplay('kiln');
    }
  };

  const renderKilnCharges = () => {
    return (
      <>
        <div className="HeaderContainer">
          <Typography variant="h5" className="Heading" color="primary">
            Kiln Admin
          </Typography>
        </div>

        <div className="Charges">
          {kilnCharges.length === 0 && (
            <div className="NoActiveMessage">No active kiln charges</div>
          )}

          <List dense component="nav">
            {kilnCharges.map((r) => (
              <KilnChargeItemDisplay
                key={r.id}
                kilnCharge={r as any}
                handleMoveOut={handleMoveOut}
                handleRemoveCharge={handleRemoveCharge}
              />
            ))}
          </List>
        </div>
      </>
    );
  };

  const renderCharges = () => {
    return (
      <>
        {setRender()}
        {display !== 'kiln-type' && (
          <ButtonGroup
            className="Buttons"
            variant="contained"
            disableElevation
            color="primary"
            aria-label="text primary button group"
            size="medium"
          >
            <Button
              color="error"
              variant="contained"
              disableElevation
              onClick={() => {
                handleBackButton();
              }}
            >
              Back
            </Button>
            {display === 'list' && (
              <Button
                className="CreateButton"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => setDisplay('kiln')}
              >
                Create Kiln Charge
              </Button>
            )}
          </ButtonGroup>
        )}
      </>
    );
  };

  return (
    <Container>
      {(loading || kilnsQuery.loading) && <DelayedLinearProgress wholeScreen />}
      {display === 'move-out' ? renderMoveOut() : renderCharges()}
    </Container>
  );
};

export default KilnAdmin;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;

  flex-direction: column;

  .HeaderContainer {
    background-color: #d8ccc2;
    padding: 12px 0;
    position: relative;
    z-index: 1;
    margin-bottom: 5px;
  }

  .Heading {
    text-align: center;
  }

  .Charges {
    overflow-y: scroll;
  }
  .Kilns {
    margin-top: 5px;
    height: 320px;
    overflow-y: scroll;
  }

  .KilnType {
    margin: 30px auto;
  }

  .Buttons {
    margin: 20px 0px;
    align-self: center;
  }

  .NoActiveMessage {
    margin-top: 30px;
    text-align: center;
  }
`;

const SelectKiln: React.FC<{
  kilns: Array<{ id: string | ID; name: string; location: string }>;
  onChoose: (id: string) => void;
}> = ({ kilns, onChoose }) => (
  <>
    <div className="HeaderContainer">
      <Typography variant="h5" className="Heading">
        Select a Kiln
      </Typography>
    </div>
    <div className="Kilns">
      <List component="nav">
        {kilns?.map((kiln) => (
          <ListItem
            key={kiln.id}
            divider
            button
            alignItems="flex-start"
            onClick={() => onChoose(kiln.id)}
          >
            <ListItemText primary={kiln.name} secondary={kiln.location} />
          </ListItem>
        ))}
      </List>
    </div>
  </>
);
