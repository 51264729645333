import React from 'react';
import Wifi from '@mui/icons-material/Wifi';
import styled from 'styled-components';

export const WifiPendingIndicator: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Wrapper>
      <Wifi
        className="icon"
        style={text ? { marginRight: '1em' } : undefined}
      />
      {text && <span className="text">{text}</span>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .icon {
    animation-name: heartbeat;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
  }

  .text {
    margin-bottom: -3px;
    color: #ccc;
  }

  /* Shamelessly pulled from Universal Print (thanks Dustin), with a couple
     of tweaks */
  @keyframes heartbeat {
    0% {
      transform: scale(none);
      opacity: 1;
    }

    20% {
      transform: scale(1.2, 1.2);
    }

    30% {
      transform: scale(1.05, 1.05);
    }

    45% {
      transform: scale(1.25, 1.25);
    }

    50% {
      transform: scale(none, none);
      opacity: 0.3;
    }

    100% {
      transform: scale(none, none);
      opacity: 1;
    }
  }
`;
