
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FileInfo": [
      "DeletedFile",
      "PresentFile"
    ],
    "LabelTemplateUploadPresetsError": [
      "LabelTemplateUploadPresetsInvalidDataError",
      "LabelTemplateUploadPresetsNotFoundError"
    ],
    "ReceiptCreateManualError": [
      "ReceiptCreateManualConflictingPacksError",
      "ReceiptCreateManualInvalidInputError",
      "ReceiptCreateManualNotFoundError"
    ],
    "ReceiptDeletePacksError": [
      "ReceiptDeletePacksNotFoundError"
    ],
    "ReceiptPackReplaceTallyError": [
      "ReceiptPackReplaceTallyInvalidInputError",
      "ReceiptPackReplaceTallyNotFoundError",
      "ReceiptPackReplaceTallyProductKindError"
    ],
    "ReceiptPackSetPieceCountError": [
      "ReceiptPackSetPieceCountInvalidInputError",
      "ReceiptPackSetPieceCountNotFoundError",
      "ReceiptPackSetPieceCountProductKindError"
    ],
    "ReceiptPackUpdateKilnDryDateError": [
      "ReceiptPackUpdateKilnDryDateNotFoundError"
    ],
    "ReceiptPackUpdateProductCodeError": [
      "ReceiptPackUpdateProductCodeInvalidInputError",
      "ReceiptPackUpdateProductCodeNotFoundError"
    ],
    "ReceiptPacksUpdatePurchaseOrderNumberError": [
      "ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError",
      "ReceiptPacksUpdatePurchaseOrderNumberNotFoundError"
    ],
    "ReceiptScanPacksError": [
      "ReceiptScanPacksConflictingPacksError",
      "ReceiptScanPacksInvalidInputError"
    ],
    "ReceiptSendPacksError": [
      "ReceiptSendPacksNotFoundError",
      "ReceiptSendPacksNotSendableError",
      "ReceiptSendPacksSendingFailedError"
    ],
    "ReceiptSubmitUploadError": [
      "ReceiptSubmitUploadConflictingPacksError",
      "ReceiptSubmitUploadInvalidInputError"
    ],
    "ReceiptUploadUpdateDateDispatchedError": [
      "ReceiptUploadUpdateDateDispatchedNotFoundError"
    ],
    "ReceiptUploadUpdateReferenceError": [
      "ReceiptUploadUpdateReferenceInvalidInputError",
      "ReceiptUploadUpdateReferenceNotFoundError"
    ],
    "RunAddInfeedPacksError": [
      "DuplicatePackError",
      "IncompatiblePackError",
      "PackDoesNotExistError",
      "RunDoesNotExistError"
    ],
    "StockConsumeError": [
      "StockConsumeNotFoundError"
    ],
    "StockCreateError": [
      "StockCreateInvalidInputError",
      "StockCreateNotFoundError"
    ],
    "StockCreateSingleError": [
      "StockCreateSingleNoBarcodeError",
      "StockCreateSingleNotFoundError"
    ],
    "StockCreateSingleUndoError": [
      "StockCreateSingleUndoNotFoundError"
    ],
    "StockCreateUndoError": [
      "StockCreateUndoNotFoundError"
    ],
    "UpdateHandlesGetError": [
      "UpdateHandlesGetNotFoundError",
      "UpdateHandlesGetNotModifiableError"
    ],
    "UploadHandlesGetError": [
      "UploadHandlesGetInvalidInputError"
    ],
    "UserError": [
      "LabelTemplateUploadPresetsInvalidDataError",
      "LabelTemplateUploadPresetsNotFoundError",
      "ReceiptCreateManualConflictingPacksError",
      "ReceiptCreateManualInvalidInputError",
      "ReceiptCreateManualNotFoundError",
      "ReceiptDeletePacksNotFoundError",
      "ReceiptPackReplaceTallyInvalidInputError",
      "ReceiptPackReplaceTallyNotFoundError",
      "ReceiptPackReplaceTallyProductKindError",
      "ReceiptPackSetPieceCountInvalidInputError",
      "ReceiptPackSetPieceCountNotFoundError",
      "ReceiptPackSetPieceCountProductKindError",
      "ReceiptPackUpdateKilnDryDateNotFoundError",
      "ReceiptPackUpdateProductCodeInvalidInputError",
      "ReceiptPackUpdateProductCodeNotFoundError",
      "ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError",
      "ReceiptPacksUpdatePurchaseOrderNumberNotFoundError",
      "ReceiptScanPacksConflictingPacksError",
      "ReceiptScanPacksInvalidInputError",
      "ReceiptSendPacksNotFoundError",
      "ReceiptSendPacksNotSendableError",
      "ReceiptSendPacksSendingFailedError",
      "ReceiptSubmitUploadConflictingPacksError",
      "ReceiptSubmitUploadInvalidInputError",
      "ReceiptUploadUpdateDateDispatchedNotFoundError",
      "ReceiptUploadUpdateReferenceInvalidInputError",
      "ReceiptUploadUpdateReferenceNotFoundError",
      "StockConsumeNotFoundError",
      "StockCreateInvalidInputError",
      "StockCreateNotFoundError",
      "StockCreateSingleNoBarcodeError",
      "StockCreateSingleNotFoundError",
      "StockCreateSingleUndoNotFoundError",
      "StockCreateUndoNotFoundError",
      "UpdateHandlesGetNotFoundError",
      "UpdateHandlesGetNotModifiableError",
      "UploadHandlesGetInvalidInputError"
    ]
  }
};
      export default result;
    