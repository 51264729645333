import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Divider,
  Button,
  Typography,
} from '@mui/material';

import { useSelector } from 'react-redux';
import { UserSelectors } from '../../selectors/user';
// @ts-ignore
import SignaturePad from 'react-signature-canvas-react17-compatible';

interface SignatureDialogProps {
  open: boolean;
  close: () => void;
  confirm: (signatureDataUrl: string) => void;
}

export const SignatureDialog: React.FC<SignatureDialogProps> = ({
  open,
  close,
  confirm,
}) => {
  const user = useSelector(UserSelectors.current);

  const ref = useRef<SignaturePad | null>(null);
  const clear = () => {
    ref.current?.clear();
  };

  const captureSignature = () => {
    if (!ref.current) {
      console.error('Cannot access signature ref');
      return;
    }

    const pad = ref.current;
    const value = pad.getTrimmedCanvas().toDataURL('image/png');
    confirm(value);
  };

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AdminFormStyle>
        <DialogContent className="DialogContainer">
          <Typography variant="h6" className="Heading">
            Treatment Technician
          </Typography>
          <Typography variant="h5" className="TechnicianName">
            {user?.givenName} {user?.familyName}
          </Typography>
          <Divider />

          <SignatureStyle>
            <span className="SignatureText">Signature:</span>
            <div className="sigContainer">
              <SignaturePad
                canvasProps={{ className: 'sigPad' }}
                ref={(pad: any) => (ref.current = pad)}
              />
            </div>
            <div>
              <Button
                variant="contained"
                disableElevation
                className="ClearButton"
                onClick={clear}
              >
                Clear
              </Button>
            </div>
          </SignatureStyle>

          <DialogActions className="Buttons">
            <ButtonGroup
              variant="contained"
              color="primary"
              disableElevation
              aria-label="text primary button group"
              size="large"
            >
              <Button size="large" color="error" onClick={close}>
                Back
              </Button>
              <Button size="large" color="primary" onClick={captureSignature}>
                Move Out
              </Button>
            </ButtonGroup>
          </DialogActions>
        </DialogContent>
      </AdminFormStyle>
    </Dialog>
  );
};

const AdminFormStyle = styled.div`
  .DialogContainer {
    text-align: center;
    height: 100vh;
  }
  .Heading {
    margin-bottom: 15px;
    text-align: center;
  }
  .Buttons {
    margin: 0 auto;
    align-self: center;
    justify-content: center;
  }

  .SelectCustomer {
    width: 250px;
    margin: 20px auto;
    display: flex;
  }

  .sigImage {
    margin-top: 20px;
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
  }
`;

const SignatureStyle = styled.div`
  margin-top: 15px;
  .sigContainer {
    height: 175px;
    margin: 10px auto 0px auto;
    background-color: #ececec;
    border: 3px solid #d4d4d4;
  }

  .sigPad {
    width: 100%;
    height: 100%;
  }

  .SignatureText {
    display: flex;
  }

  .ClearButton {
    margin-top: 5px;
  }
`;
