import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { SentryLink } from 'apollo-link-sentry';
import { getCachedPin } from '../../state/user';
import introspectionResult from '../../generated/introspection-result';
import { env } from '../../runtime-environment';
import { maybeOffline, getSimulateOffline } from './network';

class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.message = message;
  }
}

const retryLink = new RetryLink({
  attempts: {
    max: Infinity,
    retryIf(error, _operation) {
      return !(error instanceof UnauthorizedError);
    },
  },
  delay: (count, _operation, _error) => {
    maybeOffline(true);
    const unbounded = 2 ** count * (0.5 + Math.random());
    return Math.min(10000, Math.max(500, unbounded));
  },
});

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
});

const authLink = setContext(async (_, { headers }) => {
  const cachedPin = getCachedPin();
  return {
    headers: {
      ...headers,
      'x-client-app': 'ts-runner',
      authorization: `Basic ${cachedPin}`,
    },
  };
});

let client: ApolloClient<unknown> | null = null;
const makeApolloClient = () => {
  if (client) return client;

  const sentryLink = new SentryLink({
    uri: env.serverUri,
    setTransaction: true,
    setFingerprint: true,
    attachBreadcrumbs: {},
  });

  const httpLink = createHttpLink({
    uri: env.serverUri,
    async fetch(...args) {
      if (getSimulateOffline()) {
        // Useful for testing offline ability
        throw new Error('Simulated network issue');
      }

      const result = await window.fetch(...args);
      if (result.status === 401) {
        throw new UnauthorizedError('401 Response');
      }

      maybeOffline(false);
      return result;
    },
  });

  client = new ApolloClient({
    link: ApolloLink.from([
      retryLink,
      authLink,
      sentryLink,
      httpLink,
      new ApolloLinkTimeout(25000),
    ]),
    cache,
  });
  return client;
};

export default makeApolloClient;
