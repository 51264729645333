import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';

export interface NumpadProps {
  onDigit: (num: number | string) => void;
  onBackspace: () => void;
  inputFocus: string;
}

export const Numpad: React.FC<NumpadProps> = ({
  onDigit,
  onBackspace,
  inputFocus,
}) => {
  const numArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <KeyboardStyle>
      {numArray.map((num) => {
        return (
          <Button
            key={num}
            variant="contained"
            disableElevation
            onMouseDown={(e) => {
              e.preventDefault();
              onDigit(num);
            }}
          >
            {num}
          </Button>
        );
      })}
      <Button
        key={11}
        variant="contained"
        disableElevation
        endIcon={<BackspaceIcon />}
        onMouseDown={(e) => {
          e.preventDefault();
          onBackspace();
        }}
      />
      <Button
        key={12}
        variant="contained"
        disableElevation
        onMouseDown={(e) => {
          e.preventDefault();
          onDigit(0);
        }}
      >
        0
      </Button>
      <Button
        key={13}
        variant="contained"
        disableElevation
        disabled={inputFocus === 'pieces'}
        onMouseDown={(e) => {
          e.preventDefault();
          onDigit('.');
        }}
      >
        •
      </Button>
    </KeyboardStyle>
  );
};

const KeyboardStyle = styled.div`
  display: grid;
  width: 300px;
  margin: auto auto;
  transform: scale(0.9);
  grid-gap: 12px;
  margin-top: 15px;
  grid-template-columns: repeat(3, 1fr);
  button {
    height: 70px;
  }

  .MuiButton-endIcon {
    margin: 0px;
  }

  @media only screen and (max-width: 450px) {
    display: none;
  }
`;
