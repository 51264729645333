import React from 'react';
import styled from 'styled-components';

export interface RunnerProps {
  homepage?: boolean;
}
export const RunnerAnimation: React.FC<RunnerProps> = ({ homepage }) => {
  return (
    <RunnerStyle homepage={homepage}>
      <div id="robot">
        <div className="head"></div>
        <div className="arm l">
          <div>
            <div></div>
          </div>
        </div>
        <div className="leg l">
          <div>
            <div></div>
          </div>
        </div>
        <div className="leg r">
          <div>
            <div></div>
          </div>
        </div>
        <div className="arm r">
          <div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="shadow"></div>
    </RunnerStyle>
  );
};

export default RunnerAnimation;

const RunnerStyle = styled.div<{ homepage?: boolean }>`
  transform: scale(0.45);

  #robot {
    position: relative;
    height: 78vh;
    width: 20vh;
    transform: scale(0.8);
    margin: 0 auto;
    -webkit-animation: robot 1.5s infinite ease-in-out;
    animation: robot 1.5s infinite ease-in-out;
    z-index: 2;
  }
  #robot div {
    position: absolute;
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  #robot .r div {
    background: ${(props) => (props.homepage ? '#85bade30' : '#29516d')};
  }
  #robot .l div {
    background: ${(props) => (props.homepage ? '#85bade30' : '#326384')};
  }
  #robot .leg.r,
  #robot .arm.l {
    -webkit-animation: trans 1.2s 0.65s infinite linear;
    animation: trans 1.2s 0.65s infinite linear;
  }
  #robot .leg.l,
  #robot .arm.r {
    -webkit-animation: trans 1.2s infinite linear;
    animation: trans 1.2s infinite linear;
  }
  #robot .head {
    top: 10vh;
    left: 5vh;
    width: 9vh;
    height: 10vh;
    margin: 0 auto;
    -webkit-transform-origin: 20% 80%;
    transform-origin: 20% 80%;
    -webkit-animation: head 1.2s infinite linear;
    animation: head 1.2s infinite linear;
    background: ${(props) => (props.homepage ? '#85bade30' : '#326384')};
  }
  #robot .arm {
    left: 5vh;
    top: 20.5vh;
    width: 8vh;
    height: 5.5vh;
  }
  #robot .arm div {
    top: 100%;
    left: 0;
    width: 100%;
    height: 10vh;
    box-sizing: border-box;
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
    -webkit-animation: arms1 1.2s infinite linear;
    animation: arms1 1.2s infinite linear;
  }
  #robot .arm div div {
    -webkit-animation: arms2 1.2s infinite linear;
    animation: arms2 1.2s infinite linear;
  }
  #robot .arm.l div {
    -webkit-animation-delay: -0.65s;
    animation-delay: -0.65s;
  }
  #robot .leg {
    top: 45vh;
    left: 5vh;
    width: 8vh;
    height: 4vh;
  }
  #robot .leg div {
    width: 100%;
    height: 15vh;
    top: 100%;
    -webkit-animation: legs1 1.2s infinite linear;
    animation: legs1 1.2s infinite linear;
  }
  #robot .leg div div {
    -webkit-animation: legs2 1.2s infinite linear;
    animation: legs2 1.2s infinite linear;
  }
  #robot .leg.r div {
    -webkit-animation-delay: -0.65s;
    animation-delay: -0.65s;
  }

  .shadow {
    position: relative;
    width: 22vh;
    height: 3vh;
    margin: 0 auto;
    border-radius: 50%;
    background: ${(props) => (props.homepage ? '#85bade30' : '#05314f')};
    -webkit-animation: shadow 1.2s infinite linear;
    animation: shadow 1.2s infinite linear;
    z-index: 1;
  }

  @-webkit-keyframes robot {
    0%,
    50%,
    100% {
      -webkit-transform: translateY(0vh) rotate(7deg);
      transform: translateY(0vh) rotate(7deg);
    }
    15%,
    65% {
      -webkit-transform: translateY(-4vh) rotate(5deg);
      transform: translateY(-4vh) rotate(5deg);
    }
  }
  @keyframes robot {
    0%,
    50%,
    100% {
      -webkit-transform: translateY(0vh) rotate(7deg);
      transform: translateY(0vh) rotate(7deg);
    }
    15%,
    65% {
      -webkit-transform: translateY(-4vh) rotate(5deg);
      transform: translateY(-4vh) rotate(5deg);
    }
  }
  @-webkit-keyframes head {
    0%,
    100% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    25%,
    75% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg);
    }
    50% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  @keyframes head {
    0%,
    100% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    25%,
    75% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg);
    }
    50% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  @-webkit-keyframes trans {
    0%,
    100% {
      -webkit-transform: translateX(0vh);
      transform: translateX(0vh);
    }
    50% {
      -webkit-transform: translateX(3vh);
      transform: translateX(3vh);
    }
  }
  @keyframes trans {
    0%,
    100% {
      -webkit-transform: translateX(0vh);
      transform: translateX(0vh);
    }
    50% {
      -webkit-transform: translateX(3vh);
      transform: translateX(3vh);
    }
  }
  @-webkit-keyframes arms1 {
    0%,
    100% {
      -webkit-transform: rotate(50deg);
      transform: rotate(50deg);
    }
    50% {
      -webkit-transform: rotate(-70deg);
      transform: rotate(-70deg);
    }
  }
  @keyframes arms1 {
    0%,
    100% {
      -webkit-transform: rotate(50deg);
      transform: rotate(50deg);
    }
    50% {
      -webkit-transform: rotate(-70deg);
      transform: rotate(-70deg);
    }
  }
  @-webkit-keyframes arms2 {
    0%,
    100% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    50% {
      -webkit-transform: rotate(-100deg);
      transform: rotate(-100deg);
    }
  }
  @keyframes arms2 {
    0%,
    100% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    50% {
      -webkit-transform: rotate(-100deg);
      transform: rotate(-100deg);
    }
  }
  @-webkit-keyframes legs1 {
    0%,
    100% {
      -webkit-transform: rotate(60deg);
      transform: rotate(60deg);
    }
    40% {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
  @keyframes legs1 {
    0%,
    100% {
      -webkit-transform: rotate(60deg);
      transform: rotate(60deg);
    }
    40% {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
  @-webkit-keyframes legs2 {
    0%,
    100% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
    33% {
      -webkit-transform: rotate(150deg);
      transform: rotate(150deg);
    }
    50% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  @keyframes legs2 {
    0%,
    100% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
    33% {
      -webkit-transform: rotate(150deg);
      transform: rotate(150deg);
    }
    50% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  @-webkit-keyframes shadow {
    0%,
    50%,
    100% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }
    25%,
    75% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
    }
  }
  @keyframes shadow {
    0%,
    50%,
    100% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }
    25%,
    75% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
    }
  }
`;
