import 'date-fns';
import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  InputAdornment,
  ButtonGroup,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import gql from 'graphql-tag';
import {
  useKilnChargeRemoveFromKilnMutation,
  KilnCharge,
} from '../../generated/graphql';
import Swal from 'sweetalert2';
import { Loading } from '../Loading';
import { KilnChargeType } from '../../generated/graphql';
import { SignatureDialog } from './SignatureDialog';
import { IcprDialog } from './IcprDialog';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

gql`
  mutation KilnChargeRemoveFromKiln($input: KilnChargeRemoveFromKilnInput!) {
    kilnChargeRemoveFromKiln(input: $input)
  }
`;

export interface KilnMoveOutProps {
  setMoveOut: () => void;
  kilnChargeId: ID;
  kilnChargeType: KilnChargeType;
  refetch: () => void;
  kilnCharge: KilnCharge;
}

/** Deals with moving out of the Kiln and performs the necessary mutations for generating
 * the ICPR document, and sending to Blob Storage, simultaneously updating TimberSmart
 */
export const KilnMoveOut: React.FC<KilnMoveOutProps> = ({
  setMoveOut,
  kilnChargeId,
  kilnChargeType,
  kilnCharge,
  refetch,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  // Temperature is defaulted to 90 as the operator rarely sets a different temp
  const [temperature, setTemperature] = useState('90');
  const [moveOutKilnCharge, { loading: movingOut }] =
    useKilnChargeRemoveFromKilnMutation({
      errorPolicy: 'ignore',
    });

  const [selectedHours, setSelectedHours] = useState<string>('');
  const [confirmIcpr, setConfirmIcpr] = useState(false);
  const [confirmSignature, setConfirmSignature] = useState(false);

  const temperatureValue = parseFloat(temperature);
  const validated: string | number | Date | null =
    selectedHours && temperature && selectedDate;

  const renderError = (msg: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oh no!',
      text: msg,
    });
  };

  const handleError = () => {
    setConfirmSignature(false);
    setConfirmIcpr(false);
  };

  const handleConfirmIcpr = (isIcpr: boolean) => {
    if (isIcpr) {
      setConfirmSignature(true);
    } else {
      handleConfirmation(null);
    }
  };

  const handleConfirmation = (signatureDataUrl: string | null) => {
    if (isNaN(temperatureValue)) return;

    /**
     * Converts hours to total seconds for more intuitive backend calculations.
     * If the kiln operation is for a `Contract`, then must pass down the customer, PO#, thickness, and M3
     */
    moveOutKilnCharge({
      variables: {
        input: {
          kilnChargeId,
          secondsInKiln: parseInt(selectedHours, 10) * 60 * 60,
          signatureDataUrl: !signatureDataUrl ? null : signatureDataUrl,
          temperatureDegrees: temperatureValue,
          contractInfo:
            kilnChargeType === KilnChargeType.Contract
              ? {
                  customer: kilnCharge.customer!,
                  purchaseOrderNumber: kilnCharge.reference,
                  thicknessMillimeters: kilnCharge.thickness
                    ? kilnCharge.thickness
                    : null,
                }
              : null,
        },
      },
    })
      .then((r: any) => {
        if (r.errors) {
          handleError();
          renderError('Could not move out at this time.');
          Sentry.captureMessage(
            'Failed to move out (kilns)',
            Sentry.Severity.Error,
          );
        } else {
          setConfirmSignature(false);
          refetch();
          setMoveOut();
          Swal.fire({
            icon: 'success',
            title: 'Yay!',
            text: 'Move-Out Successful',
          });
        }
      })
      .catch((error) => {
        handleError();
        Sentry.captureException(error);
        renderError(error.message);
      });
  };

  if (movingOut) {
    return <Loading message="Moving out..." />;
  }

  const renderComplianceForm = () => {
    return (
      <>
        <HeaderContainer className="HeaderContainer">
          <Typography variant="h5" className="Heading">
            Compliance Form
          </Typography>
          <Typography variant="h6" className="Heading">
            #{kilnCharge.chargeNumber}
          </Typography>
        </HeaderContainer>
        <ComplianceFormStyle>
          <form noValidate autoComplete="off">
            <TextField
              label="Temperature"
              value={temperature}
              type="number"
              className="temp"
              onChange={(e) => setTemperature(e.target.value)}
              id="standard-start-adornment"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">°C</InputAdornment>
                ),
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                className="date-picker"
                inputFormat="dd/MM/yyyy"
                renderInput={(props) => <TextField {...props} />}
                label="Move-Out Date"
                value={selectedDate}
                onChange={(selectedDate) => setSelectedDate(selectedDate)}
              />
            </LocalizationProvider>
            <TextField
              id="hours"
              className="Hours"
              label="Hours in Kiln"
              value={selectedHours}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setSelectedHours(e.target.value.replace(/[^0-9.]/g, ''));
              }}
            />
          </form>
        </ComplianceFormStyle>
        <ButtonGroup
          variant="contained"
          color="primary"
          disableElevation
          aria-label="text primary button group"
          size="large"
          style={{ justifyContent: 'center' }}
        >
          <Button color="error" onClick={() => setMoveOut()}>
            Back
          </Button>
          <Button
            disabled={!validated}
            color="primary"
            onClick={() => setConfirmIcpr(true)}
          >
            Next
          </Button>
        </ButtonGroup>

        <IcprDialog
          open={confirmIcpr}
          close={() => setConfirmIcpr(false)}
          handleConfirm={handleConfirmIcpr}
        />

        <SignatureDialog
          open={confirmSignature}
          close={() => setConfirmSignature(false)}
          confirm={handleConfirmation}
        />
      </>
    );
  };

  return renderComplianceForm();
};

export default KilnMoveOut;

const ComplianceFormStyle = styled.div`
  form {
    display: flex;
    flex-direction: column;
    margin: 13px auto 0px auto;
    width: 175px;
    .Hours {
      margin: 15px 0 25px 0;
    }
    .temp {
      margin-bottom: 15px;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 1em !important;
  justify-content: space-between;
  align-items: center;
`;
