import { useCallback, useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';

export type OperationId = string & { type: 'operation-id' };

interface OperationEntry<Operation> {
  id: OperationId;
  op: Operation;
}

export class Persistor<Operation> {
  private storageKey: string;
  private operations: OperationEntry<Operation>[] = [];

  onComplete?: (op: Operation) => void;

  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  /**
   * Add a network action. The `op` will be persisted in localStorage, and can
   * be used with `continueFromEarlier` to continue network actions on refresh
   * or similar. When `action` resolves or throws, it is removed from the
   * persisted list.
   */
  async performAction(
    op: Operation,
    action: (op: Operation) => Promise<void>,
  ): Promise<void> {
    const entry = this.addOperation(op);
    await this.processEntry(entry, action);
  }

  /** Spin back up all pending stored network actions. Does not throw. */
  async continueFromEarlier(action: (op: Operation) => Promise<void>) {
    this.load();
    await Promise.all(
      this.operations.map((entry) => this.processEntry(entry, action)),
    );
  }

  hasPending() {
    return this.operations.length > 0;
  }

  private async processEntry(
    entry: OperationEntry<Operation>,
    action: (op: Operation) => Promise<void>,
  ) {
    try {
      await action(entry.op);
    } finally {
      this.removeOperation(entry.id);
      try {
        this.onComplete?.(entry.op);
      } finally {
        // ignore
      }
    }
  }

  private addOperation(operation: Operation): OperationEntry<Operation> {
    const id = nanoid() as OperationId;
    const entry: OperationEntry<Operation> = { id, op: operation };
    this.operations.push(entry);
    this.save();
    return entry;
  }

  private removeOperation(id: OperationId): void {
    const index = this.operations.findIndex((op) => op.id === id);
    if (index === -1) return;
    this.operations.splice(index, 1);
    this.save();
  }

  private save() {
    if (this.operations.length === 0) {
      localStorage.removeItem(this.storageKey);
    } else {
      localStorage.setItem(this.storageKey, JSON.stringify(this.operations));
    }
  }

  private load() {
    const item = localStorage.getItem(this.storageKey);
    this.operations = item == null ? [] : JSON.parse(item);
  }
}

export function usePersistedActions<Operation>({
  storageKey,
  action,
  onComplete,
}: {
  storageKey: string;
  action: (op: Operation) => Promise<void>;
  onComplete?: (op: Operation) => void;
}) {
  const persistor = useRef(new Persistor<Operation>(storageKey));
  const [hasPending, setHasPending] = useState(() =>
    persistor.current.hasPending(),
  );

  persistor.current.onComplete = useCallback(
    (op) => {
      onComplete?.(op);
      setHasPending(persistor.current.hasPending());
    },
    [onComplete],
  );

  useEffect(() => {
    persistor.current.continueFromEarlier(action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performAction = useCallback(
    (op: Operation) => {
      setHasPending(true);
      return persistor.current.performAction(op, action);
    },
    [action],
  );

  return {
    performAction,
    hasPending,
  };
}
