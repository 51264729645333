import React from 'react';
import styled from 'styled-components';
import RunnerAnimation from '../assets/RunnerAnimation';
import Wifi from '@mui/icons-material/Wifi';
import { Typography } from '@mui/material';
import { useMaybeOffline } from '../services/apollo/network';

export interface LoadingProps {
  message?: string;
}

export const Loading: React.FC<LoadingProps> = ({ message }) => {
  const maybeOffline = useMaybeOffline();
  return (
    <LoadingStyling>
      <RunnerAnimation />
      {message && <div className="message">{message}</div>}
      {maybeOffline && (
        <div className="wifi">
          <Wifi />
          <Typography style={{ marginLeft: '1.5em', width: 200 }}>
            Experiencing network issues, please ensure you are in internet range
          </Typography>
        </div>
      )}
    </LoadingStyling>
  );
};

const LoadingStyling = styled.div`
  z-index: 1999;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .message {
    font-size: 17px;
    font-weight: 500;
    position: fixed;
    padding: 15px;
    border-radius: 10px;
    background: #000000;
    height: 40px;
    color: white;
    display: table;
  }

  .wifi {
    margin-bottom: 2em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiTypography-root {
      font-size: 0.85em;
    }
  }

  align-items: center;
`;
