import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  ButtonGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Collapse,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import gql from 'graphql-tag';
import { Loading } from '../../Loading';
import { Error } from '../../Error';
import { useHistory } from 'react-router-dom';
import Alert from '@mui/lab/Alert';
import {
  useRunCompleteMutation,
  useGetInfeedPacksQuery,
  useRunAddInfeedPacksMutation,
  useRunRemovePackMutation,
} from '../../../generated/graphql';
import { useRouteMatch } from 'react-router-dom';
import { OptiLineDisplay } from '../OptiItemDisplay';
import { DelayedLinearProgress } from '../../DelayedLinearProgress';

gql`
  query GetInfeedPacks($id: ID!) {
    run(id: $id) {
      id
      description
      location {
        name
      }
      infeed {
        packs {
          packId
          productCode
        }
      }
      type {
        type
      }
    }
  }

  mutation RunAddInfeedPacks($input: RunAddInfeedPacksInput!) {
    runAddInfeedPacks(input: $input) {
      success
      error
      errorCode
    }
  }

  mutation RunComplete($input: RunCompleteInput!) {
    runComplete(input: $input)
  }

`;

type ExtraMessageDisplay = {primaryMessage: string, secondaryMessage: string}

/** Deals with the scanning of packs in an Opti Run */
const OptiScan: React.FC = () => {
  const { runId, date } = useRouteMatch<{ runId: ID; date: any }>().params;
  const [dialog, setDialog] = useState<'none' | 'finish' | 'cancel'>('none');
  const [deleteItemConfirm, setDeleteItemConfirm] = useState<ID | null>(null);
  const [packId, setPackId] = useState<ID>('' as ID);
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [closeRun] = useRunCompleteMutation();
  const [erroneousPack, setErroneousPack] = useState< ExtraMessageDisplay | null >(null);
  const [showExtraMessage, setShowExtraMessage] = useState(false);

  const { data, loading, error, refetch } = useGetInfeedPacksQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: runId,
    },
  });

  // Reverses packs to show the most recently scanned item at the top of the list
  const packs = [...(data?.run?.infeed?.packs ?? [])].reverse();

  const [runAddInfeedPacks, { loading: addingPack }] =
    useRunAddInfeedPacksMutation();
  const [removePack, { loading: deletingPack }] = useRunRemovePackMutation();

  if (loading && !data) return <Loading message="Just a moment..." />;
  if (error || !data) {
    return <Error fullscreen />;
  }

  if (addingPack) return <Loading message="Adding pack..." />;
  if (deletingPack) return <Loading message="Deleting pack..." />;

  const renderError = (msg: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oh no!',
      text: msg,
    });
  };

  const packsScannedPrettier = () => {
    const packsExist = packs?.length && packs?.length > 0;
    return `Scan Packs... (${packsExist && packs?.length})`;
  };

  const onEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') onScanPack();
  };

  const onScanPack = () => {
    setShowExtraMessage(false);
    setErroneousPack(null);
    // Skip if packId is blank, e.g. when the user has not scanned anything
    if (packId === '') return;
    runAddInfeedPacks({
      variables: {
        input: {
          runId: runId,
          packs: [
            {
              packId: packId,
              processDate: new Date().toISOString(),
            },
          ],
        },
      },
    })
      .then((pack) => {
        const error = pack.data?.runAddInfeedPacks?.error;
        if (error) {
          Sentry.captureMessage('Failed to add pack', Sentry.Severity.Error);

          const ERRONEOUS_SCAN: ExtraMessageDisplay = {
            primaryMessage: `${error.toLocaleUpperCase()}`,
            secondaryMessage: '',
          }

          // error logic specific to error message
          if (error === 'Pack is already on infeed') {
            ERRONEOUS_SCAN.secondaryMessage = `${packId}:EXISTS, SCAN NEXT PACK`;
          }
          if (error === 'Pack does not exist.') {
            ERRONEOUS_SCAN.secondaryMessage = `LOGISTICS WILL BE NOTIFIED`;
          }
          setErroneousPack(ERRONEOUS_SCAN);
          setShowExtraMessage(true);

        } else if (pack.errors) {
          const ERRONEOUS_SCAN: ExtraMessageDisplay= {
            primaryMessage: 'UNKOWN ERROR',
            secondaryMessage: 'THE IT TEAM HAS BEEN ALERTED'
          }

          setErroneousPack(ERRONEOUS_SCAN);
          setShowExtraMessage(true);
        }
        refetch();
      })
      .catch((error) => {
        Sentry.captureException(error);
        const ERRONEOUS_SCAN: ExtraMessageDisplay = {
          primaryMessage: 'UNABLE TO PROCESS',
          secondaryMessage: 'PLEASE SCAN AGAIN'
        }

        setErroneousPack(ERRONEOUS_SCAN);
        setShowExtraMessage(true);
      });

    setPackId('' as ID);
  };

  const handleCloseRun = () => {
    setDialog('none');
    closeRun({
      variables: { input: { runId, costSpread: false } },
    })
      .then((p) => {
        if (p.errors) {
          Sentry.captureMessage('Failed to close run', Sentry.Severity.Error);
          renderError('Could not close run!  Please contact IT or go back.');
        }
        Swal.fire({
          icon: 'success',
          title: 'Yay!',
          text: 'Run successfully closed.',
        });
        history.goBack();
      })
      .catch((error) => {
        Sentry.captureException(error);
        renderError('Could not close run!  Please contact IT or go back.');
      });
  };

  const handleDeletePack = () => {
    removePack({
      variables: {
        input: {
          packId: deleteItemConfirm!,
          runId: runId,
        },
      },
    })
      .then((r) => {
        if (r.errors) {
          Sentry.captureMessage('Failed to remove pack', Sentry.Severity.Error);
          renderError('Unable to remove this pack.');
        } else {
          refetch();
        }
        setDeleteItemConfirm(null);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setDeleteItemConfirm(null);
        renderError('Unable to remove this pack from the scan.');
      });
  };

  const isOpti: boolean = data?.run?.type?.type === "Opti In*"

  return (
    <Container>
      {loading && <DelayedLinearProgress wholeScreen />}
      <div className="HeaderContainer">
        <div className="OptiHeader">
          <span className="HeaderID">{runId}</span>
          <span style={{ fontSize: 12 }}>{data?.run?.description ?? ''}</span>
          <span className="HeaderDate">{date}</span>
        </div>
      </div>
      <Collapse in={open}>
        <Alert
          className="DeleteInfo"
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Tap a row to delete a pack.
        </Alert>
      </Collapse>
      <div style={{ padding: '0 16px' }}>
        <TextField
          autoComplete="off"
          id="BarcodeInput"
          placeholder={packsScannedPrettier()}
          value={packId}
          onChange={(e) => setPackId(e.target.value as ID)}
          onKeyUp={onEnterPress}
          fullWidth
          autoFocus
          onBlur={() =>
            dialog === 'none' &&
            deleteItemConfirm == null &&
            document.getElementById('BarcodeInput')?.focus()
          }
        />
      </div>

      <List component="nav" className="Packs">
        {showExtraMessage && (
          <ListItem divider alignItems="center" style={{ color: 'red', backgroundColor: 'lightgrey', textAlign: 'center' }}>
            <ListItemText primary={erroneousPack?.primaryMessage} secondary={erroneousPack?.secondaryMessage} style={{color: 'red'}} />
          </ListItem>
          )
        }
        {packs?.map((p) => (
          <OptiLineDisplay
            deleteItem={setDeleteItemConfirm}
            packId={p.packId}
            productCode={p.productCode}
          />
        ))}
      </List>
      <ButtonGroup
        className="Buttons"
        variant="contained"
        color="primary"
        aria-label="text primary button group"
        size="medium"
      >
        <Button color="error" onClick={() => setDialog('cancel')}>
          Back
        </Button>
        <Button onClick={() => setDialog('finish')}>Finish</Button>
      </ButtonGroup>
      <Dialog open={dialog === 'cancel'}>
        <DialogTitle>Go back?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you are finished scanning?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog('none')} color="error">
            Cancel
          </Button>
          <Button
            onClick={() => {
              history.goBack();
            }}
            variant="contained"
            color="primary"
          >
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteItemConfirm != null}>
        <DialogTitle>Delete from scan?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete pack <b>{deleteItemConfirm}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteItemConfirm(null)} color="error">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeletePack();
            }}
            variant="contained"
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialog === 'finish'}>
        <DialogTitle>
          {isOpti ? 'Are you sure you want to complete this?' : 'Finished?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isOpti ? 'Has the Opti finished its run?' : 'Are you sure? Press FINISH to end session.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog('none')} color="error">
            {isOpti ? 'No, cancel!' : 'Cancel'}
          </Button>
          <Button
            onClick={() => {
              handleCloseRun();
            }}
            color="primary"
            variant="contained"
          >
            {isOpti ? 'Yes, complete it!' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OptiScan;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .Buttons {
    margin-top: 7px;
    margin-bottom: 5px;
    align-self: center;
  }

  .Heading {
    text-align: center;
    margin-top: 20px;
  }

  .TotalPacks {
    font-size: 15px;
    text-align: right;
    margin: 4px 3px;
  }

  .Packs {
    margin: 15px 0px 10px 0px;
    height: 275px;
    overflow-y: scroll;
  }

  .OptiHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 17px;
    font-weight: 500;
  }
  .MuiAlert-root {
    padding: 0px 12px;
  }

  .DeleteInfo {
    margin-bottom: 10px;
  }

  .HeaderContainer {
    background-color: #d8ccc2;
    padding: 7px 0;
    z-index: 1500;
    margin-bottom: 5px;
  }
`;
