import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';

export interface FilletingInfeedItemDisplayProps {
  packId: ID;
  productCode: string;
  deleteItem: (packId: ID) => void;
}

export const FilletingInfeedItemDisplay: React.FC<
  FilletingInfeedItemDisplayProps
> = ({ packId, productCode, deleteItem }) => (
  <FilletingItemStyle>
    <ListItem
      key={packId}
      dense
      divider
      button
      alignItems="flex-start"
      onClick={() => deleteItem(packId)}
    >
      <ListItemText primary={packId} secondary="ID" />
      <ListItemText primary={productCode} secondary="Product Code" />
      <DeleteIcon className="DeleteIcon" />
    </ListItem>
  </FilletingItemStyle>
);

const FilletingItemStyle = styled.div`
  .MuiIconButton-root {
    padding: 0px;
  }
  .MuiIconButton-edgeEnd {
    margin-right: 0px;
  }
  .ProductCode {
    text-align-last: right;
  }
  .DeleteIcon {
    position: absolute;
    right: 0;
    font-size: large;
    bottom: 12px;
  }
`;
