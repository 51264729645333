import React, { useState, useEffect, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  DialogContentText,
  ButtonGroup,
  Typography,
  List,
  ListItemText,
  ListItem,
  TextField,
  CircularProgress,
} from '@mui/material';
import styled from 'styled-components';
import InStock from '@mui/icons-material/Check';
import NotInStock from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Swal from 'sweetalert2';
import gql from 'graphql-tag';
import { Loading } from '../../Loading';
import { Error } from '../../Error';
import { useHistory } from 'react-router-dom';
import {
  useGetStocktakeQuery,
  useAddStocktakePackMutation,
  useStocktakeFinishMutation,
  GetStocktakeDocument,
  GetStocktakeQuery,
  GetStocktakeQueryVariables,
  StocktakePackInventoryStatus,
} from '../../../generated/graphql';
import { useRouteMatch } from 'react-router-dom';
import { useMaybeOffline } from '../../../services/apollo/network';
import { usePersistedActions } from '../../../services/persist/Persistor';
import { WifiPendingIndicator } from '../../WifiPendingIndicator';

gql`
  query GetStocktake($input: ID!) {
    stocktake(id: $input) {
      id
      dateStarted
      dateFinished
      location
      addedPacks(mine: true) {
        id
        packId
        inventoryStatus
      }
    }
  }

  mutation AddStocktakePack($input: StocktakeAddPackInput!) {
    stocktakeAddPack(input: $input) {
      id
      packId
      inventoryStatus
    }
  }

  mutation StocktakeFinish($input: StocktakeFinishInput!) {
    stocktakeFinish(input: $input)
  }
`;

type AddPackOperation = { stocktakeId: ID; packId: ID } | { stocktakeId: ID; packId: string;};
type ExtraMessageDisplay = {primaryMessage: string, secondaryMessage: string}


const StocktakeScan: React.FC = () => {
  const { stocktakeId } = useRouteMatch<{ stocktakeId: ID }>().params;
  const maybeOffline = useMaybeOffline();

  const { performAction, hasPending } = usePersistedActions<AddPackOperation>({
    storageKey: 'stocktake-packs',
    action: useAddStocktakePack(stocktakeId),
  });

  const [dialog, setDialog] = useState<'cancel' | 'finish' | 'none'>('none');

  const [scannedText, setScannedText] = useState('');
  const [mostRecentScanned, setMostRecentScanned] = useState('');
  // scanning error handling state
  const [erroneousPack, setErroneousPack] = useState< ExtraMessageDisplay | null >(null);
  const [showExtraMessage, setShowExtraMessage] = useState(false);

  const history = useHistory();

  const [stocktakeFinish, { loading: finishingStocktake }] =
    useStocktakeFinishMutation();

  const { data, error } = useGetStocktakeQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: stocktakeId,
    },
  });

  const stocktakePacks = [...(data?.stocktake?.addedPacks ?? [])].reverse();
  
  useEffect(() => {
    window.onbeforeunload = () => (stocktakeId == null ? null : true);
    return () => {
      window.onbeforeunload = null;
    };
  }, [stocktakeId]);

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') onScanPack();
  };

  const onScanPack = () => {
    // set to false in case the last scan displayed an error message
    setShowExtraMessage(false);
    setErroneousPack(null);
    if (scannedText === '') return;

    const packId = parseUniversalPackLabel(scannedText) ?? scannedText.trim();

    if (!stocktakePacks.some((pack) => pack.packId === packId)) {
      // perform action 
      performAction({ stocktakeId, packId: packId as ID });
      setMostRecentScanned(packId);
      
    } else {
      // show duplicate pack message at top of screen
      setMostRecentScanned(`DUPLICATE SCAN OF ${packId}`);
      const ERRONEOUS_SCAN: ExtraMessageDisplay = {
        primaryMessage: `DUPLICATE SCAN OF ${packId}`,
        secondaryMessage: 'please scan next pack',
      }
      // state updates with duplicate object and status
      setErroneousPack(ERRONEOUS_SCAN);
      setShowExtraMessage(true);
    }
    setScannedText('');
  };

  const handleFinishStocktake = () => {
    setDialog('none');
    stocktakeFinish({
      variables: {
        input: {
          stocktakeId: stocktakeId,
        },
      },
    })
      .then((pack) => {
        if (pack.errors) {
          Sentry.captureMessage(
            'Failed to finish stocktake',
            Sentry.Severity.Error,
          );
          renderError(
            'Could not finish Stocktake! Please contact IT or go back.',
          );
        }
        Swal.fire({
          icon: 'success',
          title: 'Yay!',
          text: 'Stock take successful.',
        });

        history.goBack();
      })
      .catch((error) => {
        Sentry.captureException(error);
        renderError(
          'Could not finish Stocktake! Please contact IT or go back.',
        );
      });
  };

  if (finishingStocktake) {
    return <Loading message="Finishing stocktake..." />;
  }

  if (error || !data) {
    return <Error fullscreen />;
  }

  return (
    <Container>
      <div className="HeaderContainer">
        <Typography variant="h6" className="Heading">
          <span className="LastScanned">
            Last Scanned: {mostRecentScanned || 'n/a'}
          </span>
          <span className="HeaderID"> ID: {stocktakeId}</span>
        </Typography>
      </div>

      <div style={{ padding: '0 16px' }}>
        <TextField
          autoComplete="off"
          id="BarcodeInput"
          placeholder="Scan Pack..."
          value={scannedText}
          onChange={(e) => setScannedText(e.target.value)}
          onKeyUp={onKeyPress}
          fullWidth
          autoFocus
          onBlur={() =>
            dialog === 'none' &&
            document.getElementById('BarcodeInput')?.focus()
          }
        />
      </div>
      <List dense component="nav" className="Packs">
      {showExtraMessage && (
          <ListItem divider alignItems="center" style={{ color: 'red', backgroundColor: 'lightgrey', textAlign: 'center' }}>
            <ListItemText primary={<ContentCopyIcon fontSize='large' />} />
            <ListItemText primary={erroneousPack?.primaryMessage} secondary={erroneousPack?.secondaryMessage} style={{color: 'red'}} />
            <ListItemText primary={<ContentCopyIcon fontSize='large' />}  />
          </ListItem>
        )
        }
        {stocktakePacks?.map((pack) => {
          const pending = pack.id.startsWith('cached:');

          let primary, secondary;
          if (pending) {
            primary = maybeOffline ? (
              <div style={{ marginTop: '10px' }}>
                <WifiPendingIndicator />
              </div>
            ) : (
              <CircularProgress size={30} style={{ marginRight: 20 }} />
            );
            secondary = undefined;
          } else if (
            pack.inventoryStatus !== StocktakePackInventoryStatus.NotFound
          ) {
            primary = <InStock />;
            secondary =
              pack.inventoryStatus === StocktakePackInventoryStatus.Finished
                ? 'Finished'
                : 'WIP';
          } else {
            primary = <NotInStock />;
            secondary = 'Not in Stock';
          }

          return (
            <ListItem key={pack.id} divider alignItems="flex-start" button>
              <ListItemText
                primary={pack.packId}
                secondary={<span style={{ marginTop: '6px' }}>Pack ID</span>}
              />
              <ListItemText
                primary={primary}
                secondary={secondary}
                style={{
                  maxWidth: 80,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              />
            </ListItem>
          );
        })}
      </List>
      <ButtonGroup
        className="Buttons"
        variant="contained"
        disableElevation
        color="primary"
        aria-label="text primary button group"
        size="large"
      >
        <Button color="error" onClick={() => setDialog('cancel')}>
          Back
        </Button>
        <Button
          color="primary"
          disabled={hasPending}
          onClick={() => setDialog('finish')}
        >
          Finish
        </Button>
      </ButtonGroup>

      <Dialog open={dialog === 'cancel'}>
        <DialogTitle>Go back?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure? You have not finished the run yet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog('none')} color="error">
            Cancel
          </Button>
          <Button
            onClick={() => {
              history.push('/');
            }}
            variant="contained"
            color="primary"
          >
            Go Back
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialog === 'finish'}>
        <DialogTitle>Finish run?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure? Pressing Submit will close for <b>all users</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog('none')} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFinishStocktake} color="error">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

function useAddStocktakePack(stocktakeId: ID) {
  const [stocktakeAddPack] = useAddStocktakePackMutation();
  return useCallback(
    async (operation: AddPackOperation) => {
      if (operation.stocktakeId !== stocktakeId) {
        return;
      }

      await stocktakeAddPack({
        variables: {
          input: {
            stocktakeId: operation.stocktakeId,
            packId: operation.packId as ID,
          },
        },
        // Optimistic response is what you expect the return from the server to be
        // Update is what actually happens with the data
        // The `id` is arbitrarily set to `-1` as is the typical convention
        optimisticResponse: {
          __typename: 'Mutation',
          stocktakeAddPack: {
            __typename: 'StocktakePack',
            id: `cached:${Math.random()}` as ID,
            packId: operation.packId as ID,
            inventoryStatus: StocktakePackInventoryStatus.NotFound,
          },
        },
        update: (store, { data }) => {
          if (data?.stocktakeAddPack == null) {
            return;
          }
          // Read the data from our cache for this query
          const cached = store.readQuery<
            GetStocktakeQuery,
            GetStocktakeQueryVariables
          >({
            query: GetStocktakeDocument,
            variables: { input: stocktakeId },
          });
          if (cached?.stocktake == null) {
            return;
          }

          // Write update data back to the cache
          store.writeQuery<GetStocktakeQuery, GetStocktakeQueryVariables>({
            query: GetStocktakeDocument,
            data: {
              ...cached,
              stocktake: {
                ...cached.stocktake,
                // Add stocktake item from the mutation to the list of packs.
                addedPacks: [
                  ...cached.stocktake.addedPacks,
                  data.stocktakeAddPack,
                ],
              },
            },
          });
        },
      }).catch((error) => {
        Sentry.captureException(error);
        renderError(error.message);
      });
    },
    [stocktakeId, stocktakeAddPack],
  );
}

function renderError(msg: string) {
  Swal.fire({
    icon: 'error',
    title: 'Oh no!',
    text: msg,
  });
}

function parseUniversalPackLabel(scannedValue: string) {
  try {
    // Replace curly quotes
    scannedValue = scannedValue
      .replace(/[\u2018\u2019]/g, "'")
      .replace(/[\u201C\u201D]/g, '"');

    // Don't actually parse as JSON since sometimes scanners fail to scan the
    // full datamatrix properly. By only requiring a small section of the JSON
    // to be present we increase the likelihood of success. This matches the
    // "PackNumber": "..." field.
    const packNumber =
      scannedValue.match(/er":"(?<packNumber>[A-Z]*\d*)"/)?.groups
        ?.packNumber ?? null;
    return packNumber as string | null;
  } catch {
    return null;
  }
}

export default StocktakeScan;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .Buttons {
    margin-top: 1.5em;
    align-self: center;
  }

  .Heading {
    font-size: 15px;
    margin: 5px 0px 0px 15px;
  }

  .Packs {
    margin-top: 15px;
    height: 280px;
    overflow-y: scroll;
  }

  .HeaderID {
    position: absolute;
    right: 25px;
  }
  .HeaderContainer {
    background-color: #d8ccc2;
    padding: 7px 0;
    z-index: 1500;
    margin-bottom: 5px;
  }
`;
