import React, { useState, useRef, useCallback, Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  DialogTitle,
  ButtonGroup,
  DialogContentText,
} from '@mui/material';
import styled from 'styled-components';
import { Loading } from '../../Loading';
import { Error } from '../../Error';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useRouteMatch } from 'react-router-dom';
import {
  useKilnChargeAddItemsMutation,
  useKilnChargeMoveToKilnMutation,
  useGetChargeItemsQuery,
  useKilnChargeRemoveItemMutation,
  GetChargeItemsDocument,
  GetChargeItemsQuery,
  KilnChargeType,
} from '../../../generated/graphql';
import Swal from 'sweetalert2';
import { KilnItemDisplay } from '../KilnItemDisplay';
import { NetworkStatus } from '@apollo/client';
import { usePersistedActions } from '../../../services/persist/Persistor';
import { useMaybeOffline } from '../../../services/apollo/network';
import { DelayedLinearProgress } from '../../DelayedLinearProgress';

gql`
  mutation KilnChargeAddItems($input: KilnChargeAddItemsInput!) {
    kilnChargeAddItems(input: $input)
  }
`;

gql`
  mutation KilnChargeMoveToKiln($input: KilnChargeMoveToKilnInput!) {
    kilnChargeMoveToKiln(input: $input)
  }
`;

gql`
  mutation KilnChargeRemoveItem($input: KilnChargeRemoveItemInput!) {
    kilnChargeRemoveItem(input: $input)
  }
`;

gql`
  query GetChargeItems($kilnChargeId: ID!) {
    kilnCharge(id: $kilnChargeId) {
      id
      type
      chargeItems {
        id
        metersCubed
        kilnCharge {
          id
        }
        packId
      }
    }
  }
`;

const CONTRACT_PREFIX = 'CTR';

interface AddOperation {
  type: 'add';
  packId: ID;
  metersCubed: number | null;
  kilnChargeId: ID;
}

interface RemoveOperation {
  type: 'remove';
  packId: ID;
  kilnChargeId: ID;
}

type Operation = AddOperation | RemoveOperation;

type ExtraMessageDisplay = { primaryMessage: string, secondaryMessage: string }



/** This component deals with the scanning process of moving packs into a Kiln  */
const KilnMoveIn: React.FC = () => {
  // grabs the KilnChargeID made from the KilnAdmin
  const { kilnCharge } = useRouteMatch<{
    kilnCharge: ID;
  }>().params;
  const history = useHistory();
  const maybeOffline = useMaybeOffline();
  const [alternateChargeNumber, setAlternateChargeNumber] = useState('');
  const [erroneousPack, setErroneousPack] = useState<ExtraMessageDisplay | null>(null);
  const [showExtraMessage, setShowExtraMessage] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [moveInKilnCharge, { loading: movingIn }] =
    useKilnChargeMoveToKilnMutation();
  const [kilnItemState, setKilnItemState] = useState<string>('');

  const [deleteItemConfirm, setDeleteItemConfirm] = useState<string | null>(
    null,
  );
  const [enterExtraDetails, setEnterExtraDetails] = useState(false);

  const { data, loading, error, networkStatus } = useGetChargeItemsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { kilnChargeId: kilnCharge },
  });
  const barcodeInput = useRef(null);

  const manageItems = useManageKilnChargeItems(kilnCharge, setErroneousPack, setShowExtraMessage);
  const { performAction, hasPending } = usePersistedActions<Operation>({
    storageKey: 'kiln-charge-items',
    action: manageItems.performAction,
  });



  const isLoading = loading && networkStatus !== NetworkStatus.refetch;
  if (isLoading && !data) return <Loading />;
  if (error || !data?.kilnCharge) {
    return <Error fullscreen />;
  }

  if (movingIn) return <Loading message="Moving in..." />;
  if (manageItems.removingItem) return <Loading message="Removing item..." />;

  const chargeItems = [...(data?.kilnCharge?.chargeItems ?? [])].sort((a, b) =>
    b!.id.localeCompare(a!.id),
  );

  const onScanKilnPack = async () => {
    setShowExtraMessage(false);
    setKilnItemState('');

    if (kilnItemState === '') return;
    if (data.kilnCharge == null) return;

    if (chargeItems.find((x) => x.packId === kilnItemState)) {
      // duplicate error
      const ERRONEOUS_SCAN: ExtraMessageDisplay = {
        primaryMessage: `PACK ${kilnItemState} ALREADY IN CHARGE`,
        secondaryMessage: `SCAN NEXT ITEM`,
      }
      setErroneousPack(ERRONEOUS_SCAN);
      setShowExtraMessage(true);
      return;
    }

    let metersCubed;
    try {
      metersCubed = await getM3(data.kilnCharge.type);
    } catch {
      return;
    }

    await performAction({
      type: 'add',
      packId: kilnItemState as ID,
      metersCubed,
      kilnChargeId: kilnCharge,
    }).catch(() => {
      // process error
      const ERRONEOUS_SCAN: ExtraMessageDisplay = {
        primaryMessage: `COULD NOT ADD PACK`,
        secondaryMessage: `LOGISTICS HAS BEEN NOTIFIED`,
      };

      setErroneousPack(ERRONEOUS_SCAN);
      setShowExtraMessage(true);
    });
  };

  const onSubmitMoveIn = () => {
    moveInKilnCharge({
      variables: {
        input: {
          kilnChargeId: kilnCharge,
          alternateChargeNumber,
        },
      },
    })
      .then((r: any) => {
        if (r.errors) {
          Sentry.captureMessage(
            'Failed to move in (kilns)',
            Sentry.Severity.Error,
          );
          renderError(
            'Unable to move in this charge. Try again or contact IT.',
          );
          return;
        }
        Swal.fire({
          icon: 'success',
          title: 'Yay!',
          text: 'Move-In Successful',
        });
        history.goBack();
      })
      .catch((error) => {
        Sentry.captureException(error);
        renderError('Unable to move in this charge. Try again or contact IT.');
      });
  };

  const onKilnItemKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') onScanKilnPack();
  };

  const deleteItem = (packId: string) => {
    setDeleteItemConfirm(packId);
  };

  const handleDeletePack = () => {
    performAction({
      type: 'remove',
      kilnChargeId: kilnCharge,
      packId: deleteItemConfirm! as ID,
    });
    setDeleteItemConfirm(null);
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value.trim();
    try {
      const parsedData = JSON.parse(inputValue);
      setKilnItemState(parsedData.PackNumber);
    } catch {
      setKilnItemState(inputValue);
    }
  }

  const renderExtraInputs = () => {
    return (
      <div className="Inputs">
        <TextField
          className="AltChargeNum"
          id="hours"
          label="Alternate Charge Number"
          value={alternateChargeNumber}
          onChange={(e: any) => setAlternateChargeNumber(e.target.value || '')}
        />
      </div>
    );
  };

  const renderScan = () => {
    const itemCount = (chargeItems ?? []).length;
    const totalM3 = (chargeItems ?? [])
      .reduce((acc, cur) => acc + (cur.metersCubed ?? 0), 0)
      .toFixed(2);


    return (
      <>
        <div
          style={{
            padding: 16,
            paddingBottom: 4,
            paddingTop: 0,
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #ccc',
          }}
        >
          <div>
            <TextField
              autoComplete="off"
              id="BarcodeInput"
              ref={barcodeInput}
              placeholder="Scan Kiln Item..."
              onChange={handleInputChange}
              fullWidth
              onKeyUp={onKilnItemKeyUp}
              autoFocus
              value={kilnItemState}
              onBlur={() => !cancel && barcodeInput.current}
            />
          </div>
          <div
            style={{
              textAlign: 'right',
              marginLeft: '0.5em',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography>
              {itemCount} Item{itemCount !== 1 ? 's' : ''}
            </Typography>
            {kilnCharge.startsWith(CONTRACT_PREFIX) && (
              <Typography>{totalM3 + ' M3'}</Typography>
            )}
          </div>
        </div>
        <List className="Runs">
          {showExtraMessage && (
            <ListItem divider alignItems="center" style={{ color: 'red', backgroundColor: 'lightgrey', textAlign: 'center' }}>
              <ListItemText primary={erroneousPack?.primaryMessage} secondary={erroneousPack?.secondaryMessage} style={{ color: 'red' }} />
            </ListItem>
          )
          }
          {chargeItems?.map((i) => (
            <KilnItemDisplay
              id={i.id}
              packId={i.packId}
              m3={i.metersCubed}
              key={i.id}
              pending={i.id.startsWith('cached:')}
              maybeOffline={maybeOffline}
              deleteItem={deleteItem}
            />
          ))}
        </List>
      </>
    );
  };

  const validNext = !hasPending && chargeItems?.length > 0;

  return (
    <Container>
      {loading && <DelayedLinearProgress wholeScreen />}
      <div className="HeaderContainer">
        <Typography variant="h5" className="Heading">
          {enterExtraDetails ? 'Extra Details' : 'Scan'}
        </Typography>
      </div>
      {enterExtraDetails ? renderExtraInputs() : renderScan()}

      <ButtonGroup
        className="Buttons"
        variant="contained"
        disableElevation
        color="primary"
        aria-label="text primary button group"
        size="large"
      >
        <Button
          color="error"
          onClick={() =>
            !enterExtraDetails ? setCancel(true) : setEnterExtraDetails(false)
          }
        >
          Back
        </Button>
        <Button
          style={{ backgroundColor: '#375954' }}
          disabled={!validNext}
          onClick={() => {
            enterExtraDetails ? onSubmitMoveIn() : setEnterExtraDetails(true);
          }}
        >
          {enterExtraDetails ? 'Move In' : 'Next'}
        </Button>
      </ButtonGroup>
      <Dialog open={deleteItemConfirm != null}>
        <DialogTitle>Delete from scan?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete pack <b>{deleteItemConfirm}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteItemConfirm(null)} color="error">
            Back
          </Button>
          <Button
            onClick={() => {
              handleDeletePack();
            }}
            variant="contained"
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={cancel}>
        <DialogTitle>Go back?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to return to the <b>Kiln Admin</b> page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancel(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              history.goBack();
            }}
            color="primary"
            variant="contained"
          >
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default KilnMoveIn;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .Buttons {
    bottom: 10px;
    align-self: center;
    margin-bottom: 0.5em;
  }
  .Runs {
    overflow-y: auto;
    height: 290px;
    align-content: center;
  }

  .Heading {
    text-align: center;
    margin-top: 10px;
    color: #375954;
  }

  .HeaderContainer {
    background-color: #d8cec2;
    padding: 1px 0;
    z-index: 1500;
    margin-bottom: 3px;
  }

  .EnterM3 {
    margin: 0px auto 30px auto;
  }

  .Inputs {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .AltChargeNum {
      margin: 0 auto;
      width: 200px;
      margin-bottom: 30px;
    }
  }
`;

function useManageKilnChargeItems(kilnChargeId: ID, setErroneousPack: Dispatch<SetStateAction<ExtraMessageDisplay | null>>, setShowExtraMessage: Dispatch<SetStateAction<boolean>>) {
  const [addKilnChargeItems] = useKilnChargeAddItemsMutation({
    refetchQueries: ['GetChargeItems'],
    awaitRefetchQueries: true,
  });
  const [removeKilnChargeItem, { loading: removingItem }] =
    useKilnChargeRemoveItemMutation({
      refetchQueries: ['GetChargeItems'],
      awaitRefetchQueries: true,
    });

  const performAction = useCallback(
    async (op: Operation) => {
      if (kilnChargeId !== op.kilnChargeId) {
        return;
      }

      if (op.type === 'remove') {
        await removeKilnChargeItem({
          variables: {
            input: {
              kilnChargeId: op.kilnChargeId,
              packId: op.packId,
            },
          },
        })
          .then((r) => {
            if (!r.errors) return;
            Sentry.captureMessage(
              'Failed to remove pack',
              Sentry.Severity.Error,
            );
            renderError('Unable to remove this pack.');
          })
          .catch((error) => {
            Sentry.captureException(error);
            renderError('Unable to remove this pack from the charge.');
          });
        return;
      }

      await addKilnChargeItems({
        variables: {
          input: {
            kilnChargeId: op.kilnChargeId,
            packs: [
              { kilnChargePackId: op.packId, metersCubed: op.metersCubed },
            ],
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          kilnChargeAddItems: [`cached:${Math.random()}` as ID],
        },
        update: (store, { data }) => {
          // Read the data from our cache for this query
          const data2: GetChargeItemsQuery | null = store.readQuery({
            query: GetChargeItemsDocument,
            variables: { kilnChargeId },
          });

          if (!data2?.kilnCharge) return;

          // Write our data back to the cache.
          type Obj = Exclude<
            GetChargeItemsQuery['kilnCharge'],
            null | undefined
          >['chargeItems'][0];
          store.writeQuery<GetChargeItemsQuery>({
            query: GetChargeItemsDocument,
            data: {
              __typename: 'Query',
              kilnCharge: {
                __typename: 'KilnCharge',
                id: data2.kilnCharge.id,
                type: data2.kilnCharge.type,
                // Add stocktake item from the mutation to the list of packs.
                chargeItems: [
                  ...data2.kilnCharge.chargeItems,
                  ...(data?.kilnChargeAddItems
                    ?.map(
                      (id): Obj => ({
                        __typename: 'KilnChargeItem',
                        id,
                        packId: op.packId,
                        metersCubed: op.metersCubed,
                        kilnCharge: {
                          __typename: 'KilnCharge',
                          id: data2.kilnCharge!.id,
                        },
                      }),
                    )
                    .sort((a, b) => b!.id.localeCompare(a!.id)) || []),
                ],
              },
            },
          });
        },
      })
        .then((r) => {
          if (!r.errors) return;
          Sentry.captureMessage('Failed to add pack', Sentry.Severity.Error);
          const ERRONEOUS_SCAN: ExtraMessageDisplay = {
            primaryMessage: `UNABLE TO ADD PACK`,
            secondaryMessage: `TECHNOLOGY HAS BEEN NOTIFIED`,
          }
          setErroneousPack(ERRONEOUS_SCAN);
          setShowExtraMessage(true);
        })
        .catch((error) => {
          Sentry.captureException(error);
          const ERRONEOUS_SCAN: ExtraMessageDisplay = {
            primaryMessage: `${error.toLocaleUpperCase()}`,
            secondaryMessage: `ERROR PROCESSING, TRY AGAIN`,
          }
          setErroneousPack(ERRONEOUS_SCAN);
          setShowExtraMessage(true);
        });
    },
    [addKilnChargeItems, removeKilnChargeItem, kilnChargeId, setErroneousPack, setShowExtraMessage],
  );

  return {
    removingItem,
    performAction,
  };
}

function renderError(msg: string) {
  Swal.fire({
    icon: 'error',
    title: 'Oh no!',
    text: msg,
  });
}

async function getM3(kilnChargeType: KilnChargeType): Promise<number | null> {
  if (kilnChargeType === KilnChargeType.Standard) {
    return null;
  }

  const result = await Swal.fire({
    title: 'Enter pack M3',
    input: 'text',
    inputLabel: 'M3',
    showCancelButton: true,
  });

  if (result.value === '' || isNaN(Number(result.value))) {
    Swal.fire('Error', 'Invalid M3 value', 'error');
    throw new window.Error('Invalid M3 value');
  }

  return Number(result.value);
}
