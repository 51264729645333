import { makeVar, useReactiveVar } from '@apollo/client';
import { releaseProfile } from '../../runtime-environment';

export const canSimulateOffline = releaseProfile() !== 'production';

const simulateOffline = makeVar(
  localStorage.getItem('simulateOffline') === 'true',
);

export const getSimulateOffline = () => simulateOffline();

export function useSimulateOffline() {
  return useReactiveVar(simulateOffline);
}

export function toggleSimulateOffline() {
  const newVal = !simulateOffline();
  simulateOffline(newVal);
  localStorage.setItem('simulateOffline', newVal ? 'true' : 'false');
}

export const maybeOffline = makeVar(false);

export function useMaybeOffline() {
  return useReactiveVar(maybeOffline);
}
