import React from 'react';
import {
  Button,
  ButtonGroup,
  Container,
  Typography,
  Grid,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../state';
import styled from 'styled-components';
import { Loading } from '../../Loading';
import { RouteComponentProps } from 'react-router-dom';
import { Supplier } from '../../../models/Supplier';
import { ButtonStyle } from './ReceiptCreator';
import { useScanPacksMutation } from '../../../generated/graphql';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import { displayUserErrors } from '../../../errors';
import { Error } from '../../Error';

gql`
  mutation ScanPacks($input: ReceiptScanPacksInput!) {
    result: receiptScanPacks(input: $input) {
      scanEvent {
        id
      }
      userErrors {
        ... on UserError {
          __typename
          message
        }
      }
    }
  }
`;

const ReceiptFinish: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const lines = useSelector<AppState, string[]>((s) => s.receipting.items);
  const dispatch = useDispatch();

  const supplier = useSelector<AppState, Supplier | null>(
    (s) => s.receipting.supplier,
  );

  const reference = useSelector<AppState, string>(
    (s) => s.receipting.reference,
  );

  const renderMessage = (msg: string, success?: boolean) => {
    Swal.fire({
      icon: success ? 'success' : 'error',
      title: success ? 'Nice!' : 'Oh no!',
      text: msg,
    });
  };

  const [scanPacks, { loading }] = useScanPacksMutation();

  if (loading) {
    return <Loading message="Sending to TimberSmart..." />;
  }

  if (supplier == null) {
    return <Error fullscreen errorText="Not actively receipting" />;
  }

  const handleSend = () => {
    const promise = scanPacks({
      variables: {
        input: {
          supplierId: supplier.id,
          reference: reference.trim(),
          packNumbers: lines,
        },
      },
    });

    displayUserErrors(
      'result',
      promise.then((res) => {
        const errors = res.data?.result.userErrors;
        if (errors != null && errors.length === 0) {
          renderMessage('Packs submitted!', true);
          setTimeout(function () {
            history.push('/receipting');
          }, 1000);
          dispatch({
            type: 'CLEAR_ALL',
          });
        }
        return res;
      }),
      true,
    );
  };

  const showItems = () => {
    return (
      <div className="BarcodeList">
        {lines.map((l: string, x: number) => (
          <div className="Barcode" key={x}>
            {l}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Container
      maxWidth="sm"
      style={{ paddingTop: '1rem', height: '100vh', overflow: 'hidden' }}
    >
      <Typography variant="h5" align="center" color="primary">
        Summary
      </Typography>
      <SummaryStyle>
        <Grid container direction="column" style={{ textAlign: 'center' }}>
          <Grid item>
            <div className="item">
              <span className="Heading">Supplier: </span>
              <div className="Value"> {supplier.name}</div>
            </div>
          </Grid>
          <Grid item>
            <div className="item">
              <span className="Heading">Reference: </span>
              <div className="Value">{reference ? reference : 'Not Given'}</div>
            </div>
          </Grid>

          <Typography>
            <span className="Heading Item-Heading">Items</span>
          </Typography>

          <Grid item style={{ overflow: 'auto' }}>
            {showItems()}
          </Grid>
          <Grid item style={{ paddingTop: '1rem' }}>
            <ButtonStyle>
              <ButtonGroup
                className="Buttons"
                variant="contained"
                disableElevation
                color="primary"
                aria-label="text primary button group"
                size="large"
                style={{ justifyContent: 'center' }}
              >
                <Button
                  style={{ width: '100%', height: '32px' }}
                  color="error"
                  variant="contained"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Button>

                <Button
                  style={{ width: '100%', height: '32px' }}
                  color="primary"
                  variant="contained"
                  onClick={() => handleSend()}
                >
                  Submit
                </Button>
              </ButtonGroup>
            </ButtonStyle>
          </Grid>
        </Grid>
      </SummaryStyle>
    </Container>
  );
};

export default ReceiptFinish;

const SummaryStyle = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  text-align-last: left;

  .item {
    width: 100%;
    display: inline-flex;
    flex-flow: wrap;
  }

  .Heading {
    font-weight: 500;
  }

  .Value {
    margin-left: 5px;
    margin-bottom: 10px;
    .GivenName {
      margin-right: 5px;
    }
  }

  .BarcodeList {
    background: #3263840d;
    max-height: 220px;
    padding-top: 5px;
    overflow: scroll;
    .Barcode {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }
`;
