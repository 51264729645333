import React, { useState, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import {
  TextField,
  List,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Button,
  Typography,
  FormControl,
} from '@mui/material';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import gql from 'graphql-tag';
import {
  useAddOutfeedPackItemsMutation,
  usePrintOutfeedPackLabelMutation,
  Run,
  RunOutfeedPack,
} from '../../generated/graphql';
import { Loading } from '../Loading';
import { Numpad } from '../Numpad';
import { FilletingOutfeedItemDisplay } from './FilletingOutfeedItemDisplay';

gql`
  mutation AddOutfeedPackItems($input: RunAddOutfeedPacksInput!) {
    runAddOutfeedPacks(input: $input)
  }

  mutation PrintOutfeedPackLabel($input: RunPrintOutfeedPackInput!) {
    runPrintOutfeedPack(input: $input)
  }
`;

export interface FilletingOutfeedProps {
  run: Run | null | undefined;
  refetch: () => void;
  deleteItem: (packId: ID) => void;
  deleteItemConfirm: boolean;
  finish: boolean;
}

export const FilletingOutfeed: React.FC<FilletingOutfeedProps> = ({
  run,
  refetch,
  deleteItem,
}) => {
  const [open, setOpen] = useState(false);
  const [piecesState, setPiecesState] = useState('');
  const [lengthState, setLengthState] = useState('');
  const [inputFocus, setInputFocus] = useState<'length' | 'pieces'>('length');
  const [openPack, setOpenPack] = useState<RunOutfeedPack | null>(null);
  const [addOutfeedPackItems, { loading }] = useAddOutfeedPackItemsMutation();
  const [printOutfeedPackLabel] = usePrintOutfeedPackLabelMutation();
  const gcsId = run?.infeed?.packs[0]?.gcsId;

  const openOutfeedItemModal = (pack: RunOutfeedPack) => {
    setOpenPack(pack);
  };

  const addNumpadDigit = useCallback(
    (digit: any) => {
      if (inputFocus === 'length') {
        setLengthState((v) => `${v}${digit}`);
      } else if (inputFocus === 'pieces') {
        setPiecesState((v) => `${v}${digit}`);
      }
    },
    [inputFocus],
  );

  const numpadBackspace = useCallback(() => {
    if (inputFocus === 'length') {
      setLengthState((v) => v.slice(0, -1));
    } else if (inputFocus === 'pieces') {
      setPiecesState((v) => v.slice(0, -1));
    }
  }, [inputFocus]);

  const runOutfeed = [...(run?.outfeed?.packs ?? [])].sort((a, b) =>
    b!.lastUpdate.localeCompare(a!.lastUpdate),
  );

  const renderError = (msg: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oh no!',
      text: msg,
    });
  };

  const setDefaultState = () => {
    setLengthState('');
    setPiecesState('');
  };

  const createOutfeedPack = () => {
    if (!gcsId) return;

    addOutfeedPackItems({
      variables: {
        input: {
          runId: run!.id,
          packs: [
            {
              processDate: new Date().toISOString(),
              gcsId: gcsId,
              tally: [
                {
                  pieces: parseInt(piecesState, 10),
                  length: parseFloat(lengthState),
                },
              ],
            },
          ],
        },
      },
    })
      .then((r) => {
        if (r.errors) {
          renderError('Unable to add this pack to the run.');
          setDefaultState();
          Sentry.captureMessage(
            'Failed to add pack to run',
            Sentry.Severity.Error,
          );
        } else {
          setDefaultState();
          setOpen(false);
          refetch();
        }
      })
      .catch((error) => {
        setDefaultState();
        Sentry.captureException(error);
        renderError('Unable to add this pack to the run.');
      });
  };

  if (loading) return <Loading />;

  function handleNext(event: any, nextField: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const nextFieldElem = window.document.getElementById(nextField);
      nextFieldElem?.focus?.();
    }
  }

  return (
    <Container>
      <Button
        className="AddOutfeedPackButton"
        variant="contained"
        color="primary"
        disableElevation
        disabled={!gcsId}
        onClick={() => setOpen(true)}
      >
        Add Outfeed Pack
      </Button>
      <div className="OutfeedPacks">
        {runOutfeed ? (
          <List component="nav">
            {runOutfeed.map((pack) => (
              <FilletingOutfeedItemDisplay
                key={pack.packId}
                pack={pack}
                openOutfeedItemModal={openOutfeedItemModal}
              />
            ))}
          </List>
        ) : (
          <div className="NoActiveMessage">No outfeed packs yet.</div>
        )}
      </div>

      <Dialog open={openPack != null}>
        <DialogTitle>{openPack?.packId}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenPack(null)} color="error">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (openPack?.packId) deleteItem(openPack.packId);
              setOpenPack(null);
            }}
            variant="contained"
            color="primary"
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              printOutfeedPackLabel({
                variables: {
                  input: {
                    runId: run!.id,
                    packId: openPack?.packId!,
                  },
                },
              }).catch((error) => {
                Sentry.captureException(error);
                renderError('Failed to re-print pack label.');
              });

              setOpenPack(null);
            }}
            variant="contained"
            color="primary"
          >
            Print
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <OptiExtraInfoStyle>
          <DialogContent className="DialogContainer">
            <Typography variant="h6" className="Header">
              Fill in LM and PCE
            </Typography>
            <FormControl required className="FormStyle">
              <TextField
                id="Length"
                label="Length (LM)"
                onChange={(e) => setLengthState(e.target.value)}
                value={lengthState}
                type="number"
                autoFocus
                onFocus={() => setInputFocus('length')}
                onKeyPress={(e) => handleNext(e, 'Pieces')}
              />
              <br />
              <TextField
                id="Pieces"
                name="Pieces"
                type="number"
                label="Pieces"
                value={piecesState}
                onFocus={() => setInputFocus('pieces')}
                onChange={(e) => setPiecesState(e.target.value)}
              />
            </FormControl>
            <Numpad
              onDigit={addNumpadDigit}
              onBackspace={numpadBackspace}
              inputFocus={inputFocus}
            />
          </DialogContent>

          <DialogActions className="Buttons">
            <ButtonGroup
              variant="contained"
              disableElevation
              color="primary"
              aria-label="text primary button group"
              size="large"
            >
              <Button
                color="error"
                onClick={() => {
                  setDefaultState();
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={lengthState === '' || piecesState === ''}
                onClick={() => createOutfeedPack()}
              >
                Add and Print
              </Button>
            </ButtonGroup>
          </DialogActions>
        </OptiExtraInfoStyle>
      </Dialog>
    </Container>
  );
};

export default FilletingOutfeed;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .AddOutfeedPackButton {
    align-self: center;
  }

  .OutfeedPacks {
    height: 245px;
    overflow-y: scroll;
    .NoActiveMessage {
      margin-top: 20px;
      text-align: center;
    }
    @media only screen and (min-width: 450px) {
      height: calc(100vh - 320px);
    }
  }
`;

const OptiExtraInfoStyle = styled.div`
  .DialogContainer {
    text-align: center;

    max-width: 600px;
    margin: auto auto;
    margin-top: 10px;
    display: block;
    z-index: 5;
    @media only screen and (max-width: 450px) {
      height: 80vh;
    }
  }

  .MuiDialog-paper {
    overflow: hidden;
  }
  overflow: hidden;

  .Header {
    text-align: center;
    margin-bottom: 10px;
  }
  .Buttons {
    align-self: center;
    justify-content: center;
    margin: 0 auto;
  }

  .FormStyle {
    margin: auto auto;
    display: flex;
    max-width: 300px;
    flex-direction: column;
  }
`;
