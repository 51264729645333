import { combineReducers } from 'redux';
import { user } from './user';
import { receipting } from './receipting';

export const rootReducer = combineReducers({
  user,
  receipting,
});

export type AppState = ReturnType<typeof rootReducer>;
