import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: ID;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  JSON: unknown;
  Upload: unknown;
};

/** Describes access permissions to an application. */
export type AppAccess = {
  __typename: 'AppAccess';
  /** Textual ID for identifying the app. */
  id: Scalars['String'];
};

/**
 * Stores a number which changes when used, intended for generating sequences or
 * unique identifiers.
 */
export type Counter = {
  __typename: 'Counter';
  /** Unique identifier for the counter. Can be any string. */
  id: Scalars['ID'];
  /**
   * The amount the `value` of the counter will change when used once. May be
   * negative.
   */
  updateChange: Scalars['Int'];
  /** The counter's current value. */
  value: Scalars['Int'];
};

export type CounterCreateInput = {
  /** Unique identifier for fetching the `Counter` once created. Can be any string. */
  id: Scalars['ID'];
  /**
   * The initial value of the counter. Is the value that will be returned when
   * first running the `useCounter` mutation for this `Counter` ID.
   */
  initialValue: Scalars['Int'];
  /**
   * If `true`, will replace any existing counter. If `false` or unspecified,
   * counter creation would fail if the ID was already registered.
   */
  reinitialize?: InputMaybe<Scalars['Boolean']>;
  /**
   * The amount the `value` of the counter will change when used once. May be
   * negative.
   */
  updateChange: Scalars['Int'];
};

export type Debtor = {
  __typename: 'Debtor';
  id: Scalars['ID'];
};

export type DebtorInvoice = {
  __typename: 'DebtorInvoice';
  id: Scalars['ID'];
};

export type DebtorInvoiceLine = {
  __typename: 'DebtorInvoiceLine';
  id: Scalars['ID'];
};

/** Information about a file that has been deleted. */
export type DeletedFile = {
  __typename: 'DeletedFile';
  deletionReason: FileDeletionReason;
};

/** A durable file containing human-readable information. Can be printed. */
export type Document = {
  __typename: 'Document';
  /** Unique ID. */
  id: Scalars['ID'];
  /** URL for displaying the document, valid for approximately 30 minutes */
  url: Scalars['String'];
};

export type DocumentGenerateCommandInput = {
  /**
   * The data to apply to the `DocumentTemplate` to generate a document.
   * If the `DocumentTemplate` provides default/example data, this is optional.
   */
  data?: InputMaybe<Scalars['JSON']>;
  /** The `DocumentTemplate` that should be used, for example 'examples.hello'. */
  templateId: Scalars['ID'];
  /**
   * Location to upload the generated document to. Should be an Azure Blob Storage
   * URL (with SAS token or otherwise authenticated), typically obtained via use of
   * `uploadHandlesGet`.
   */
  uploadUrl: Scalars['String'];
};

export type DocumentGenerateInput = {
  /** List of commands for generating documents. */
  commands: Array<DocumentGenerateCommandInput>;
};

export type DocumentGeneratePayload = {
  __typename: 'DocumentGeneratePayload';
  /**
   * Always an empty array
   * @deprecated Always an empty array, so no longer serves a purpose.
   */
  documents: Array<Document>;
  message: Scalars['String'];
};

export type DocumentPrintCommandInput = {
  /** Number of copies to print. Cannot be negative. Defaults to 1. */
  copies?: InputMaybe<Scalars['Int']>;
  /** ID of the printer to use. */
  printer: Scalars['String'];
  /** Location to fetch the document from, before printing. Must be a PDF file. */
  url: Scalars['String'];
};

export type DocumentPrintInput = {
  /** List of commands for printing. */
  commands: Array<DocumentPrintCommandInput>;
};

export type DocumentPrintPayload = {
  __typename: 'DocumentPrintPayload';
  message: Scalars['String'];
};

export type DuplicatePackError = {
  __typename: 'DuplicatePackError';
  /** Human-readable error message. */
  message: Scalars['String'];
};

export enum EmailAttachmentContentKind {
  /** Treat contents as the attachment data itself, encoded as base 64. */
  Base64 = 'BASE64',
  /** Treat contents as a URL to download the attachment data from. */
  DownloadUrl = 'DOWNLOAD_URL'
}

export type EmailSendAttachmentInput = {
  /** The raw content of the attachment. */
  content: Scalars['String'];
  /** Describes how to interpret `content`. */
  contentKind: EmailAttachmentContentKind;
  /** The content type of the attachment, e.g. `application/pdf`. */
  contentType: Scalars['String'];
  /** The filename the attachment should have. */
  filename: Scalars['String'];
};

export type EmailSendEmailInput = {
  /** List of attachments to send. */
  attachments: Array<EmailSendAttachmentInput>;
  /** List of BCC email recipients. If not provided, equivalent to the empty list. */
  bcc?: InputMaybe<Array<Scalars['String']>>;
  /** List of CC email recipients. If not provided, equivalent to the empty list. */
  cc?: InputMaybe<Array<Scalars['String']>>;
  /**
   * The email address acting as the sender. Must be from a domain configured with
   * SendGrid. If not specified, the default from address will be used.
   */
  from?: InputMaybe<Scalars['String']>;
  /** Use to define the header, such as setting Importance levels. */
  headers?: InputMaybe<Scalars['JSON']>;
  /** Only use if `templateId` is null. */
  html?: InputMaybe<Scalars['String']>;
  /** Only use if `templateId` is null. */
  subject?: InputMaybe<Scalars['String']>;
  /** Only use if `templateId` is null. */
  templateData?: InputMaybe<Scalars['JSON']>;
  /** The template id to use for the data (see `templates.staging-production.json`). */
  templateId?: InputMaybe<Scalars['ID']>;
  /** Only use if `templateId` is null. */
  text?: InputMaybe<Scalars['String']>;
  /** List of email recipients. */
  to: Array<Scalars['String']>;
};

export type EmailSendInput = {
  emails: Array<EmailSendEmailInput>;
};

export type EmailSendPayload = {
  __typename: 'EmailSendPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Email template that can be printed. */
export type EmailTemplate = {
  __typename: 'EmailTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A handle to a file in Azure Blob Storage. */
export type File = {
  __typename: 'File';
  /** Mime-type of the file, e.g. "application/pdf". */
  contentType: Scalars['String'];
  /** When, if at all, the file will expire or did expire. */
  expireAt?: Maybe<Scalars['DateTime']>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Data relating to the file, different depending on the file's status */
  info: FileInfo;
  /** Name of the file, if specified when creating an upload handle. */
  name?: Maybe<Scalars['String']>;
};

export enum FileDeletionReason {
  /** File was deleted automatically because it expired. */
  Expired = 'EXPIRED'
}

export type FileInfo = DeletedFile | PresentFile;

export enum FilePermissions {
  /** The default. Files can only be read. */
  Read = 'READ',
  /**
   * Files can be updated after creation - an upload url can always be
   * requested. However unlike the `accessUrl` field, doing so requires
   * the admin scope.
   */
  ReadUpdate = 'READ_UPDATE'
}

export type Fulfilment = {
  __typename: 'Fulfilment';
  id: Scalars['ID'];
};

export type FulfilmentLine = {
  __typename: 'FulfilmentLine';
  id: Scalars['ID'];
};

export type FulfilmentLinePack = {
  __typename: 'FulfilmentLinePack';
  id: Scalars['ID'];
};

export type IncompatiblePackError = {
  __typename: 'IncompatiblePackError';
  /** Human-readable error message. */
  message: Scalars['String'];
};

/** Describes a physical transfer of a collection of physical products 'StockItem's from one location to another. */
export type InventoryTransfer = {
  __typename: 'InventoryTransfer';
  /** Creating user. */
  createdBy: User;
  /** The date of creation. */
  dateCreated: Scalars['Date'];
  /** The date of receival. */
  dateReceived?: Maybe<Scalars['Date']>;
  /** The destination of the stock. */
  destination: StockLocation;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Where stock is drawn from. */
  origin: StockLocation;
  /** Amount of packs being transferred. */
  packCount: Scalars['Int'];
  /** List of packs to be moved. */
  packs: Array<InventoryTransferPack>;
  /** Reference. */
  reference: Scalars['String'];
  /** Only for `InventoryTransferType` `TRUCK`, specifies trucking company. */
  shippingProvider?: Maybe<ShippingProvider>;
  /** The shipment status. */
  status: InventoryTransferStatus;
  /** The total weight in kilograms. */
  totalKilograms: Scalars['Float'];
  /** The M3. */
  totalMetersCubed: Scalars['Float'];
  /** Optional Treatment location for packs are being sent be treated  */
  treatmentPlant?: Maybe<TreatmentPlant>;
  /** The method of transportation. */
  type: InventoryTransferType;
};

export type InventoryTransferAddPackInput = {
  /** The `InventoryTransfer` this pack is being added to. */
  inventoryTransferId: Scalars['ID'];
  /** The description of the stock for manual treatment transfers. Only applicable if stock code = 'MANUAL'. */
  manualDescription?: InputMaybe<Scalars['String']>;
  /** The shipping Destination of the stock for manual treatment transfers. Only applicable if stock code = 'MANUAL'. */
  manualShippingDestination?: InputMaybe<Scalars['String']>;
  /** The treatment type of the stock for manual treatment transfers. Only applicable if stock code = 'MANUAL'. */
  manualTreatmentType?: InputMaybe<Scalars['String']>;
  /** M3 of pack. */
  metresCubed: Scalars['Float'];
  /** The unique ID of the pack. */
  packNumber: Scalars['String'];
  /** The quantity of the given `StockItem` contained in the pack being added. */
  quantity: Scalars['Float'];
  /** Stockcode ID of pack contents. */
  stockCode: Scalars['String'];
  /** In some cases the server may throw an error with a type of 'TREATMENT_WARNING' which the user must acknowledge prior to the pack being added. */
  treatmentWarningAcknowledged?: Scalars['Boolean'];
  /** The Unit Number if it was present in the pack's barcode */
  unitNumber?: InputMaybe<Scalars['String']>;
  /** Weight of pack. */
  weightKilograms: Scalars['Float'];
};

export type InventoryTransferAddPackPayload = {
  __typename: 'InventoryTransferAddPackPayload';
  /** The `InventoryTransfer` the pack was added to. */
  inventoryTransfer: InventoryTransfer;
  /** The `InventoryTransferPack` added. */
  pack: InventoryTransferPack;
  /** Any warning messages about the pack added. */
  warning?: Maybe<Scalars['String']>;
};

export type InventoryTransferCreateInput = {
  /** The location of `InventoryTransfer` destination. */
  destinationStockLocationId: Scalars['ID'];
  /** The method of transfer. */
  inventoryTransferType: InventoryTransferType;
  /** The location of `InventoryTransfer` origin. */
  originStockLocationId: Scalars['ID'];
  /** Only for `InventoryTransferType` = `TRUCK`. Specifies trucking company */
  shippingProviderId?: InputMaybe<Scalars['ID']>;
  /** Only for`InventoryTransferType` = `INSTANT`. Specifies treatment plant */
  treatmentPlantId?: InputMaybe<Scalars['ID']>;
};

export type InventoryTransferCreatePayload = {
  __typename: 'InventoryTransferCreatePayload';
  /** The `InventoryTransfer` that has been created. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferDeleteInput = {
  /** The `InventoryTransfer` being soft deleted. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferDeletePayload = {
  __typename: 'InventoryTransferDeletePayload';
  /** The `InventoryTransfer` that has been deleted. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferGiveUpInput = {
  /** The `InventoryTransfer` to give up. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferGiveUpPayload = {
  __typename: 'InventoryTransferGiveUpPayload';
  /** The `InventoryTransfer` that has just been given up. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferInput = {
  __typename: 'InventoryTransferInput';
  destinationLocationId: Scalars['ID'];
  fromLocationId: Scalars['ID'];
  inventoryTransferId: Scalars['ID'];
  packs?: Maybe<Array<InventoryTransferPack>>;
  reference?: Maybe<Scalars['String']>;
};

/** A (typically) large number of items of a particular `StockItem` packed efficiently so as to facilitate transport. */
export type InventoryTransferPack = {
  __typename: 'InventoryTransferPack';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** The `InventoryTransfer` this pack belongs to. */
  inventoryTransfer: InventoryTransfer;
  /** The description given by the user for manual packs. */
  manualDescription?: Maybe<Scalars['String']>;
  /** The total M3 of the pack. */
  metresCubed: Scalars['Float'];
  /** The pack number. */
  packNumber: Scalars['String'];
  /** Total number of this `InventoryTransferPack`. */
  quantity: Scalars['Float'];
  /** The Shipping Destination as recorded for this pack. Separate to the stock item as it may change over time. */
  shippingDestination?: Maybe<Scalars['String']>;
  /** The `StockItem` contained within. Note optional as manual transfers may not have a regular stock item associated with them. */
  stockItem?: Maybe<StockItem>;
  /** The treatment type as recorded for this pack. Separate to the stock item as it may change over time. */
  treatmentType?: Maybe<Scalars['String']>;
  /** The Unit Number if it was present in the pack's barcode */
  unitNumber?: Maybe<Scalars['String']>;
  /** The details text derived from unit number, customer etc. */
  unitText?: Maybe<Scalars['String']>;
  /** The total weight of the pack in kilograms. */
  weightKilograms: Scalars['Float'];
};

export type InventoryTransferReceiveInput = {
  /** The `InventoryTransfer` being received. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferReceivePayload = {
  __typename: 'InventoryTransferReceivePayload';
  /** The `InventoryTransfer` that was received. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferRedirectInput = {
  /** The new destination location. If null, destination remains the same. */
  destinationStockLocationId?: InputMaybe<Scalars['ID']>;
  /** The `InventoryTransfer` to redirect. */
  inventoryTransferId: Scalars['ID'];
  /** The new origin location. If null, origin remains the same. */
  originStockLocationId?: InputMaybe<Scalars['ID']>;
};

export type InventoryTransferRedirectPayload = {
  __typename: 'InventoryTransferRedirectPayload';
  /** Error message, if any. */
  error?: Maybe<Scalars['String']>;
  /** The `InventoryTransfer` that has just been redirected. */
  inventoryTransfer?: Maybe<InventoryTransfer>;
};

export type InventoryTransferRemovePackInput = {
  /** The `InventoryTransferPack` being removed from the `InventoryTransfer`. */
  packId: Scalars['ID'];
};

export type InventoryTransferRemovePackPayload = {
  __typename: 'InventoryTransferRemovePackPayload';
  /** The `InventoryTransfer` the `InventoryTransferPack` was removed from */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferStartTransitInput = {
  /** The `InventoryTransfer` who's status is being set to `IN_TRANSIT`. */
  inventoryTransferId: Scalars['ID'];
  /** Reference for quick recognition of `InventoryTransfer` by office staff. */
  reference: Scalars['String'];
};

export type InventoryTransferStartTransitPayload = {
  __typename: 'InventoryTransferStartTransitPayload';
  /** The `InventoryTransfer` describing the packs that have just left the origin `StockLocation`. */
  inventoryTransfer: InventoryTransfer;
};

/** The possible statuses of an `InventoryTransfer` in relation to its desired location. */
export enum InventoryTransferStatus {
  /** Status once received at the desired location */
  Complete = 'COMPLETE',
  /** Status upon creation at the location of origin */
  Creating = 'CREATING',
  /** Status on soft deletion. */
  Deleted = 'DELETED',
  /** Status when a user gives up on a transfer */
  Error = 'ERROR',
  /**
   * Status when error occurs in EXO update when receiving a transfer. The user may
   * safely reattempt `inventoryTransferReceive` again on a shipment with this
   * status.
   */
  ErrorReceiveTransfer = 'ERROR_RECEIVE_TRANSFER',
  /**
   * Status when error occurs in EXO update when starting a transfer. The user may
   * try to perform the `inventoryTransferStartTransit` operation again if they
   * wish. If a transfer is in this state, stock levels are not in an inconsistent
   * state - nothing has happened in EXO due to the transfer.
   */
  ErrorStartTransit = 'ERROR_START_TRANSIT',
  /**
   * Status when error occurs in EXO update when undoing a transfer. The user may
   * safely reattempt `inventoryTransferUndo` again on a shipment with this status.
   */
  ErrorUndoTransit = 'ERROR_UNDO_TRANSIT',
  /** Status during transit, on the way to the desired location */
  InTransit = 'IN_TRANSIT'
}

/** The method of transport for an `InventoryTransfer`. */
export enum InventoryTransferType {
  /** `InventoryTransfer` being sent in a shipping `CONTAINER`. */
  Container = 'CONTAINER',
  /**
   * `InventoryTransfer` is to appear immediately in the destination location,
   * without need for receipting.
   */
  Instant = 'INSTANT',
  /** `InventoryTransfer` being sent by `TRUCK`. */
  Truck = 'TRUCK'
}

export type InventoryTransferUndoInput = {
  /** The `InventoryTransfer` being reverted from status `COMPLETE` back to `IN_TRANSIT`, in case of a user error. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferUndoPayload = {
  __typename: 'InventoryTransferUndoPayload';
  /** The `InventoryTransfer` that was undone. */
  inventoryTransfer: InventoryTransfer;
};

/** A particular type of workstation responsible for drying wood. */
export type Kiln = {
  __typename: 'Kiln';
  id: Scalars['ID'];
  /**
   * String location of the `Kiln`. Could be changed to a `Location`, if we decide
   * that that is more desirable, and worth the contractor cost.
   */
  locationName: Scalars['String'];
  /** Name of the `Kiln`. Appears to have a short location prefix. */
  name: Scalars['String'];
};

/** Describes a set of items to be or which have been loaded into a `Kiln`. */
export type KilnCharge = {
  __typename: 'KilnCharge';
  /** Alternate charge number, or an empty string if not given. */
  alternateChargeNumber: Scalars['String'];
  /** The set of items to be loaded into the `Kiln`. */
  chargeItems: Array<KilnChargeItem>;
  /** Unique identifier. */
  chargeNumber: Scalars['String'];
  /** Customer for the contract charge. */
  customer?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** When the charge entered the kiln, if at all. */
  inDate?: Maybe<Scalars['Date']>;
  /** The `Kiln` that the `KilnCharge` is loaded into. */
  kiln: Kiln;
  /** Has the charge entered the kiln? */
  kilnIn: Scalars['Boolean'];
  /** Has the charge left the kiln? */
  kilnOut: Scalars['Boolean'];
  /** M3 for the items in the contract charge. */
  metersCubed?: Maybe<Scalars['Float']>;
  /** When the charge left the kiln, if it already has. */
  outDate?: Maybe<Scalars['Date']>;
  /** Reference for the contract charge. */
  reference?: Maybe<Scalars['String']>;
  /** Thickness in mm for the items in the charge. */
  thickness?: Maybe<Scalars['Float']>;
  /** Type of the `KilnCharge`, which modifies the behavior of certain actions. */
  type: KilnChargeType;
};

export type KilnChargeAddItemPacks = {
  kilnChargePackId: Scalars['ID'];
  metersCubed?: InputMaybe<Scalars['Float']>;
};

export type KilnChargeAddItemsInput = {
  kilnChargeId: Scalars['ID'];
  packIds?: InputMaybe<Array<Scalars['ID']>>;
  packs?: InputMaybe<Array<KilnChargeAddItemPacks>>;
};

export type KilnChargeCreateInput = {
  alternateChargeNumber?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  geniaFilleted?: InputMaybe<Scalars['Boolean']>;
  kilnId: Scalars['Int'];
  reference?: InputMaybe<Scalars['String']>;
  thickness?: InputMaybe<Scalars['Float']>;
  type: KilnChargeType;
};

/** Describes a pack that is to enter a `Kiln`, and other associated information. */
export type KilnChargeItem = {
  __typename: 'KilnChargeItem';
  /** GCS Code for the pack, if available */
  gcsCode?: Maybe<Scalars['String']>;
  /** GCS id for the pack, if available */
  gcsId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kilnCharge: KilnCharge;
  /** M3 for the item */
  metersCubed?: Maybe<Scalars['Float']>;
  /** The pack that this `KilnChargeItem` is representing */
  packId: Scalars['ID'];
};

export type KilnChargeMoveToKilnInput = {
  alternateChargeNumber?: InputMaybe<Scalars['String']>;
  kilnChargeId: Scalars['ID'];
  metersCubed?: InputMaybe<Scalars['Float']>;
};

export type KilnChargeRemoveChargeInput = {
  kilnChargeId: Scalars['ID'];
};

export type KilnChargeRemoveFromKilnContractInfoInput = {
  /**
   * Freeform customer name. Probably one of the following:
   * - Southwood
   * - Taylors
   * - Halswell
   * - Other
   */
  customer: Scalars['String'];
  /** Total metres cubed dried as part of the charge. */
  metresCubed?: InputMaybe<Scalars['Float']>;
  /** Optional PO number for the customer. */
  purchaseOrderNumber?: InputMaybe<Scalars['String']>;
  /** Thickness of the wood that was dried in the `Kiln`. */
  thicknessMillimeters?: InputMaybe<Scalars['Float']>;
};

export type KilnChargeRemoveFromKilnInput = {
  /** Extra information about the `KilnCharge` if it is a contract charge. */
  contractInfo?: InputMaybe<KilnChargeRemoveFromKilnContractInfoInput>;
  /** The `KilnCharge` to be removed. */
  kilnChargeId: Scalars['ID'];
  /** The total number of seconds the charge has spent in the `Kiln`. */
  secondsInKiln: Scalars['Int'];
  /**
   * Image data URL for the signature of the `Kiln` operator who signs off the
   * charge. If the `KilnCharge` isn't ICPR compliant, then null should be
   * provided. If a signature is given then ICPR compliance is assumed.
   */
  signatureDataUrl?: InputMaybe<Scalars['String']>;
  /** The temperature the `Kiln` was at during the process. */
  temperatureDegrees: Scalars['Float'];
};

export type KilnChargeRemoveItemInput = {
  kilnChargeId: Scalars['ID'];
  packId: Scalars['ID'];
};

/** Type of a `KilnCharge`. */
export enum KilnChargeType {
  /**
   * For a `KilnCharge` that dries client's product under contract. This product
   * never enters our inventory system as it is not ours.
   */
  Contract = 'CONTRACT',
  /** A normal `KilnCharge` handled by our inventory system using product we track. */
  Standard = 'STANDARD'
}

/** A customer who wants to use our kilns for contract `KilnCharge`s. */
export type KilnContractCustomer = {
  __typename: 'KilnContractCustomer';
  id: Scalars['ID'];
  /** Name of the contract customer. */
  name: Scalars['String'];
};

export type LabelPrintCommandInput = {
  /**
   * The data to apply to the `DocumentTemplate` to generate a document.
   * If the `DocumentTemplate` provides default/example data, this is optional.
   */
  data: Array<LabelTemplateFieldData>;
  /** The number of copies to print. Must be nonnegative. */
  printQuantity: Scalars['Int'];
  /** The printer to print to. */
  printerId: Scalars['ID'];
  /** The `LabelTemplate` that should be used. For example 'Porta-FSC'. */
  templateId: Scalars['ID'];
};

export type LabelPrintInput = {
  /** List of commands for printing labels. */
  commands: Array<LabelPrintCommandInput>;
};

export type LabelPrintPayload = {
  __typename: 'LabelPrintPayload';
  message: Scalars['String'];
};

/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplate = {
  __typename: 'LabelTemplate';
  /** Free text, way of loosely categorizing templates (group by category). */
  category?: Maybe<Scalars['String']>;
  /**
   * The printer that should be suggested automatically if printing labels with
   * this template.
   */
  defaultPrinter?: Maybe<Scalars['String']>;
  /** Fields for customizing generated labels. */
  fields: Array<LabelTemplateField>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Name of the template. */
  name: Scalars['String'];
  /**
   * Preset document, if any, in XLSX format, as a base64 string. Can use `age`
   * in the same was as in the `presets` field.
   */
  presetDocument?: Maybe<Scalars['String']>;
  /** How presets should be handled, or whether allowed at all. */
  presetKind: LabelTemplatePresetKind;
  /**
   * Preset data, empty array if none. Can use `age` to get previous versions.
   *   - `age = 0`: current version
   *   - `age = 1`: version before current
   *   - etc.
   */
  presets: Array<Array<Scalars['String']>>;
};


/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplatePresetDocumentArgs = {
  age?: InputMaybe<Scalars['Int']>;
};


/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplatePresetsArgs = {
  age?: InputMaybe<Scalars['Int']>;
};

/** A point of customization for labels generated with a `LabelTemplate`. */
export type LabelTemplateField = {
  __typename: 'LabelTemplateField';
  /** For multiline fields, how many lines may typically be used. */
  defaultLineCount?: Maybe<Scalars['Int']>;
  /** Comment or example input to clarify the purpose of the field. */
  extraInfo: Scalars['String'];
  /** Null if the field is there for clarity in presets, and not for the label. */
  internalName?: Maybe<Scalars['String']>;
  /** Guide as to how the field should be entered. */
  kind: LabelTemplateFieldKind;
  /** User-friendly field name. */
  name: Scalars['String'];
  /** Whether this field should be provided by a preset, or entered manually. */
  preset: Scalars['Boolean'];
};

export type LabelTemplateFieldData = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export enum LabelTemplateFieldKind {
  /** The field accepts multiline text. */
  Multiline = 'MULTILINE',
  /** The field should be given normal text. */
  Text = 'TEXT'
}

export enum LabelTemplatePresetKind {
  /** Can use presets, but with multi-select printing. */
  Multi = 'MULTI',
  /** Cannot use presets for this template */
  None = 'NONE',
  /** Can use presets, but with single-select printing. */
  Single = 'SINGLE'
}

export type LabelTemplateUploadPresetsError = LabelTemplateUploadPresetsInvalidDataError | LabelTemplateUploadPresetsNotFoundError;

export type LabelTemplateUploadPresetsInput = {
  /** The label template to upload presets to. */
  labelTemplateId: Scalars['ID'];
  /** The presets file, in XLSX format. */
  presets: Scalars['Upload'];
};

export type LabelTemplateUploadPresetsInvalidDataError = UserError & {
  __typename: 'LabelTemplateUploadPresetsInvalidDataError';
  message: Scalars['String'];
};

export type LabelTemplateUploadPresetsNotFoundError = UserError & {
  __typename: 'LabelTemplateUploadPresetsNotFoundError';
  message: Scalars['String'];
};

export type LabelTemplateUploadPresetsPayload = {
  __typename: 'LabelTemplateUploadPresetsPayload';
  userErrors: Array<LabelTemplateUploadPresetsError>;
};

/** Physical locations. */
export type Location = {
  __typename: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  /** Create a counter with the given initial data. */
  counterCreate: Scalars['Boolean'];
  /**
   * Fetches the current counter information and returns it, and then updates the
   * counter as appropriate. If the counter does not exist, will error. You
   * can choose to handle this and create the counter on failure if desired.
   *
   * If `multiplier` is specified, will behave as if the counter was used that many
   * times.
   */
  counterUse: Counter;
  /** Generate documents using a `DocumentTemplate` and data. */
  documentGenerate: DocumentGeneratePayload;
  /** Print documents to a printer using a URL. */
  documentPrint: DocumentPrintPayload;
  /** Send email(s) using SendGrid. */
  emailSend: EmailSendPayload;
  /** Adds a pack to an `InventoryTransfer`. */
  inventoryTransferAddPack: InventoryTransferAddPackPayload;
  /** Create a new `InventoryTransfer` of status `CREATING` with no packs. */
  inventoryTransferCreate: InventoryTransferCreatePayload;
  /** Soft deletes an `InventoryTransfer`. Keeping the entity data but marking it as deleted */
  inventoryTransferDelete: InventoryTransferDeletePayload;
  /** Set the `InventoryTransfer`s status to `ERROR` if a transfer is created with stock items that are not available at the origin or destination location */
  inventoryTransferGiveUp: InventoryTransferGiveUpPayload;
  /** The `InventoryTransfer` that has just been received at the destination `StockLocation`. Status will change accordingly to `COMPLETE`. */
  inventoryTransferReceive: InventoryTransferReceivePayload;
  /**
   * Change the origin and destination locations for an `InventoryTransfer`. This
   * can be done for both in transit and completed transfers.
   */
  inventoryTransferRedirect: InventoryTransferRedirectPayload;
  /** Remove a pack from an `InventoryTransfer`. */
  inventoryTransferRemovePack: InventoryTransferRemovePackPayload;
  /** The `InventoryTransfer` that has just left the origin `StockLocation`. Status will change accordingly to `IN_TRANSIT`. */
  inventoryTransferStartTransit: InventoryTransferStartTransitPayload;
  /**
   * Moves a `InventoryTransfer` from `COMPLETE` back to `IN_TRANSIT`, and creates
   * stock movements which reverse those that came from receiving. Would be used if
   * someone messed up.
   */
  inventoryTransferUndo: InventoryTransferUndoPayload;
  /** Add a list of items to a `KilnCharge`, returning the IDs of the added items. */
  kilnChargeAddItems: Array<Scalars['ID']>;
  /** Create a `KilnCharge`. */
  kilnChargeCreate: Scalars['ID'];
  /**
   * Move a `KilnCharge` into a `Kiln`. Cannot move a charge into a `Kiln` more
   * than once.
   */
  kilnChargeMoveToKiln: Scalars['Boolean'];
  /** Removes a Kiln Charge from the list of active (or non-active if you're a psycho) kiln charges. */
  kilnChargeRemoveCharge: Scalars['Boolean'];
  /**
   * Remove a `KilnCharge` from a `Kiln`. Must currently be in a `Kiln`.
   * Also generates and stores a compliance form PDF.
   *
   * Cannot be done anonymously.
   */
  kilnChargeRemoveFromKiln: Scalars['Boolean'];
  /**
   * Removes an item from a `KilnCharge`, when user may have made a mistake
   * scanning.
   */
  kilnChargeRemoveItem: Scalars['Boolean'];
  /** Print a label using a `LabelTemplate` and data. */
  labelPrint: LabelPrintPayload;
  /** Upload a set of presets to a `LabelTemplate`. */
  labelTemplateUploadPresets: LabelTemplateUploadPresetsPayload;
  /**
   * Create a manual upload event so that details can be given for the specified
   * packs.
   */
  receiptCreateManual: ReceiptCreateManualPayload;
  /** Delete packs. The packs must all be `NEW`. */
  receiptDeletePacks: ReceiptDeletePacksPayload;
  /** Modify the tally on a pack. The pack must be `NEW`. */
  receiptPackReplaceTally: ReceiptPackReplaceTallyPayload;
  /**
   * Modify the piece count on a pack. The pack must be `NEW`, and must be of a
   * set-length product.
   */
  receiptPackSetPieceCount: ReceiptPackSetPieceCountPayload;
  /** Replace the kiln dry date on a pack. */
  receiptPackUpdateKilnDryDate: ReceiptPackUpdateKilnDryDatePayload;
  /** Replace the product code on a pack. */
  receiptPackUpdateProductCode: ReceiptPackUpdateProductCodePayload;
  /** Update the purchase order on packs. The packs must all be `NEW`. */
  receiptPacksUpdatePurchaseOrderNumber: ReceiptPacksUpdatePurchaseOrderNumberPayload;
  /** Indicate that the specified packs have arrived on-site. */
  receiptScanPacks: ReceiptScanPacksPayload;
  /**
   * Sends packs to the inventory system. All packs given must be `NEW` and
   * sendable.
   */
  receiptSendPacks: ReceiptSendPacksPayload;
  /** Upload data obtained via supplier delivery dockets. */
  receiptSubmitUpload: ReceiptSubmitUploadPayload;
  /** Replace the date dispatched on an upload event. */
  receiptUploadUpdateDateDispatched: ReceiptUploadUpdateDateDispatchedPayload;
  /** Replace the reference on an upload event. */
  receiptUploadUpdateReference: ReceiptUploadUpdateReferencePayload;
  /** Add packs which are consumed in a `Run`. */
  runAddInfeedPacks: RunAddInfeedPacksPayload;
  runAddOutfeedPackUsingPlan: RunAddOutfeedPackUsingPlanPayload;
  /** Generate a pack representing resultant product from a `Run` */
  runAddOutfeedPacks: Scalars['Boolean'];
  /** Completes a `Run`. */
  runComplete: Scalars['Boolean'];
  /** Create/schedule a `Run`. */
  runCreate: Scalars['ID'];
  /** Reprints the label for a given outfeed pack. */
  runPrintOutfeedPack: Scalars['Boolean'];
  /**
   * Removes an item from a `Run`, when user may have made a mistake
   * scanning.
   */
  runRemovePack: Scalars['Boolean'];
  /** Cancel one or more `Run`s. */
  runsCancel: RunsCancelPayload;
  /** Create/schedule `Run`s. */
  runsCreate: Array<Scalars['ID']>;
  /** Restore one or more `Run`s. */
  runsRestore: Scalars['Boolean'];
  /** Change the planned start date of one or more `Run`s. */
  runsUpdateDate: Scalars['Boolean'];
  /** Change the description/title of one or more `Run`s. */
  runsUpdateDescription: Scalars['Boolean'];
  /** Adjust out packs at a location, 'consuming' the packs. */
  stockConsume: StockConsumePayload;
  /** Create a (Universal Print) pack label and perform related operations. */
  stockCreate: StockCreatePayload;
  /** Create a product pack label for a single product, and adjust the product in. */
  stockCreateSingle: StockCreateSinglePayload;
  /** Attempt to undo the `stockCreateSingle` operation. */
  stockCreateSingleUndo: StockCreateSingleUndoPayload;
  /** Attempt to undo the `stockCreate` operation, or redo after an undo. */
  stockCreateUndo: StockCreateUndoPayload;
  /**
   * Register a physical pack to the `Stocktake`. This may or may not be in the
   * inventory system. Returns the recorded pack data.
   */
  stocktakeAddPack: StocktakePack;
  /** Start a new `Stocktake`. This can be edited by any `User`. */
  stocktakeCreate: Scalars['ID'];
  /**
   * Everyone involved is done with the `Stocktake`. Saves when it was finished
   * and prevents further changes.
   *
   * Produce Excel document like such:
   *
   * | Location: Somewhere
   * |
   * | Started:  14:14:50 15-05-2020
   * | Finished: 16:53:17 15-05-2020
   * |
   * | Pack # | In Inventory? | Added By | Scanned             |
   * | 3140   | Yes           | Thomas   | 14:13:57 15-05-2020 |
   *
   * Saves to our document storage system using upload handles, which can then be
   * dealt with by a Microsoft flow or something else as appropriate.
   */
  stocktakeFinish: Scalars['Boolean'];
  /**
   * Get one or more handles, which can be used to update existing files for approximately
   * 60 minutes. For each element in the input array, one `UploadHandle` is
   * returned. Requires the `Documents.Admin` scope, and the files must be
   * configured with update permissions.
   */
  updateHandlesGet: UpdateHandlesGetPayload;
  /**
   * Get one or more handles, which can be used to upload files for approximately
   * 60 minutes. For each element in the input array, one `UploadHandle` is
   * returned.
   */
  uploadHandlesGet: UploadHandlesGetPayload;
};


export type MutationCounterCreateArgs = {
  input: CounterCreateInput;
};


export type MutationCounterUseArgs = {
  id: Scalars['ID'];
  multiplier?: InputMaybe<Scalars['Int']>;
};


export type MutationDocumentGenerateArgs = {
  input: DocumentGenerateInput;
};


export type MutationDocumentPrintArgs = {
  input: DocumentPrintInput;
};


export type MutationEmailSendArgs = {
  input: EmailSendInput;
};


export type MutationInventoryTransferAddPackArgs = {
  input: InventoryTransferAddPackInput;
};


export type MutationInventoryTransferCreateArgs = {
  input: InventoryTransferCreateInput;
};


export type MutationInventoryTransferDeleteArgs = {
  input: InventoryTransferDeleteInput;
};


export type MutationInventoryTransferGiveUpArgs = {
  input: InventoryTransferGiveUpInput;
};


export type MutationInventoryTransferReceiveArgs = {
  input: InventoryTransferReceiveInput;
};


export type MutationInventoryTransferRedirectArgs = {
  input: InventoryTransferRedirectInput;
};


export type MutationInventoryTransferRemovePackArgs = {
  input: InventoryTransferRemovePackInput;
};


export type MutationInventoryTransferStartTransitArgs = {
  input: InventoryTransferStartTransitInput;
};


export type MutationInventoryTransferUndoArgs = {
  input: InventoryTransferUndoInput;
};


export type MutationKilnChargeAddItemsArgs = {
  input: KilnChargeAddItemsInput;
};


export type MutationKilnChargeCreateArgs = {
  input: KilnChargeCreateInput;
};


export type MutationKilnChargeMoveToKilnArgs = {
  input: KilnChargeMoveToKilnInput;
};


export type MutationKilnChargeRemoveChargeArgs = {
  input: KilnChargeRemoveChargeInput;
};


export type MutationKilnChargeRemoveFromKilnArgs = {
  input: KilnChargeRemoveFromKilnInput;
};


export type MutationKilnChargeRemoveItemArgs = {
  input: KilnChargeRemoveItemInput;
};


export type MutationLabelPrintArgs = {
  input: LabelPrintInput;
};


export type MutationLabelTemplateUploadPresetsArgs = {
  input: LabelTemplateUploadPresetsInput;
};


export type MutationReceiptCreateManualArgs = {
  input: ReceiptCreateManualInput;
};


export type MutationReceiptDeletePacksArgs = {
  input: ReceiptDeletePacksInput;
};


export type MutationReceiptPackReplaceTallyArgs = {
  input: ReceiptPackReplaceTallyInput;
};


export type MutationReceiptPackSetPieceCountArgs = {
  input: ReceiptPackSetPieceCountInput;
};


export type MutationReceiptPackUpdateKilnDryDateArgs = {
  input: ReceiptPackUpdateKilnDryDateInput;
};


export type MutationReceiptPackUpdateProductCodeArgs = {
  input: ReceiptPackUpdateProductCodeInput;
};


export type MutationReceiptPacksUpdatePurchaseOrderNumberArgs = {
  input: ReceiptPacksUpdatePurchaseOrderNumberInput;
};


export type MutationReceiptScanPacksArgs = {
  input: ReceiptScanPacksInput;
};


export type MutationReceiptSendPacksArgs = {
  input: ReceiptSendPacksInput;
};


export type MutationReceiptSubmitUploadArgs = {
  input: ReceiptSubmitUploadInput;
};


export type MutationReceiptUploadUpdateDateDispatchedArgs = {
  input: ReceiptUploadUpdateDateDispatchedInput;
};


export type MutationReceiptUploadUpdateReferenceArgs = {
  input: ReceiptUploadUpdateReferenceInput;
};


export type MutationRunAddInfeedPacksArgs = {
  input: RunAddInfeedPacksInput;
};


export type MutationRunAddOutfeedPackUsingPlanArgs = {
  input: RunAddOutfeedPackUsingPlanInput;
};


export type MutationRunAddOutfeedPacksArgs = {
  input: RunAddOutfeedPacksInput;
};


export type MutationRunCompleteArgs = {
  input: RunCompleteInput;
};


export type MutationRunCreateArgs = {
  input: RunCreateInput;
};


export type MutationRunPrintOutfeedPackArgs = {
  input: RunPrintOutfeedPackInput;
};


export type MutationRunRemovePackArgs = {
  input: RunRemovePackInput;
};


export type MutationRunsCancelArgs = {
  input: RunsCancelInput;
};


export type MutationRunsCreateArgs = {
  input: RunsCreateInput;
};


export type MutationRunsRestoreArgs = {
  input: RunsRestoreInput;
};


export type MutationRunsUpdateDateArgs = {
  input: RunsUpdateDateInput;
};


export type MutationRunsUpdateDescriptionArgs = {
  input: RunsUpdateDescriptionInput;
};


export type MutationStockConsumeArgs = {
  input: StockConsumeInput;
};


export type MutationStockCreateArgs = {
  input: StockCreateInput;
};


export type MutationStockCreateSingleArgs = {
  input: StockCreateSingleInput;
};


export type MutationStockCreateSingleUndoArgs = {
  input: StockCreateSingleUndoInput;
};


export type MutationStockCreateUndoArgs = {
  input: StockCreateUndoInput;
};


export type MutationStocktakeAddPackArgs = {
  input: StocktakeAddPackInput;
};


export type MutationStocktakeCreateArgs = {
  input: StocktakeCreateInput;
};


export type MutationStocktakeFinishArgs = {
  input: StocktakeFinishInput;
};


export type MutationUpdateHandlesGetArgs = {
  input: UpdateHandlesGetInput;
};


export type MutationUploadHandlesGetArgs = {
  input: UploadHandlesGetInput;
};

export enum OutfeedPackUnitOfMeasure {
  Lm = 'LM',
  Pce = 'PCE'
}

export type PackDoesNotExistError = {
  __typename: 'PackDoesNotExistError';
  /** Human-readable error message. */
  message: Scalars['String'];
};

export type PackLog = {
  __typename: 'PackLog';
  consumed: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  lastUpdated: Scalars['DateTime'];
  packNumber: Scalars['String'];
  printer: Scalars['String'];
  /** Quantity of the pack in the stock item's default unit. */
  quantity: Scalars['Float'];
  stockItem: StockItem;
  /**
   * For variable length packs, describes the composition of the pack.
   * If the pack is not of variable length products (or those configured as such),
   * or the pack was created prior to this feature being added, then this will be
   * an empty array.
   */
  tally: Array<Tally>;
  unitNumber?: Maybe<Scalars['String']>;
  unitNumberCategory?: Maybe<Scalars['String']>;
  workstation: PackWorkstation;
};

/** Describes a list of packs purchased/to purchase. */
export type PackPurchaseOrder = {
  __typename: 'PackPurchaseOrder';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** List of items on the purchase order. */
  items: Array<PackPurchaseOrderItem>;
};

/** A pack that is/is to be purchased. */
export type PackPurchaseOrderItem = {
  __typename: 'PackPurchaseOrderItem';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Code for the product required. */
  productCode: Scalars['String'];
};

/** A machine/location at which product can be produced and entered into EXO. */
export type PackWorkstation = {
  __typename: 'PackWorkstation';
  batchPrintingEnabled: Scalars['Boolean'];
  defaultStockLocation: StockLocation;
  id: Scalars['ID'];
  name: Scalars['String'];
  printer: Scalars['String'];
  stickerPrinter: Scalars['String'];
  stockAdjustmentsDisabled: Scalars['Boolean'];
  /** Link to the corresponding RunType in TimberSmart */
  timberSmartRunTypeId?: Maybe<Scalars['String']>;
  /** Categorizes the `PackWorkstation`. */
  type: PackWorkstationType;
};

/** The kind of pack workstation. */
export enum PackWorkstationType {
  /** Added value - i.e. prebuilt/crafted goods. */
  AddedValue = 'ADDED_VALUE',
  /** Optimizer machine. */
  Opti = 'OPTI',
  /** Producing product using a plan. */
  Planned = 'PLANNED',
  /** Other machine. */
  Standard = 'STANDARD'
}

export type PickGroup = {
  __typename: 'PickGroup';
  id: Scalars['ID'];
};

/** Information about a file that exists (though might not yet be uploaded). */
export type PresentFile = {
  __typename: 'PresentFile';
  /**
   * The base URL, but with SAS query parameters providing read access to the
   * file for approximately 60 minutes.
   */
  accessUrl: Scalars['String'];
  /** URI for the location of the blob. */
  baseUrl: Scalars['String'];
  /** Permissions configured for the file. */
  permissions: FilePermissions;
};

/** A device (physical or not) that documents can be printed to. */
export type Printer = {
  __typename: 'Printer';
  /** Unique ID for the printer */
  id: Scalars['ID'];
};

export type Query = {
  __typename: 'Query';
  _null?: Maybe<Scalars['String']>;
  /** Fetch a counter without modifying. */
  counter?: Maybe<Counter>;
  /** Fetch all counters without modifying. */
  counters: Array<Counter>;
  /** List of email templates available for printing. */
  emailTemplates: Array<EmailTemplate>;
  /** Get a `File` by id */
  file?: Maybe<File>;
  /** Get `File`s by id */
  files: Array<File>;
  /** Get the `InventoryTransfer` with the given ID, or null if does not exist. */
  inventoryTransfer?: Maybe<InventoryTransfer>;
  /** List `InventoryTransfer`s with filtering and other options. */
  inventoryTransfers: Array<InventoryTransfer>;
  /** Gets a `KilnCharge`, or null if it doesn't exist. */
  kilnCharge?: Maybe<KilnCharge>;
  /** Gets a `KilnChargeItem`, or null if it doesn't exist. */
  kilnChargeItem?: Maybe<KilnChargeItem>;
  /** Gets a list of `KilnChargeItem`s. */
  kilnChargeItems: Array<KilnChargeItem>;
  /** Gets a list of `KilnCharge`s. */
  kilnCharges: Array<KilnCharge>;
  /** Customers who want to use our kilns for contract `KilnCharge`s. */
  kilnContractCustomers: Array<KilnContractCustomer>;
  /** Gets a list of `Kiln`s. */
  kilns: Array<Kiln>;
  /** Get a `LabelTemplate` by ID if it exists, or null if none found. */
  labelTemplate?: Maybe<LabelTemplate>;
  /** Returns a list of all/many `LabelTemplate`s. */
  labelTemplates: Array<LabelTemplate>;
  /** Gets the current user as recognized by the authentication system. */
  me?: Maybe<User>;
  /** Get a pack log by it's pack number, returning null if not present. */
  packLogByPackNumber?: Maybe<PackLog>;
  /** Fetch pack logs, in order of most recent to least. */
  packLogs: Array<PackLog>;
  /** Fetch packs, in the order of most to least recently created. */
  packPurchaseOrders: Array<PackPurchaseOrder>;
  /**
   * Returns the `PackWorkstation` corresponding to the given ID, or null if not
   * found.
   */
  packWorkstation?: Maybe<PackWorkstation>;
  /**
   * Returns the `PackWorkstation` corresponding to the given ID, or null if not
   * found.
   */
  packWorkstationByName?: Maybe<PackWorkstation>;
  /** Returns a list of all/many `PackWorkstation`s. */
  packWorkstations: Array<PackWorkstation>;
  /** Get a printer by ID if it exists. If a printer is returned, it may be printed to. */
  printer?: Maybe<Printer>;
  /** The list of printers that can be printed to */
  printers: Array<Printer>;
  /** Fetch packs, in the order of most to least recently created. */
  receiptPacks: Array<ReceiptPack>;
  /** Fetch list of suppliers who send us timber to receipt. */
  receiptSuppliers: Array<ReceiptSupplier>;
  /** Fetch upload events, in the order of most recent to least recent. */
  receiptUploadEvents: Array<ReceiptUploadEvent>;
  /**
   * Fetch a particular `Run` by a textual unique ID. Returns null if it doesn't
   * exist.
   */
  run?: Maybe<Run>;
  /** Get a `RunOutfeedPlanLine` by ID, or null if it doesn't exist. */
  runOutfeedPlanLine?: Maybe<RunOutfeedPlanLine>;
  /** Fetch a set of `Run`s */
  runs: Array<Run>;
  /** Get a `Stocktake`, or null if it doesn't exist. */
  stocktake?: Maybe<Stocktake>;
  /** Gets a list of `Stocktake`s. */
  stocktakes: Array<Stocktake>;
  treatmentHistory: TreatmentHistorySearchResult;
  treatmentPlants: Array<TreatmentPlant>;
  /** Get's the `User` with the given ID, or null if not found. */
  user?: Maybe<User>;
  /**
   * Fetches a `User` by their email.
   * Returns null if no such user exists, otherwise the `User`.
   */
  userByEmail?: Maybe<User>;
  /**
   * Attempts to log in using the given PIN.
   * Returns null if no such user exists, otherwise the `User`.
   */
  userByPin?: Maybe<User>;
  /** Get's the `User`s with the given IDs or all `User`s. */
  users: Array<User>;
  /** Returns the `Workstation` corresponding to the given ID, or null if not found. */
  workstation?: Maybe<Workstation>;
  /** Returns a list of all `Workstations`. */
  workstations: Array<Workstation>;
};


export type QueryCounterArgs = {
  id: Scalars['ID'];
};


export type QueryFileArgs = {
  id: Scalars['ID'];
};


export type QueryFilesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryInventoryTransferArgs = {
  id: Scalars['ID'];
};


export type QueryInventoryTransfersArgs = {
  count?: InputMaybe<Scalars['Int']>;
  destinationLocationId?: InputMaybe<Scalars['ID']>;
  fromId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<InventoryTransferStatus>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryKilnChargeArgs = {
  id: Scalars['ID'];
};


export type QueryKilnChargeItemArgs = {
  id: Scalars['ID'];
};


export type QueryKilnChargeItemsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryKilnChargesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  onlyActive?: InputMaybe<Scalars['Boolean']>;
};


export type QueryKilnsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryLabelTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryLabelTemplatesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryPackLogByPackNumberArgs = {
  packNumber: Scalars['String'];
};


export type QueryPackLogsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  packWorkstationId?: InputMaybe<Scalars['ID']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  stockCode?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPackPurchaseOrdersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPackWorkstationArgs = {
  id: Scalars['ID'];
};


export type QueryPackWorkstationByNameArgs = {
  name: Scalars['String'];
};


export type QueryPackWorkstationsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryPrinterArgs = {
  id: Scalars['ID'];
};


export type QueryReceiptPacksArgs = {
  take?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ReceiptPackType>;
};


export type QueryReceiptUploadEventsArgs = {
  status?: InputMaybe<ReceiptUploadEventStatus>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryRunArgs = {
  id: Scalars['ID'];
};


export type QueryRunOutfeedPlanLineArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRunsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  includeCanceled?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  untilDate?: InputMaybe<Scalars['Date']>;
  workstationId?: InputMaybe<Scalars['ID']>;
  workstationType?: InputMaybe<Scalars['String']>;
};


export type QueryStocktakeArgs = {
  id: Scalars['ID'];
};


export type QueryStocktakesArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTreatmentHistoryArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  packNo?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  treatmentPlantId?: InputMaybe<Scalars['ID']>;
  treatmentType?: InputMaybe<Scalars['String']>;
  unitNo?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserByPinArgs = {
  pin: Scalars['String'];
};


export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryWorkstationArgs = {
  id: Scalars['ID'];
};

export type ReceiptCreateManualConflictingPacksError = UserError & {
  __typename: 'ReceiptCreateManualConflictingPacksError';
  message: Scalars['String'];
};

export type ReceiptCreateManualError = ReceiptCreateManualConflictingPacksError | ReceiptCreateManualInvalidInputError | ReceiptCreateManualNotFoundError;

export type ReceiptCreateManualInput = {
  /** List of packs details will be provided for. */
  packIds: Array<Scalars['ID']>;
  /** Supplier who sent the packs. */
  supplierId: Scalars['ID'];
};

export type ReceiptCreateManualInvalidInputError = UserError & {
  __typename: 'ReceiptCreateManualInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptCreateManualNotFoundError = UserError & {
  __typename: 'ReceiptCreateManualNotFoundError';
  message: Scalars['String'];
};

export type ReceiptCreateManualPayload = {
  __typename: 'ReceiptCreateManualPayload';
  /** The created `ReceiptUploadEvent`, null if no packs given or on failure. */
  uploadEvent?: Maybe<ReceiptUploadEvent>;
  userErrors: Array<ReceiptCreateManualError>;
};

export type ReceiptDeletePacksError = ReceiptDeletePacksNotFoundError;

export type ReceiptDeletePacksInput = {
  /** The packs to delete. */
  packIds: Array<Scalars['ID']>;
};

export type ReceiptDeletePacksNotFoundError = UserError & {
  __typename: 'ReceiptDeletePacksNotFoundError';
  message: Scalars['String'];
};

export type ReceiptDeletePacksPayload = {
  __typename: 'ReceiptDeletePacksPayload';
  userErrors: Array<ReceiptDeletePacksError>;
};

export type ReceiptPack = {
  __typename: 'ReceiptPack';
  /**
   * Date the pack was registered. This will be the `dateSubmitted` of the
   * earliest `ReceiptUploadEvent` or `ReceiptScanEvent` mentioning the pack.
   */
  dateCreated: Scalars['DateTime'];
  /** Date the pack most recently had it's `status` field changed */
  dateTransitioned: Scalars['DateTime'];
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Date the wood was kiln-dried. */
  kilnDryDate?: Maybe<Scalars['Date']>;
  /** Unique ID for the pack, typically generated by the supplier */
  packNumber: Scalars['String'];
  /** Number of pieces, non-null only if the product is of a set length */
  pieceCount?: Maybe<Scalars['Float']>;
  /** Our product code for the product in the pack, if it could be determined. */
  productCode?: Maybe<Scalars['String']>;
  /** The purchase order that triggered the purchase of the goods in the pack. */
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  scanEvent?: Maybe<ReceiptScanEvent>;
  /**
   * Whether or not this pack could be sent in its current state into the inventory
   * system.
   */
  sendable: Scalars['Boolean'];
  /** The status of the pack. */
  status: ReceiptPackStatus;
  /**
   * Supplier's product code (the code typically present in the delivery
   * docket) for the product in the pack.
   */
  supplierProductCode?: Maybe<Scalars['String']>;
  tally: Array<ReceiptPackTally>;
  uploadEvent?: Maybe<ReceiptUploadEvent>;
};

export type ReceiptPackReplaceTallyError = ReceiptPackReplaceTallyInvalidInputError | ReceiptPackReplaceTallyNotFoundError | ReceiptPackReplaceTallyProductKindError;

export type ReceiptPackReplaceTallyInput = {
  /** The pack to modify. */
  packId: Scalars['ID'];
  /** The tally to associate with the pack. */
  tally: Array<TallyInput>;
};

export type ReceiptPackReplaceTallyInvalidInputError = UserError & {
  __typename: 'ReceiptPackReplaceTallyInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptPackReplaceTallyNotFoundError = UserError & {
  __typename: 'ReceiptPackReplaceTallyNotFoundError';
  message: Scalars['String'];
};

export type ReceiptPackReplaceTallyPayload = {
  __typename: 'ReceiptPackReplaceTallyPayload';
  /** The modified pack. */
  pack?: Maybe<ReceiptPack>;
  userErrors: Array<ReceiptPackReplaceTallyError>;
};

export type ReceiptPackReplaceTallyProductKindError = UserError & {
  __typename: 'ReceiptPackReplaceTallyProductKindError';
  message: Scalars['String'];
};

export type ReceiptPackSetPieceCountError = ReceiptPackSetPieceCountInvalidInputError | ReceiptPackSetPieceCountNotFoundError | ReceiptPackSetPieceCountProductKindError;

export type ReceiptPackSetPieceCountInput = {
  /** The pack to modify. */
  packId: Scalars['ID'];
  /** The number of pieces to set. */
  pieceCount: Scalars['Float'];
};

export type ReceiptPackSetPieceCountInvalidInputError = UserError & {
  __typename: 'ReceiptPackSetPieceCountInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptPackSetPieceCountNotFoundError = UserError & {
  __typename: 'ReceiptPackSetPieceCountNotFoundError';
  message: Scalars['String'];
};

export type ReceiptPackSetPieceCountPayload = {
  __typename: 'ReceiptPackSetPieceCountPayload';
  /** The modified pack. */
  pack?: Maybe<ReceiptPack>;
  userErrors: Array<ReceiptPackSetPieceCountError>;
};

export type ReceiptPackSetPieceCountProductKindError = UserError & {
  __typename: 'ReceiptPackSetPieceCountProductKindError';
  message: Scalars['String'];
};

export enum ReceiptPackStatus {
  /**
   * The pack is not in the inventory system, and never will be, for whatever
   * reason.
   */
  Deleted = 'DELETED',
  /**
   * The pack is not yet in the inventory system - it is either waiting for manual
   * input, or additional verification (scan event or upload event).
   */
  New = 'NEW',
  /**
   * The pack is currently being registered in the inventory system. If a pack
   * remains stuck in this state, that may indicate that the pack could not
   * successfully be created in the inventory system.
   */
  Sending = 'SENDING',
  /** The pack is in the inventory system, and so can no longer be modified. */
  Sent = 'SENT'
}

export type ReceiptPackTally = {
  __typename: 'ReceiptPackTally';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** A length of a number of goods in a pack. */
  length: Scalars['Float'];
  /** Number of pieces of the given length. */
  pieces: Scalars['Int'];
};

export enum ReceiptPackType {
  /** The pack is scanned but not yet uploaded. */
  Scanned = 'SCANNED',
  /** The pack is uploaded but not yet scanned. */
  Uploaded = 'UPLOADED'
}

export type ReceiptPackUpdateKilnDryDateError = ReceiptPackUpdateKilnDryDateNotFoundError;

export type ReceiptPackUpdateKilnDryDateInput = {
  /** The kiln dry date to set on the pack. */
  kilnDryDate?: InputMaybe<Scalars['Date']>;
  /** The packs to modify. */
  packIds: Array<Scalars['ID']>;
};

export type ReceiptPackUpdateKilnDryDateNotFoundError = UserError & {
  __typename: 'ReceiptPackUpdateKilnDryDateNotFoundError';
  message: Scalars['String'];
};

export type ReceiptPackUpdateKilnDryDatePayload = {
  __typename: 'ReceiptPackUpdateKilnDryDatePayload';
  /** The modified packs. */
  packs?: Maybe<Array<ReceiptPack>>;
  userErrors: Array<ReceiptPackUpdateKilnDryDateError>;
};

export type ReceiptPackUpdateProductCodeError = ReceiptPackUpdateProductCodeInvalidInputError | ReceiptPackUpdateProductCodeNotFoundError;

export type ReceiptPackUpdateProductCodeInput = {
  /** The pack to modify. */
  packId: Scalars['ID'];
  /** The product code to set on the pack. May be `null`, but not an empty string. */
  productCode?: InputMaybe<Scalars['String']>;
};

export type ReceiptPackUpdateProductCodeInvalidInputError = UserError & {
  __typename: 'ReceiptPackUpdateProductCodeInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptPackUpdateProductCodeNotFoundError = UserError & {
  __typename: 'ReceiptPackUpdateProductCodeNotFoundError';
  message: Scalars['String'];
};

export type ReceiptPackUpdateProductCodePayload = {
  __typename: 'ReceiptPackUpdateProductCodePayload';
  /** The modified pack. */
  pack?: Maybe<ReceiptPack>;
  userErrors: Array<ReceiptPackUpdateProductCodeError>;
};

export type ReceiptPacksUpdatePurchaseOrderNumberError = ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError | ReceiptPacksUpdatePurchaseOrderNumberNotFoundError;

export type ReceiptPacksUpdatePurchaseOrderNumberInput = {
  /** The packs to delete. */
  packIds: Array<Scalars['ID']>;
  /** The purchase order number to set on the packs. */
  purchaseOrderNumber: Scalars['String'];
};

export type ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError = UserError & {
  __typename: 'ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptPacksUpdatePurchaseOrderNumberNotFoundError = UserError & {
  __typename: 'ReceiptPacksUpdatePurchaseOrderNumberNotFoundError';
  message: Scalars['String'];
};

export type ReceiptPacksUpdatePurchaseOrderNumberPayload = {
  __typename: 'ReceiptPacksUpdatePurchaseOrderNumberPayload';
  /** The modified packs */
  packs?: Maybe<Array<ReceiptPack>>;
  userErrors: Array<ReceiptPacksUpdatePurchaseOrderNumberError>;
};

export type ReceiptScanEvent = {
  __typename: 'ReceiptScanEvent';
  /** Date of the upload. */
  dateSubmitted: Scalars['DateTime'];
  /** Unique identifier. */
  id: Scalars['ID'];
  /** The list of packs associated with the `ReceiptScanEvent`. */
  packs: Array<ReceiptPack>;
  /** Free text reference. */
  reference: Scalars['String'];
  /** The `User` that scanned the physical goods. */
  scannedBy: User;
  /** Supplier who sent the packs. */
  supplier: ReceiptSupplier;
};

export type ReceiptScanPacksConflictingPacksError = UserError & {
  __typename: 'ReceiptScanPacksConflictingPacksError';
  message: Scalars['String'];
};

export type ReceiptScanPacksError = ReceiptScanPacksConflictingPacksError | ReceiptScanPacksInvalidInputError;

export type ReceiptScanPacksInput = {
  /** List of packs that were scanned. */
  packNumbers: Array<Scalars['String']>;
  /** Free text reference. */
  reference: Scalars['String'];
  /** The supplier who sent the packs. */
  supplierId: Scalars['ID'];
};

export type ReceiptScanPacksInvalidInputError = UserError & {
  __typename: 'ReceiptScanPacksInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptScanPacksPayload = {
  __typename: 'ReceiptScanPacksPayload';
  /** The created `ReceiptScanEvent`, null on failure. */
  scanEvent?: Maybe<ReceiptScanEvent>;
  userErrors: Array<ReceiptScanPacksError>;
};

export type ReceiptSendPacksError = ReceiptSendPacksNotFoundError | ReceiptSendPacksNotSendableError | ReceiptSendPacksSendingFailedError;

export type ReceiptSendPacksInput = {
  /** If set, will not allow sending packs that are in `SENDING` status. */
  allowRetry?: InputMaybe<Scalars['Boolean']>;
  /** The packs to send. */
  packIds: Array<Scalars['ID']>;
};

export type ReceiptSendPacksNotFoundError = UserError & {
  __typename: 'ReceiptSendPacksNotFoundError';
  message: Scalars['String'];
};

export type ReceiptSendPacksNotSendableError = UserError & {
  __typename: 'ReceiptSendPacksNotSendableError';
  message: Scalars['String'];
};

export type ReceiptSendPacksPayload = {
  __typename: 'ReceiptSendPacksPayload';
  /** The packs sent, null on `NotFound` and `NotSendable` errors. */
  packs?: Maybe<Array<ReceiptPack>>;
  userErrors: Array<ReceiptSendPacksError>;
};

export type ReceiptSendPacksSendingFailedError = UserError & {
  __typename: 'ReceiptSendPacksSendingFailedError';
  message: Scalars['String'];
};

export type ReceiptSubmitUploadConflictingPacksError = UserError & {
  __typename: 'ReceiptSubmitUploadConflictingPacksError';
  message: Scalars['String'];
};

export type ReceiptSubmitUploadDocketInput = {
  /**
   * Date upon which the contents originally specified in the delivery docket were
   * dispatched from the supplier.
   */
  dateDispatched?: InputMaybe<Scalars['Date']>;
  /** List of packs that were delivered. */
  packs: Array<ReceiptSubmitUploadPackInput>;
  /** Reference number for the `PurchaseOrder`. */
  reference?: InputMaybe<Scalars['String']>;
  /**
   * The supplier that sent the delivery docket. The available suppliers can be
   * queried with `receiptSuppliers`
   */
  supplierId: Scalars['ID'];
};

export type ReceiptSubmitUploadError = ReceiptSubmitUploadConflictingPacksError | ReceiptSubmitUploadInvalidInputError;

export type ReceiptSubmitUploadInput = {
  /** The data from the dockets to upload. */
  dockets: Array<ReceiptSubmitUploadDocketInput>;
};

export type ReceiptSubmitUploadInvalidInputError = UserError & {
  __typename: 'ReceiptSubmitUploadInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptSubmitUploadPackInput = {
  /** Date that the wood inside the pack was kiln dried. */
  kilnDryDate?: InputMaybe<Scalars['Date']>;
  /** Identifier for the pack that was/is being delivered. */
  packNumber: Scalars['String'];
  /**
   * If provided, and if the product is of a set-length in the inventory system,
   * will use this as the piece count, rather than calculating using the provided
   * tally. A tally must still be provided, to ensure that if for some reason the
   * product is not a set-length product, the application can still proceed.
   */
  pieceCountOverride?: InputMaybe<Scalars['Int']>;
  /** Our product code for the product in the pack, if it could be determined. */
  productCode?: InputMaybe<Scalars['String']>;
  /** The purchase order number the pack corresponds to. */
  purchaseOrderNumber?: InputMaybe<Scalars['String']>;
  /**
   * Supplier's product code (the code typically present in the delivery
   * docket) for the product in the pack.
   */
  supplierProductCode: Scalars['String'];
  /** Tally specification for the pack. */
  tally: Array<TallyInput>;
};

export type ReceiptSubmitUploadPayload = {
  __typename: 'ReceiptSubmitUploadPayload';
  /** The created `ReceiptUploadEvent`s, null on failure. */
  uploadEvents?: Maybe<Array<ReceiptUploadEvent>>;
  userErrors: Array<ReceiptSubmitUploadError>;
};

/** A supplier from which we may choose to purchase packs. */
export type ReceiptSupplier = {
  __typename: 'ReceiptSupplier';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Supplier name. */
  name: Scalars['String'];
};

export type ReceiptUploadEvent = {
  __typename: 'ReceiptUploadEvent';
  /** Date the corresponding packs were dispatched from the supplier. */
  dateDispatched?: Maybe<Scalars['Date']>;
  /** Date of the upload. */
  dateSubmitted: Scalars['DateTime'];
  /** Unique identifier. */
  id: Scalars['ID'];
  /** The list of packs associated with the `ReceiptUploadEvent`. */
  packs: Array<ReceiptPack>;
  /** Free-form reference to aid in searchability. */
  reference: Scalars['String'];
  /** Supplier the upload (delivery docket) came from. */
  supplier: ReceiptSupplier;
  /** Type of the upload event. */
  type: ReceiptUploadEventType;
};

export enum ReceiptUploadEventStatus {
  /** Only fetch upload events that don't have NEW/SENDING packs. */
  Complete = 'COMPLETE',
  /** Only fetch upload events that have NEW/SENDING packs. */
  Incomplete = 'INCOMPLETE'
}

export enum ReceiptUploadEventType {
  /** The `UploadEvent` was created manually to provide details of scanned packs. */
  Manual = 'MANUAL',
  /**
   * The `UploadEvent` was generated automatically from delivery dockets or other
   * data sent by the supplier.
   */
  Upload = 'UPLOAD'
}

export type ReceiptUploadUpdateDateDispatchedError = ReceiptUploadUpdateDateDispatchedNotFoundError;

export type ReceiptUploadUpdateDateDispatchedInput = {
  /** The date dispatched to set on the upload event. */
  dateDispatched?: InputMaybe<Scalars['String']>;
  /** The event to modify. */
  uploadEventId: Scalars['ID'];
};

export type ReceiptUploadUpdateDateDispatchedNotFoundError = UserError & {
  __typename: 'ReceiptUploadUpdateDateDispatchedNotFoundError';
  message: Scalars['String'];
};

export type ReceiptUploadUpdateDateDispatchedPayload = {
  __typename: 'ReceiptUploadUpdateDateDispatchedPayload';
  /** The modified upload event. */
  uploadEvent?: Maybe<ReceiptUploadEvent>;
  userErrors: Array<ReceiptUploadUpdateDateDispatchedError>;
};

export type ReceiptUploadUpdateReferenceError = ReceiptUploadUpdateReferenceInvalidInputError | ReceiptUploadUpdateReferenceNotFoundError;

export type ReceiptUploadUpdateReferenceInput = {
  /** The reference to set on the upload event. */
  reference: Scalars['String'];
  /** The event to modify. */
  uploadEventId: Scalars['ID'];
};

export type ReceiptUploadUpdateReferenceInvalidInputError = UserError & {
  __typename: 'ReceiptUploadUpdateReferenceInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptUploadUpdateReferenceNotFoundError = UserError & {
  __typename: 'ReceiptUploadUpdateReferenceNotFoundError';
  message: Scalars['String'];
};

export type ReceiptUploadUpdateReferencePayload = {
  __typename: 'ReceiptUploadUpdateReferencePayload';
  /** The modified upload event. */
  uploadEvent?: Maybe<ReceiptUploadEvent>;
  userErrors: Array<ReceiptUploadUpdateReferenceError>;
};

/** Describes a use of a `Workstation` to consume `Packs` and produce new `Packs`. */
export type Run = {
  __typename: 'Run';
  /**
   * Whether the run has been canceled. Canceled runs cannot have packs added to
   * them, and tend to be hidden by default.
   */
  canceled: Scalars['Boolean'];
  /** Certification Code the `Run` is set at. */
  certificationCode?: Maybe<Scalars['String']>;
  /** Whether the run already been completed - not scheduled and not in progress. */
  complete: Scalars['Boolean'];
  /** When the run is planned to/did take place. */
  date: Scalars['String'];
  /** Additional information about the `Run`. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Describes the input of the `Run`. */
  infeed: RunInfeed;
  location: Location;
  /** Describes the output of the `Run`. */
  outfeed: RunOutfeed;
  /** The expected output of the `Run`. */
  outfeedPlan: RunOutfeedPlan;
  /** Supplier name the `Run` is set at. */
  supplierName?: Maybe<Scalars['String']>;
  type?: Maybe<RunType>;
  /** Workstation the `Run` operates on. */
  workstation: Workstation;
};

export type RunAddInfeedPacksError = DuplicatePackError | IncompatiblePackError | PackDoesNotExistError | RunDoesNotExistError;

export type RunAddInfeedPacksInput = {
  packs: Array<RunAddInfeedPacksPackInput>;
  runId: Scalars['ID'];
};

export type RunAddInfeedPacksPackInput = {
  packId: Scalars['ID'];
  processDate: Scalars['DateTime'];
};

export type RunAddInfeedPacksPayload = {
  __typename: 'RunAddInfeedPacksPayload';
  error?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  /** Human-readable completion message. */
  success: Scalars['Boolean'];
};

export type RunAddOutfeedPackUsingPlanInput = {
  printer: Scalars['String'];
  quantity: Scalars['Float'];
  runOutfeedPlanLineId: Scalars['ID'];
  unitOfMeasure: OutfeedPackUnitOfMeasure;
};

export type RunAddOutfeedPackUsingPlanPayload = {
  __typename: 'RunAddOutfeedPackUsingPlanPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RunAddOutfeedPacksInput = {
  packs: Array<RunAddOutfeedPacksPackInput>;
  runId: Scalars['ID'];
};

export type RunAddOutfeedPacksPackInput = {
  gcsId: Scalars['ID'];
  processDate: Scalars['DateTime'];
  setLengthPieces?: InputMaybe<Scalars['Int']>;
  tally: Array<TallyInput>;
};

export type RunCompleteInput = {
  /**
   * If set to do, will perform cost-spreading when completing.
   *
   * Cost spreading does the following:
   * -	Auto calculates the run input costs (including machining costs)
   * -	Calculates the difference in volume between outfeed and infeed.
   * -	Spreads the weighted difference across all outfeed packs (i.e. smaller packs
   *   will be changed less than larger ones)
   * -	Spreads the costs across the adjusted packets.
   */
  costSpread: Scalars['Boolean'];
  /** ID of the `Run` that should be completed. */
  runId: Scalars['ID'];
};

export type RunCreateInput = {
  /**
   * When the run is planned to take place.
   * Note: Only accepts the date part, time is truncated..
   */
  date: Scalars['Date'];
  /** Optional additional information about the `Run`. */
  description?: InputMaybe<Scalars['String']>;
  /** ID representing the workstation ID. */
  workstationId: Scalars['ID'];
};

export type RunDoesNotExistError = {
  __typename: 'RunDoesNotExistError';
  /** Human-readable error message. */
  message: Scalars['String'];
};

/** Describes what enters a `Workstation` during a `Run`. */
export type RunInfeed = {
  __typename: 'RunInfeed';
  packs: Array<RunInfeedPack>;
};


/** Describes what enters a `Workstation` during a `Run`. */
export type RunInfeedPacksArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

/**
 * Packs consumed during a `Run`. Can be thought of as product entering a
 * `Workstation`.
 */
export type RunInfeedPack = {
  __typename: 'RunInfeedPack';
  gcsId: Scalars['ID'];
  lastUpdate: Scalars['DateTime'];
  packId: Scalars['ID'];
  processDate: Scalars['Date'];
  processDescription: Scalars['String'];
  processId: Scalars['ID'];
  productCode: Scalars['String'];
  shortCode?: Maybe<Scalars['String']>;
  tally?: Maybe<Array<Maybe<Tally>>>;
  thickness: Scalars['Float'];
  width: Scalars['Float'];
};

/** Describes what leaves a `Workstation` during a `Run`. */
export type RunOutfeed = {
  __typename: 'RunOutfeed';
  packs: Array<RunOutfeedPack>;
};


/** Describes what leaves a `Workstation` during a `Run`. */
export type RunOutfeedPacksArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

/**
 * Packs created due to a `Run`. Can be thought of as product leaving a
 * `Workstation`.
 */
export type RunOutfeedPack = {
  __typename: 'RunOutfeedPack';
  gcsId: Scalars['ID'];
  lastUpdate: Scalars['DateTime'];
  packId: Scalars['ID'];
  processDate: Scalars['Date'];
  processDescription: Scalars['String'];
  processId: Scalars['ID'];
  productCode: Scalars['String'];
  tally?: Maybe<Array<Maybe<Tally>>>;
};

/** Describes the expected output of a run. */
export type RunOutfeedPlan = {
  __typename: 'RunOutfeedPlan';
  /** Each line describes an amount of product expected to be produced by the run. */
  lines: Array<RunOutfeedPlanLine>;
};

/** Describes an amount of product expected to be produced by the run. */
export type RunOutfeedPlanLine = {
  __typename: 'RunOutfeedPlanLine';
  /**
   * If the packs created should be created as finished packs, this will be
   * non-null and describes the finished product that would be produced by the run.
   * If this is null, the packs produced corresponding to this plan line will be
   * considered work-in-progress goods, and will be tracked in the inventory system
   * rather than the sales system.
   */
  finishedProduct?: Maybe<StockItem>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Total LM that has been produced so far for that plan line. */
  linealCompleted?: Maybe<Scalars['Float']>;
  /** Total LM expected to be produced by the run. */
  linealPlanned?: Maybe<Scalars['Float']>;
  /**
   * Describes the next operation expected to be performed on the packs produced
   * according to the plan.
   */
  nextAction?: Maybe<Scalars['String']>;
  /** Describes the composition/type of pack as a raw string. */
  packBuild?: Maybe<Scalars['String']>;
  /** Total piece count expected to be produced by the run. */
  piecesPlanned?: Maybe<Scalars['Float']>;
  /** The run the line belongs to. */
  run: Run;
  /** The unit number the packs are being created to fulfil. */
  unitNumber?: Maybe<Scalars['String']>;
  /** The product to produce. */
  wipProduct: WipProduct;
};

export type RunPrintOutfeedPackInput = {
  packId: Scalars['ID'];
  runId: Scalars['ID'];
};

export type RunRemovePackInput = {
  outfeed?: InputMaybe<Scalars['Boolean']>;
  packId: Scalars['ID'];
  runId: Scalars['ID'];
};

export type RunType = {
  __typename: 'RunType';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type RunUpdateDateInput = {
  /** The new date (UTC). */
  date: Scalars['DateTime'];
  /** The `Run` to update. */
  runId: Scalars['ID'];
};

export type RunsCancelInput = {
  /** The `Run`s to cancel. */
  runIds: Array<Scalars['ID']>;
};

export type RunsCancelPayload = {
  __typename: 'RunsCancelPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RunsCreateInput = {
  runs: Array<RunsCreateRunInput>;
};

export type RunsCreateRunInput = {
  /** When the run is planned to take place. UTC timestamp. */
  date: Scalars['DateTime'];
  /** Optional additional information about the `Run`. */
  description?: InputMaybe<Scalars['String']>;
  /** ID representing the workstation ID. */
  workstationId: Scalars['ID'];
};

export type RunsRestoreInput = {
  /** The `Run`s to restore. */
  runIds: Array<Scalars['ID']>;
};

export type RunsUpdateDateInput = {
  updates: Array<RunUpdateDateInput>;
};

export type RunsUpdateDescriptionInput = {
  updates: Array<RunsUpdateDescriptionUpdateInput>;
};

export type RunsUpdateDescriptionUpdateInput = {
  /** The new description. */
  description: Scalars['String'];
  /** The `Run` to update. */
  runId: Scalars['ID'];
};

export type SalesBranch = {
  __typename: 'SalesBranch';
  id: Scalars['ID'];
};

export type SalesOrder = {
  __typename: 'SalesOrder';
  id: Scalars['ID'];
};

export type SalesOrderLine = {
  __typename: 'SalesOrderLine';
  id: Scalars['ID'];
};

export type ShippingProvider = {
  __typename: 'ShippingProvider';
  id: Scalars['ID'];
};

export type StockConsumeError = StockConsumeNotFoundError;

export type StockConsumeInput = {
  /** The pack numbers of the packs to consume. */
  packNumbers: Array<Scalars['String']>;
  /** Where the packs are located. */
  stockLocationId: Scalars['ID'];
};

export type StockConsumeNotFoundError = UserError & {
  __typename: 'StockConsumeNotFoundError';
  message: Scalars['String'];
};

export type StockConsumePayload = {
  __typename: 'StockConsumePayload';
  userErrors: Array<StockConsumeError>;
};

export type StockCreateError = StockCreateInvalidInputError | StockCreateNotFoundError;

export type StockCreateInput = {
  displayDimensions?: InputMaybe<Scalars['String']>;
  lengthSummary?: InputMaybe<Scalars['String']>;
  packWorkstationId: Scalars['ID'];
  printQuantity: Scalars['Int'];
  printer: Scalars['String'];
  quantity: Scalars['Float'];
  selectedUnitOfMeasure: StockCreateUnitOfMeasure;
  stockCode: Scalars['String'];
  tally?: InputMaybe<Array<TallyInput>>;
  unitNumber?: InputMaybe<Scalars['String']>;
  variableLengthPieces?: InputMaybe<Scalars['Float']>;
};

export type StockCreateInvalidInputError = UserError & {
  __typename: 'StockCreateInvalidInputError';
  message: Scalars['String'];
};

export type StockCreateNotFoundError = UserError & {
  __typename: 'StockCreateNotFoundError';
  message: Scalars['String'];
};

export type StockCreatePayload = {
  __typename: 'StockCreatePayload';
  /** The resulting `PackLog`s, null on failure. */
  packLogs?: Maybe<Array<PackLog>>;
  userErrors: Array<StockCreateError>;
};

export type StockCreateSingleError = StockCreateSingleNoBarcodeError | StockCreateSingleNotFoundError;

export type StockCreateSingleInput = {
  packWorkstationId: Scalars['ID'];
  printer: Scalars['String'];
  stockCode: Scalars['String'];
};

export type StockCreateSingleNoBarcodeError = UserError & {
  __typename: 'StockCreateSingleNoBarcodeError';
  message: Scalars['String'];
};

export type StockCreateSingleNotFoundError = UserError & {
  __typename: 'StockCreateSingleNotFoundError';
  message: Scalars['String'];
};

export type StockCreateSinglePayload = {
  __typename: 'StockCreateSinglePayload';
  userErrors: Array<StockCreateSingleError>;
};

export type StockCreateSingleUndoError = StockCreateSingleUndoNotFoundError;

export type StockCreateSingleUndoInput = {
  packWorkstationId: Scalars['ID'];
  stockCode: Scalars['String'];
};

export type StockCreateSingleUndoNotFoundError = UserError & {
  __typename: 'StockCreateSingleUndoNotFoundError';
  message: Scalars['String'];
};

export type StockCreateSingleUndoPayload = {
  __typename: 'StockCreateSingleUndoPayload';
  userErrors: Array<StockCreateSingleUndoError>;
};

export type StockCreateUndoError = StockCreateUndoNotFoundError;

export type StockCreateUndoInput = {
  /** The pack log to delete or restore. */
  packLogId: Scalars['ID'];
  /** Whether to soft-delete the pack log or restore it. */
  restore?: InputMaybe<Scalars['Boolean']>;
};

export type StockCreateUndoNotFoundError = UserError & {
  __typename: 'StockCreateUndoNotFoundError';
  message: Scalars['String'];
};

export type StockCreateUndoPayload = {
  __typename: 'StockCreateUndoPayload';
  /** The resulting `PackLog`, null on failure. */
  packLog?: Maybe<PackLog>;
  userErrors: Array<StockCreateUndoError>;
};

export enum StockCreateUnitOfMeasure {
  Lm = 'LM',
  Pce = 'PCE'
}

export type StockGroup = {
  __typename: 'StockGroup';
  id: Scalars['ID'];
};

export type StockItem = {
  __typename: 'StockItem';
  stockCode: Scalars['ID'];
};

export type StockLocation = {
  __typename: 'StockLocation';
  id: Scalars['ID'];
};

/**
 * A snapshot of the amount of stock held at the specified location, obtained by
 * recording the packs found on-site.
 */
export type Stocktake = {
  __typename: 'Stocktake';
  /** Packs that have been added to the `Stocktake`, i.e. packs that have been found. */
  addedPacks: Array<StocktakePack>;
  /**
   * If null, is not finished. Otherwise, when the `Stocktake` was completed and
   * made read-only.
   */
  dateFinished?: Maybe<Scalars['Date']>;
  /** Date the `Stocktake` was created. */
  dateStarted: Scalars['Date'];
  /** Autogenerated ID. */
  id: Scalars['ID'];
  /** Freeform location input. E.g. 'Moutere'. */
  location: Scalars['String'];
};


/**
 * A snapshot of the amount of stock held at the specified location, obtained by
 * recording the packs found on-site.
 */
export type StocktakeAddedPacksArgs = {
  mine?: InputMaybe<Scalars['Boolean']>;
};

export type StocktakeAddPackInput = {
  /** ID of the pack, typically scanned from a barcode. */
  packId: Scalars['ID'];
  /** ID of the `Stocktake` to add the pack to. */
  stocktakeId: Scalars['ID'];
};

export type StocktakeCreateInput = {
  /** Freeform location - see `Stocktake.location`. */
  location: Scalars['String'];
};

export type StocktakeFinishInput = {
  /** ID of the `Stocktake` that is now finished. */
  stocktakeId: Scalars['ID'];
};

/** A pack found on-site, that is recorded as part of a `Stocktake`. */
export type StocktakePack = {
  __typename: 'StocktakePack';
  /** The `User` that the pack was scanned by. */
  addedBy: User;
  /** Date the pack was scanned. */
  dateScanned: Scalars['Date'];
  /** Autogenerated ID. */
  id: Scalars['ID'];
  /**
   * Whether the pack was found in the inventory system at the time of scanning.
   * @deprecated Use `inventoryStatus` instead.
   */
  inInventory: Scalars['Boolean'];
  /** Describes where the pack is (or is not) inventory. */
  inventoryStatus: StocktakePackInventoryStatus;
  /** The unique ID of the pack expected to be in the inventory system. */
  packId: Scalars['ID'];
};

/** Describes where a pack was found. */
export enum StocktakePackInventoryStatus {
  /** Found in EXO/pack history. */
  Finished = 'FINISHED',
  /** Not found in either location. */
  NotFound = 'NOT_FOUND',
  /** Found in TimberSmart. */
  Wip = 'WIP'
}

/**
 * A measurement of the amount of product.
 * It includes the number of pieces, and the length of these pieces.
 */
export type Tally = {
  __typename: 'Tally';
  length: Scalars['Float'];
  pieces: Scalars['Int'];
};

/** Corresponding input for `Tally` objects. */
export type TallyInput = {
  length: Scalars['Float'];
  pieces: Scalars['Int'];
};

export type TreatmentHistoryRecord = {
  __typename: 'TreatmentHistoryRecord';
  dateReceived?: Maybe<Scalars['DateTime']>;
  dateSent: Scalars['DateTime'];
  documentId?: Maybe<Scalars['String']>;
  fromTreatmentInventoryTransferId?: Maybe<Scalars['ID']>;
  fromTreatmentPackId?: Maybe<Scalars['ID']>;
  manualDescription?: Maybe<Scalars['String']>;
  metresCubed: Scalars['Float'];
  packNumber: Scalars['String'];
  quantity: Scalars['Float'];
  returnedToLocationId?: Maybe<Scalars['ID']>;
  sentFromLocationId: Scalars['ID'];
  shippingDestination?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  stockItem?: Maybe<StockItem>;
  toTreatmentInventoryTransferId: Scalars['ID'];
  toTreatmentPackId: Scalars['ID'];
  treatmentPlant: TreatmentPlant;
  treatmentPlantId: Scalars['ID'];
  treatmentType?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  unitText?: Maybe<Scalars['String']>;
  weightKilograms: Scalars['Float'];
};

export type TreatmentHistorySearchResult = {
  __typename: 'TreatmentHistorySearchResult';
  records: Array<TreatmentHistoryRecord>;
  totalCount: Scalars['Int'];
};

/** Plants that packs can be sent to for treatment along with the treatment types available at that plant. */
export type TreatmentPlant = {
  __typename: 'TreatmentPlant';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  treatments: Array<Scalars['String']>;
};

export type UpdateHandlesGetError = UpdateHandlesGetNotFoundError | UpdateHandlesGetNotModifiableError;

export type UpdateHandlesGetHandleInput = {
  /** Content type for the blob, e.g. "application/pdf" */
  contentType: Scalars['String'];
  /** ID of the file to update */
  fileId: Scalars['ID'];
  /**
   * The new name for the file. Even when null, this will rename the old file. This
   * does not affect the name of the blob, just the name of the file according to
   * the API.
   */
  filename?: InputMaybe<Scalars['String']>;
};

export type UpdateHandlesGetInput = {
  handles: Array<UpdateHandlesGetHandleInput>;
};

export type UpdateHandlesGetNotFoundError = UserError & {
  __typename: 'UpdateHandlesGetNotFoundError';
  message: Scalars['String'];
};

export type UpdateHandlesGetNotModifiableError = UserError & {
  __typename: 'UpdateHandlesGetNotModifiableError';
  message: Scalars['String'];
};

export type UpdateHandlesGetPayload = {
  __typename: 'UpdateHandlesGetPayload';
  /** The handles to use for uploading files. */
  handles?: Maybe<Array<UploadHandle>>;
  userErrors: Array<UpdateHandlesGetError>;
};

/** A handle to a file in Azure Blob Storage. */
export type UploadHandle = {
  __typename: 'UploadHandle';
  /** The file record created. */
  file: File;
  /** URL which can be used to upload a file */
  uploadUrl: Scalars['String'];
};

export type UploadHandlesGetError = UploadHandlesGetInvalidInputError;

export type UploadHandlesGetHandleInput = {
  /** The file extension for the blob name, if any. */
  blobExtension?: InputMaybe<Scalars['String']>;
  /**
   * If not set, the blob will be stored internally as
   * "<some-uuid>.<blobExtension>", or if no blob file extension is present, just
   * "<some-uuid>".
   *
   * If set, the blob will be stored as "<prefix> <some-uuid>.<blobExtension>",
   * or if no blob file extension is present, just "<prefix> <some-uuid>"".
   */
  blobNamePrefix?: InputMaybe<Scalars['String']>;
  /**
   * The name of the container to place the blob in. This container does not
   * need to exist to be used - it will be created as required.
   */
  containerName: Scalars['String'];
  /** Content type for the blob, e.g. "application/pdf" */
  contentType: Scalars['String'];
  /**
   * If null, won't ever expire. If set, must be positive. Files will not be
   * deleted until after `expireDays` is met, although the deletion is not
   * immediate.
   */
  expireDays?: InputMaybe<Scalars['Int']>;
  /** Filename for the file according to the API, if any. */
  filename?: InputMaybe<Scalars['String']>;
  /**
   * Permissions that apply to the file. Only enforceable if file operations
   * happen through this api
   */
  permissions?: InputMaybe<FilePermissions>;
};

export type UploadHandlesGetInput = {
  handles: Array<UploadHandlesGetHandleInput>;
};

export type UploadHandlesGetInvalidInputError = UserError & {
  __typename: 'UploadHandlesGetInvalidInputError';
  message: Scalars['String'];
};

export type UploadHandlesGetPayload = {
  __typename: 'UploadHandlesGetPayload';
  /** The handles to use for uploading files. */
  handles?: Maybe<Array<UploadHandle>>;
  userErrors: Array<UploadHandlesGetError>;
};

/** A user of MLC applications. */
export type User = {
  __typename: 'User';
  /** The list of apps the user has access to. */
  apps: Array<AppAccess>;
  /**
   * Default `StockLocation` for the `User` when fulfilling `SalesOrder`s.
   * Some `User`s may not have a default - e.g. they don't work in dispatch.
   */
  defaultFulfillingStockLocation?: Maybe<StockLocation>;
  /**
   * Default `PickGroup` for the `User` when fulfilling `SalesOrder`s.
   * Some `User`s may not have a default - e.g. they don't work in dispatch.
   */
  defaultPickGroup?: Maybe<PickGroup>;
  /**
   * The user's email address, if any are on record. This allows the gateway and
   * services under it to identify a user logged in directly via the gateway, in a
   * way that from a service's perspective behaves identically to being logged in
   * via middleware-clients.
   */
  email?: Maybe<Scalars['String']>;
  /** The user's family (last) name. */
  familyName: Scalars['String'];
  /** The ID of the warehouse staff member in the the financial system. */
  financialWarehouseStaffMemberId?: Maybe<Scalars['ID']>;
  /** The set of branches the user may fulfil from. */
  fulfillingStockLocations: Array<StockLocation>;
  /** The user's given (first) name. */
  givenName: Scalars['String'];
  id: Scalars['ID'];
  /** The set of picking groups the user may fulfil from. */
  pickGroups: Array<PickGroup>;
};

export type UserError = {
  message: Scalars['String'];
};

export type WipProduct = {
  __typename: 'WipProduct';
  /** Description of the product. */
  description: Scalars['String'];
  /** Grade of the wood the product is composed of. */
  grade: Scalars['String'];
  /** Unique identifier. Corresponds to the product's GCS ID in TimberSmart. */
  id: Scalars['ID'];
  /** Unique and meaningful identifier for the product. */
  productCode: Scalars['String'];
  /** The fixed/set length of the product configured in the inventory system. */
  setLength?: Maybe<Scalars['Float']>;
};

export type WipSalesOrder = {
  __typename: 'WipSalesOrder';
  id: Scalars['ID'];
};

/** A machine/location at which product can be produced or manipulated. */
export type Workstation = {
  __typename: 'Workstation';
  id: Scalars['ID'];
  /** The label printer to use when a `User` at the machine wants to print a label. */
  labelPrinterId?: Maybe<Scalars['ID']>;
  /** Friendly name. */
  name: Scalars['String'];
  /** The corresponding pack workstation, if any. */
  packWorkstation?: Maybe<PackWorkstation>;
  /** Categorizes the `Workstation`. */
  type: WorkstationType;
};

/** Different types of `Workstation`. */
export enum WorkstationType {
  /** Makes packs disappear. */
  FactoryIn = 'FACTORY_IN',
  /** Preparing timber for drying. */
  Filleting = 'FILLETING',
  /** Any other type of `Workstation` that doesn't suit the other types. */
  Other = 'OTHER'
}

export type AddOutfeedPackItemsMutationVariables = Exact<{
  input: RunAddOutfeedPacksInput;
}>;


export type AddOutfeedPackItemsMutation = { __typename: 'Mutation', runAddOutfeedPacks: boolean };

export type PrintOutfeedPackLabelMutationVariables = Exact<{
  input: RunPrintOutfeedPackInput;
}>;


export type PrintOutfeedPackLabelMutation = { __typename: 'Mutation', runPrintOutfeedPack: boolean };

export type RunQueryVariables = Exact<{
  runId: Scalars['ID'];
}>;


export type RunQuery = { __typename: 'Query', run?: { __typename: 'Run', id: ID, date: string, supplierName?: string | null, certificationCode?: string | null, description?: string | null, complete: boolean, location: { __typename: 'Location', id: ID, name: string }, infeed: { __typename: 'RunInfeed', packs: Array<{ __typename: 'RunInfeedPack', packId: ID, processDate: string, lastUpdate: string, gcsId: ID, width: number, thickness: number, productCode: string, processId: ID, processDescription: string, tally?: Array<{ __typename: 'Tally', pieces: number, length: number } | null> | null }> }, outfeed: { __typename: 'RunOutfeed', packs: Array<{ __typename: 'RunOutfeedPack', packId: ID, processDate: string, lastUpdate: string, gcsId: ID, productCode: string, processId: ID, processDescription: string, tally?: Array<{ __typename: 'Tally', pieces: number, length: number } | null> | null }> }, workstation: { __typename: 'Workstation', id: ID, name: string, type: WorkstationType } } | null };

export type RunRemovePackMutationVariables = Exact<{
  input: RunRemovePackInput;
}>;


export type RunRemovePackMutation = { __typename: 'Mutation', runRemovePack: boolean };

export type KilnContractCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type KilnContractCustomersQuery = { __typename: 'Query', result: Array<{ __typename: 'KilnContractCustomer', id: ID, name: string }> };

export type KilnChargeCreateMutationVariables = Exact<{
  input: KilnChargeCreateInput;
}>;


export type KilnChargeCreateMutation = { __typename: 'Mutation', kilnChargeCreate: ID };

export type KilnChargeRemoveFromKilnMutationVariables = Exact<{
  input: KilnChargeRemoveFromKilnInput;
}>;


export type KilnChargeRemoveFromKilnMutation = { __typename: 'Mutation', kilnChargeRemoveFromKiln: boolean };

export type GetKilnChargesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  onlyActive?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetKilnChargesQuery = { __typename: 'Query', kilnCharges: Array<{ __typename: 'KilnCharge', chargeNumber: string, alternateChargeNumber: string, id: ID, type: KilnChargeType, inDate?: string | null, kilnIn: boolean, kilnOut: boolean, outDate?: string | null, thickness?: number | null, reference?: string | null, customer?: string | null, kiln: { __typename: 'Kiln', name: string }, chargeItems: Array<{ __typename: 'KilnChargeItem', id: ID, packId: ID, gcsCode?: string | null, gcsId?: string | null, metersCubed?: number | null }> }> };

export type KilnsQueryVariables = Exact<{ [key: string]: never; }>;


export type KilnsQuery = { __typename: 'Query', kilns: Array<{ __typename: 'Kiln', id: ID, name: string, locationName: string }> };

export type GetCounterQueryVariables = Exact<{
  input: Scalars['ID'];
}>;


export type GetCounterQuery = { __typename: 'Query', counter?: { __typename: 'Counter', id: ID } | null };

export type KilnChargeRemoveChargeMutationVariables = Exact<{
  input: KilnChargeRemoveChargeInput;
}>;


export type KilnChargeRemoveChargeMutation = { __typename: 'Mutation', kilnChargeRemoveCharge: boolean };

export type KilnChargeAddItemsMutationVariables = Exact<{
  input: KilnChargeAddItemsInput;
}>;


export type KilnChargeAddItemsMutation = { __typename: 'Mutation', kilnChargeAddItems: Array<ID> };

export type KilnChargeMoveToKilnMutationVariables = Exact<{
  input: KilnChargeMoveToKilnInput;
}>;


export type KilnChargeMoveToKilnMutation = { __typename: 'Mutation', kilnChargeMoveToKiln: boolean };

export type KilnChargeRemoveItemMutationVariables = Exact<{
  input: KilnChargeRemoveItemInput;
}>;


export type KilnChargeRemoveItemMutation = { __typename: 'Mutation', kilnChargeRemoveItem: boolean };

export type GetChargeItemsQueryVariables = Exact<{
  kilnChargeId: Scalars['ID'];
}>;


export type GetChargeItemsQuery = { __typename: 'Query', kilnCharge?: { __typename: 'KilnCharge', id: ID, type: KilnChargeType, chargeItems: Array<{ __typename: 'KilnChargeItem', id: ID, metersCubed?: number | null, packId: ID, kilnCharge: { __typename: 'KilnCharge', id: ID } }> } | null };

export type RunsQueryVariables = Exact<{
  workstationId?: InputMaybe<Scalars['ID']>;
  workstationType?: InputMaybe<Scalars['String']>;
  untilDate?: InputMaybe<Scalars['Date']>;
}>;


export type RunsQuery = { __typename: 'Query', runs: Array<{ __typename: 'Run', id: ID, date: string, description?: string | null, location: { __typename: 'Location', id: ID, name: string }, infeed: { __typename: 'RunInfeed', packs: Array<{ __typename: 'RunInfeedPack', packId: ID }> }, outfeed: { __typename: 'RunOutfeed', packs: Array<{ __typename: 'RunOutfeedPack', packId: ID }> }, workstation: { __typename: 'Workstation', id: ID, name: string, type: WorkstationType } }> };

export type GetInfeedPacksQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInfeedPacksQuery = { __typename: 'Query', run?: { __typename: 'Run', id: ID, description?: string | null, location: { __typename: 'Location', name: string }, infeed: { __typename: 'RunInfeed', packs: Array<{ __typename: 'RunInfeedPack', packId: ID, productCode: string }> }, type?: { __typename: 'RunType', id: ID, type: string } | null } | null };

export type RunAddInfeedPacksMutationVariables = Exact<{
  input: RunAddInfeedPacksInput;
}>;


export type RunAddInfeedPacksMutation = { __typename: 'Mutation', runAddInfeedPacks: { __typename: 'RunAddInfeedPacksPayload', success: boolean, error?: string | null, errorCode?: string | null } };

export type RunCompleteMutationVariables = Exact<{
  input: RunCompleteInput;
}>;


export type RunCompleteMutation = { __typename: 'Mutation', runComplete: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename: 'Query', me?: { __typename: 'User', id: ID, givenName: string, familyName: string } | null };

export type GetWorkstationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkstationsQuery = { __typename: 'Query', workstations: Array<{ __typename: 'Workstation', id: ID, name: string, type: WorkstationType, labelPrinterId?: ID | null }> };

export type RunCreateMutationVariables = Exact<{
  input: RunCreateInput;
}>;


export type RunCreateMutation = { __typename: 'Mutation', runCreate: ID };

export type SuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type SuppliersQuery = { __typename: 'Query', suppliers: Array<{ __typename: 'ReceiptSupplier', id: ID, name: string }> };

export type ScanPacksMutationVariables = Exact<{
  input: ReceiptScanPacksInput;
}>;


export type ScanPacksMutation = { __typename: 'Mutation', result: { __typename: 'ReceiptScanPacksPayload', scanEvent?: { __typename: 'ReceiptScanEvent', id: ID } | null, userErrors: Array<{ __typename: 'ReceiptScanPacksConflictingPacksError', message: string } | { __typename: 'ReceiptScanPacksInvalidInputError', message: string }> } };

export type GetStocktakesQueryVariables = Exact<{
  onlyActive?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetStocktakesQuery = { __typename: 'Query', stocktakes: Array<{ __typename: 'Stocktake', id: ID, dateStarted: string, dateFinished?: string | null, location: string }> };

export type StocktakeCreateMutationVariables = Exact<{
  input: StocktakeCreateInput;
}>;


export type StocktakeCreateMutation = { __typename: 'Mutation', stocktakeCreate: ID };

export type GetStocktakeQueryVariables = Exact<{
  input: Scalars['ID'];
}>;


export type GetStocktakeQuery = { __typename: 'Query', stocktake?: { __typename: 'Stocktake', id: ID, dateStarted: string, dateFinished?: string | null, location: string, addedPacks: Array<{ __typename: 'StocktakePack', id: ID, packId: ID, inventoryStatus: StocktakePackInventoryStatus }> } | null };

export type AddStocktakePackMutationVariables = Exact<{
  input: StocktakeAddPackInput;
}>;


export type AddStocktakePackMutation = { __typename: 'Mutation', stocktakeAddPack: { __typename: 'StocktakePack', id: ID, packId: ID, inventoryStatus: StocktakePackInventoryStatus } };

export type StocktakeFinishMutationVariables = Exact<{
  input: StocktakeFinishInput;
}>;


export type StocktakeFinishMutation = { __typename: 'Mutation', stocktakeFinish: boolean };


export const AddOutfeedPackItemsDocument = gql`
    mutation AddOutfeedPackItems($input: RunAddOutfeedPacksInput!) {
  runAddOutfeedPacks(input: $input)
}
    `;
export type AddOutfeedPackItemsMutationFn = Apollo.MutationFunction<AddOutfeedPackItemsMutation, AddOutfeedPackItemsMutationVariables>;

/**
 * __useAddOutfeedPackItemsMutation__
 *
 * To run a mutation, you first call `useAddOutfeedPackItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOutfeedPackItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOutfeedPackItemsMutation, { data, loading, error }] = useAddOutfeedPackItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOutfeedPackItemsMutation(baseOptions?: Apollo.MutationHookOptions<AddOutfeedPackItemsMutation, AddOutfeedPackItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOutfeedPackItemsMutation, AddOutfeedPackItemsMutationVariables>(AddOutfeedPackItemsDocument, options);
      }
export type AddOutfeedPackItemsMutationHookResult = ReturnType<typeof useAddOutfeedPackItemsMutation>;
export type AddOutfeedPackItemsMutationResult = Apollo.MutationResult<AddOutfeedPackItemsMutation>;
export type AddOutfeedPackItemsMutationOptions = Apollo.BaseMutationOptions<AddOutfeedPackItemsMutation, AddOutfeedPackItemsMutationVariables>;
export const PrintOutfeedPackLabelDocument = gql`
    mutation PrintOutfeedPackLabel($input: RunPrintOutfeedPackInput!) {
  runPrintOutfeedPack(input: $input)
}
    `;
export type PrintOutfeedPackLabelMutationFn = Apollo.MutationFunction<PrintOutfeedPackLabelMutation, PrintOutfeedPackLabelMutationVariables>;

/**
 * __usePrintOutfeedPackLabelMutation__
 *
 * To run a mutation, you first call `usePrintOutfeedPackLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintOutfeedPackLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printOutfeedPackLabelMutation, { data, loading, error }] = usePrintOutfeedPackLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintOutfeedPackLabelMutation(baseOptions?: Apollo.MutationHookOptions<PrintOutfeedPackLabelMutation, PrintOutfeedPackLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintOutfeedPackLabelMutation, PrintOutfeedPackLabelMutationVariables>(PrintOutfeedPackLabelDocument, options);
      }
export type PrintOutfeedPackLabelMutationHookResult = ReturnType<typeof usePrintOutfeedPackLabelMutation>;
export type PrintOutfeedPackLabelMutationResult = Apollo.MutationResult<PrintOutfeedPackLabelMutation>;
export type PrintOutfeedPackLabelMutationOptions = Apollo.BaseMutationOptions<PrintOutfeedPackLabelMutation, PrintOutfeedPackLabelMutationVariables>;
export const RunDocument = gql`
    query Run($runId: ID!) {
  run(id: $runId) {
    id
    date
    supplierName
    certificationCode
    location {
      id
      name
    }
    infeed {
      packs {
        packId
        processDate
        lastUpdate
        gcsId
        width
        thickness
        productCode
        processId
        processDescription
        tally {
          pieces
          length
        }
      }
    }
    outfeed {
      packs {
        packId
        processDate
        lastUpdate
        gcsId
        productCode
        processId
        processDescription
        tally {
          pieces
          length
        }
      }
    }
    description
    complete
    workstation {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useRunQuery__
 *
 * To run a query within a React component, call `useRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunQuery({
 *   variables: {
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useRunQuery(baseOptions: Apollo.QueryHookOptions<RunQuery, RunQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunQuery, RunQueryVariables>(RunDocument, options);
      }
export function useRunLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunQuery, RunQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunQuery, RunQueryVariables>(RunDocument, options);
        }
export type RunQueryHookResult = ReturnType<typeof useRunQuery>;
export type RunLazyQueryHookResult = ReturnType<typeof useRunLazyQuery>;
export type RunQueryResult = Apollo.QueryResult<RunQuery, RunQueryVariables>;
export const RunRemovePackDocument = gql`
    mutation RunRemovePack($input: RunRemovePackInput!) {
  runRemovePack(input: $input)
}
    `;
export type RunRemovePackMutationFn = Apollo.MutationFunction<RunRemovePackMutation, RunRemovePackMutationVariables>;

/**
 * __useRunRemovePackMutation__
 *
 * To run a mutation, you first call `useRunRemovePackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunRemovePackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runRemovePackMutation, { data, loading, error }] = useRunRemovePackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunRemovePackMutation(baseOptions?: Apollo.MutationHookOptions<RunRemovePackMutation, RunRemovePackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunRemovePackMutation, RunRemovePackMutationVariables>(RunRemovePackDocument, options);
      }
export type RunRemovePackMutationHookResult = ReturnType<typeof useRunRemovePackMutation>;
export type RunRemovePackMutationResult = Apollo.MutationResult<RunRemovePackMutation>;
export type RunRemovePackMutationOptions = Apollo.BaseMutationOptions<RunRemovePackMutation, RunRemovePackMutationVariables>;
export const KilnContractCustomersDocument = gql`
    query KilnContractCustomers {
  result: kilnContractCustomers {
    id
    name
  }
}
    `;

/**
 * __useKilnContractCustomersQuery__
 *
 * To run a query within a React component, call `useKilnContractCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKilnContractCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKilnContractCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useKilnContractCustomersQuery(baseOptions?: Apollo.QueryHookOptions<KilnContractCustomersQuery, KilnContractCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KilnContractCustomersQuery, KilnContractCustomersQueryVariables>(KilnContractCustomersDocument, options);
      }
export function useKilnContractCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KilnContractCustomersQuery, KilnContractCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KilnContractCustomersQuery, KilnContractCustomersQueryVariables>(KilnContractCustomersDocument, options);
        }
export type KilnContractCustomersQueryHookResult = ReturnType<typeof useKilnContractCustomersQuery>;
export type KilnContractCustomersLazyQueryHookResult = ReturnType<typeof useKilnContractCustomersLazyQuery>;
export type KilnContractCustomersQueryResult = Apollo.QueryResult<KilnContractCustomersQuery, KilnContractCustomersQueryVariables>;
export const KilnChargeCreateDocument = gql`
    mutation KilnChargeCreate($input: KilnChargeCreateInput!) {
  kilnChargeCreate(input: $input)
}
    `;
export type KilnChargeCreateMutationFn = Apollo.MutationFunction<KilnChargeCreateMutation, KilnChargeCreateMutationVariables>;

/**
 * __useKilnChargeCreateMutation__
 *
 * To run a mutation, you first call `useKilnChargeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKilnChargeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kilnChargeCreateMutation, { data, loading, error }] = useKilnChargeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKilnChargeCreateMutation(baseOptions?: Apollo.MutationHookOptions<KilnChargeCreateMutation, KilnChargeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KilnChargeCreateMutation, KilnChargeCreateMutationVariables>(KilnChargeCreateDocument, options);
      }
export type KilnChargeCreateMutationHookResult = ReturnType<typeof useKilnChargeCreateMutation>;
export type KilnChargeCreateMutationResult = Apollo.MutationResult<KilnChargeCreateMutation>;
export type KilnChargeCreateMutationOptions = Apollo.BaseMutationOptions<KilnChargeCreateMutation, KilnChargeCreateMutationVariables>;
export const KilnChargeRemoveFromKilnDocument = gql`
    mutation KilnChargeRemoveFromKiln($input: KilnChargeRemoveFromKilnInput!) {
  kilnChargeRemoveFromKiln(input: $input)
}
    `;
export type KilnChargeRemoveFromKilnMutationFn = Apollo.MutationFunction<KilnChargeRemoveFromKilnMutation, KilnChargeRemoveFromKilnMutationVariables>;

/**
 * __useKilnChargeRemoveFromKilnMutation__
 *
 * To run a mutation, you first call `useKilnChargeRemoveFromKilnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKilnChargeRemoveFromKilnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kilnChargeRemoveFromKilnMutation, { data, loading, error }] = useKilnChargeRemoveFromKilnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKilnChargeRemoveFromKilnMutation(baseOptions?: Apollo.MutationHookOptions<KilnChargeRemoveFromKilnMutation, KilnChargeRemoveFromKilnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KilnChargeRemoveFromKilnMutation, KilnChargeRemoveFromKilnMutationVariables>(KilnChargeRemoveFromKilnDocument, options);
      }
export type KilnChargeRemoveFromKilnMutationHookResult = ReturnType<typeof useKilnChargeRemoveFromKilnMutation>;
export type KilnChargeRemoveFromKilnMutationResult = Apollo.MutationResult<KilnChargeRemoveFromKilnMutation>;
export type KilnChargeRemoveFromKilnMutationOptions = Apollo.BaseMutationOptions<KilnChargeRemoveFromKilnMutation, KilnChargeRemoveFromKilnMutationVariables>;
export const GetKilnChargesDocument = gql`
    query GetKilnCharges($ids: [ID!], $onlyActive: Boolean) {
  kilnCharges(ids: $ids, onlyActive: $onlyActive) {
    chargeNumber
    alternateChargeNumber
    id
    type
    inDate
    kilnIn
    kiln {
      name
    }
    kilnOut
    outDate
    thickness
    reference
    customer
    chargeItems {
      id
      packId
      gcsCode
      gcsId
      metersCubed
    }
  }
}
    `;

/**
 * __useGetKilnChargesQuery__
 *
 * To run a query within a React component, call `useGetKilnChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKilnChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKilnChargesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      onlyActive: // value for 'onlyActive'
 *   },
 * });
 */
export function useGetKilnChargesQuery(baseOptions?: Apollo.QueryHookOptions<GetKilnChargesQuery, GetKilnChargesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKilnChargesQuery, GetKilnChargesQueryVariables>(GetKilnChargesDocument, options);
      }
export function useGetKilnChargesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKilnChargesQuery, GetKilnChargesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKilnChargesQuery, GetKilnChargesQueryVariables>(GetKilnChargesDocument, options);
        }
export type GetKilnChargesQueryHookResult = ReturnType<typeof useGetKilnChargesQuery>;
export type GetKilnChargesLazyQueryHookResult = ReturnType<typeof useGetKilnChargesLazyQuery>;
export type GetKilnChargesQueryResult = Apollo.QueryResult<GetKilnChargesQuery, GetKilnChargesQueryVariables>;
export const KilnsDocument = gql`
    query Kilns {
  kilns {
    id
    name
    locationName
  }
}
    `;

/**
 * __useKilnsQuery__
 *
 * To run a query within a React component, call `useKilnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKilnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKilnsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKilnsQuery(baseOptions?: Apollo.QueryHookOptions<KilnsQuery, KilnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KilnsQuery, KilnsQueryVariables>(KilnsDocument, options);
      }
export function useKilnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KilnsQuery, KilnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KilnsQuery, KilnsQueryVariables>(KilnsDocument, options);
        }
export type KilnsQueryHookResult = ReturnType<typeof useKilnsQuery>;
export type KilnsLazyQueryHookResult = ReturnType<typeof useKilnsLazyQuery>;
export type KilnsQueryResult = Apollo.QueryResult<KilnsQuery, KilnsQueryVariables>;
export const GetCounterDocument = gql`
    query GetCounter($input: ID!) {
  counter(id: $input) {
    id
  }
}
    `;

/**
 * __useGetCounterQuery__
 *
 * To run a query within a React component, call `useGetCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCounterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCounterQuery(baseOptions: Apollo.QueryHookOptions<GetCounterQuery, GetCounterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCounterQuery, GetCounterQueryVariables>(GetCounterDocument, options);
      }
export function useGetCounterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCounterQuery, GetCounterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCounterQuery, GetCounterQueryVariables>(GetCounterDocument, options);
        }
export type GetCounterQueryHookResult = ReturnType<typeof useGetCounterQuery>;
export type GetCounterLazyQueryHookResult = ReturnType<typeof useGetCounterLazyQuery>;
export type GetCounterQueryResult = Apollo.QueryResult<GetCounterQuery, GetCounterQueryVariables>;
export const KilnChargeRemoveChargeDocument = gql`
    mutation KilnChargeRemoveCharge($input: KilnChargeRemoveChargeInput!) {
  kilnChargeRemoveCharge(input: $input)
}
    `;
export type KilnChargeRemoveChargeMutationFn = Apollo.MutationFunction<KilnChargeRemoveChargeMutation, KilnChargeRemoveChargeMutationVariables>;

/**
 * __useKilnChargeRemoveChargeMutation__
 *
 * To run a mutation, you first call `useKilnChargeRemoveChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKilnChargeRemoveChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kilnChargeRemoveChargeMutation, { data, loading, error }] = useKilnChargeRemoveChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKilnChargeRemoveChargeMutation(baseOptions?: Apollo.MutationHookOptions<KilnChargeRemoveChargeMutation, KilnChargeRemoveChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KilnChargeRemoveChargeMutation, KilnChargeRemoveChargeMutationVariables>(KilnChargeRemoveChargeDocument, options);
      }
export type KilnChargeRemoveChargeMutationHookResult = ReturnType<typeof useKilnChargeRemoveChargeMutation>;
export type KilnChargeRemoveChargeMutationResult = Apollo.MutationResult<KilnChargeRemoveChargeMutation>;
export type KilnChargeRemoveChargeMutationOptions = Apollo.BaseMutationOptions<KilnChargeRemoveChargeMutation, KilnChargeRemoveChargeMutationVariables>;
export const KilnChargeAddItemsDocument = gql`
    mutation KilnChargeAddItems($input: KilnChargeAddItemsInput!) {
  kilnChargeAddItems(input: $input)
}
    `;
export type KilnChargeAddItemsMutationFn = Apollo.MutationFunction<KilnChargeAddItemsMutation, KilnChargeAddItemsMutationVariables>;

/**
 * __useKilnChargeAddItemsMutation__
 *
 * To run a mutation, you first call `useKilnChargeAddItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKilnChargeAddItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kilnChargeAddItemsMutation, { data, loading, error }] = useKilnChargeAddItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKilnChargeAddItemsMutation(baseOptions?: Apollo.MutationHookOptions<KilnChargeAddItemsMutation, KilnChargeAddItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KilnChargeAddItemsMutation, KilnChargeAddItemsMutationVariables>(KilnChargeAddItemsDocument, options);
      }
export type KilnChargeAddItemsMutationHookResult = ReturnType<typeof useKilnChargeAddItemsMutation>;
export type KilnChargeAddItemsMutationResult = Apollo.MutationResult<KilnChargeAddItemsMutation>;
export type KilnChargeAddItemsMutationOptions = Apollo.BaseMutationOptions<KilnChargeAddItemsMutation, KilnChargeAddItemsMutationVariables>;
export const KilnChargeMoveToKilnDocument = gql`
    mutation KilnChargeMoveToKiln($input: KilnChargeMoveToKilnInput!) {
  kilnChargeMoveToKiln(input: $input)
}
    `;
export type KilnChargeMoveToKilnMutationFn = Apollo.MutationFunction<KilnChargeMoveToKilnMutation, KilnChargeMoveToKilnMutationVariables>;

/**
 * __useKilnChargeMoveToKilnMutation__
 *
 * To run a mutation, you first call `useKilnChargeMoveToKilnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKilnChargeMoveToKilnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kilnChargeMoveToKilnMutation, { data, loading, error }] = useKilnChargeMoveToKilnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKilnChargeMoveToKilnMutation(baseOptions?: Apollo.MutationHookOptions<KilnChargeMoveToKilnMutation, KilnChargeMoveToKilnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KilnChargeMoveToKilnMutation, KilnChargeMoveToKilnMutationVariables>(KilnChargeMoveToKilnDocument, options);
      }
export type KilnChargeMoveToKilnMutationHookResult = ReturnType<typeof useKilnChargeMoveToKilnMutation>;
export type KilnChargeMoveToKilnMutationResult = Apollo.MutationResult<KilnChargeMoveToKilnMutation>;
export type KilnChargeMoveToKilnMutationOptions = Apollo.BaseMutationOptions<KilnChargeMoveToKilnMutation, KilnChargeMoveToKilnMutationVariables>;
export const KilnChargeRemoveItemDocument = gql`
    mutation KilnChargeRemoveItem($input: KilnChargeRemoveItemInput!) {
  kilnChargeRemoveItem(input: $input)
}
    `;
export type KilnChargeRemoveItemMutationFn = Apollo.MutationFunction<KilnChargeRemoveItemMutation, KilnChargeRemoveItemMutationVariables>;

/**
 * __useKilnChargeRemoveItemMutation__
 *
 * To run a mutation, you first call `useKilnChargeRemoveItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKilnChargeRemoveItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kilnChargeRemoveItemMutation, { data, loading, error }] = useKilnChargeRemoveItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKilnChargeRemoveItemMutation(baseOptions?: Apollo.MutationHookOptions<KilnChargeRemoveItemMutation, KilnChargeRemoveItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KilnChargeRemoveItemMutation, KilnChargeRemoveItemMutationVariables>(KilnChargeRemoveItemDocument, options);
      }
export type KilnChargeRemoveItemMutationHookResult = ReturnType<typeof useKilnChargeRemoveItemMutation>;
export type KilnChargeRemoveItemMutationResult = Apollo.MutationResult<KilnChargeRemoveItemMutation>;
export type KilnChargeRemoveItemMutationOptions = Apollo.BaseMutationOptions<KilnChargeRemoveItemMutation, KilnChargeRemoveItemMutationVariables>;
export const GetChargeItemsDocument = gql`
    query GetChargeItems($kilnChargeId: ID!) {
  kilnCharge(id: $kilnChargeId) {
    id
    type
    chargeItems {
      id
      metersCubed
      kilnCharge {
        id
      }
      packId
    }
  }
}
    `;

/**
 * __useGetChargeItemsQuery__
 *
 * To run a query within a React component, call `useGetChargeItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargeItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargeItemsQuery({
 *   variables: {
 *      kilnChargeId: // value for 'kilnChargeId'
 *   },
 * });
 */
export function useGetChargeItemsQuery(baseOptions: Apollo.QueryHookOptions<GetChargeItemsQuery, GetChargeItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChargeItemsQuery, GetChargeItemsQueryVariables>(GetChargeItemsDocument, options);
      }
export function useGetChargeItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChargeItemsQuery, GetChargeItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChargeItemsQuery, GetChargeItemsQueryVariables>(GetChargeItemsDocument, options);
        }
export type GetChargeItemsQueryHookResult = ReturnType<typeof useGetChargeItemsQuery>;
export type GetChargeItemsLazyQueryHookResult = ReturnType<typeof useGetChargeItemsLazyQuery>;
export type GetChargeItemsQueryResult = Apollo.QueryResult<GetChargeItemsQuery, GetChargeItemsQueryVariables>;
export const RunsDocument = gql`
    query Runs($workstationId: ID, $workstationType: String, $untilDate: Date) {
  runs(
    workstationId: $workstationId
    workstationType: $workstationType
    untilDate: $untilDate
  ) {
    id
    date
    location {
      id
      name
    }
    infeed {
      packs {
        packId
      }
    }
    outfeed {
      packs {
        packId
      }
    }
    description
    workstation {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useRunsQuery__
 *
 * To run a query within a React component, call `useRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsQuery({
 *   variables: {
 *      workstationId: // value for 'workstationId'
 *      workstationType: // value for 'workstationType'
 *      untilDate: // value for 'untilDate'
 *   },
 * });
 */
export function useRunsQuery(baseOptions?: Apollo.QueryHookOptions<RunsQuery, RunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunsQuery, RunsQueryVariables>(RunsDocument, options);
      }
export function useRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunsQuery, RunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunsQuery, RunsQueryVariables>(RunsDocument, options);
        }
export type RunsQueryHookResult = ReturnType<typeof useRunsQuery>;
export type RunsLazyQueryHookResult = ReturnType<typeof useRunsLazyQuery>;
export type RunsQueryResult = Apollo.QueryResult<RunsQuery, RunsQueryVariables>;
export const GetInfeedPacksDocument = gql`
    query GetInfeedPacks($id: ID!) {
  run(id: $id) {
    id
    description
    location {
      name
    }
    infeed {
      packs {
        packId
        productCode
      }
    }
    type {
      id
      type
    }
  }
}
    `;

/**
 * __useGetInfeedPacksQuery__
 *
 * To run a query within a React component, call `useGetInfeedPacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfeedPacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfeedPacksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInfeedPacksQuery(baseOptions: Apollo.QueryHookOptions<GetInfeedPacksQuery, GetInfeedPacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfeedPacksQuery, GetInfeedPacksQueryVariables>(GetInfeedPacksDocument, options);
      }
export function useGetInfeedPacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfeedPacksQuery, GetInfeedPacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfeedPacksQuery, GetInfeedPacksQueryVariables>(GetInfeedPacksDocument, options);
        }
export type GetInfeedPacksQueryHookResult = ReturnType<typeof useGetInfeedPacksQuery>;
export type GetInfeedPacksLazyQueryHookResult = ReturnType<typeof useGetInfeedPacksLazyQuery>;
export type GetInfeedPacksQueryResult = Apollo.QueryResult<GetInfeedPacksQuery, GetInfeedPacksQueryVariables>;
export const RunAddInfeedPacksDocument = gql`
    mutation RunAddInfeedPacks($input: RunAddInfeedPacksInput!) {
  runAddInfeedPacks(input: $input) {
    success
    error
    errorCode
  }
}
    `;
export type RunAddInfeedPacksMutationFn = Apollo.MutationFunction<RunAddInfeedPacksMutation, RunAddInfeedPacksMutationVariables>;

/**
 * __useRunAddInfeedPacksMutation__
 *
 * To run a mutation, you first call `useRunAddInfeedPacksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunAddInfeedPacksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runAddInfeedPacksMutation, { data, loading, error }] = useRunAddInfeedPacksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunAddInfeedPacksMutation(baseOptions?: Apollo.MutationHookOptions<RunAddInfeedPacksMutation, RunAddInfeedPacksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunAddInfeedPacksMutation, RunAddInfeedPacksMutationVariables>(RunAddInfeedPacksDocument, options);
      }
export type RunAddInfeedPacksMutationHookResult = ReturnType<typeof useRunAddInfeedPacksMutation>;
export type RunAddInfeedPacksMutationResult = Apollo.MutationResult<RunAddInfeedPacksMutation>;
export type RunAddInfeedPacksMutationOptions = Apollo.BaseMutationOptions<RunAddInfeedPacksMutation, RunAddInfeedPacksMutationVariables>;
export const RunCompleteDocument = gql`
    mutation RunComplete($input: RunCompleteInput!) {
  runComplete(input: $input)
}
    `;
export type RunCompleteMutationFn = Apollo.MutationFunction<RunCompleteMutation, RunCompleteMutationVariables>;

/**
 * __useRunCompleteMutation__
 *
 * To run a mutation, you first call `useRunCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runCompleteMutation, { data, loading, error }] = useRunCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunCompleteMutation(baseOptions?: Apollo.MutationHookOptions<RunCompleteMutation, RunCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunCompleteMutation, RunCompleteMutationVariables>(RunCompleteDocument, options);
      }
export type RunCompleteMutationHookResult = ReturnType<typeof useRunCompleteMutation>;
export type RunCompleteMutationResult = Apollo.MutationResult<RunCompleteMutation>;
export type RunCompleteMutationOptions = Apollo.BaseMutationOptions<RunCompleteMutation, RunCompleteMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    givenName
    familyName
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetWorkstationsDocument = gql`
    query GetWorkstations {
  workstations {
    id
    name
    type
    labelPrinterId
  }
}
    `;

/**
 * __useGetWorkstationsQuery__
 *
 * To run a query within a React component, call `useGetWorkstationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkstationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkstationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkstationsQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkstationsQuery, GetWorkstationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkstationsQuery, GetWorkstationsQueryVariables>(GetWorkstationsDocument, options);
      }
export function useGetWorkstationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkstationsQuery, GetWorkstationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkstationsQuery, GetWorkstationsQueryVariables>(GetWorkstationsDocument, options);
        }
export type GetWorkstationsQueryHookResult = ReturnType<typeof useGetWorkstationsQuery>;
export type GetWorkstationsLazyQueryHookResult = ReturnType<typeof useGetWorkstationsLazyQuery>;
export type GetWorkstationsQueryResult = Apollo.QueryResult<GetWorkstationsQuery, GetWorkstationsQueryVariables>;
export const RunCreateDocument = gql`
    mutation RunCreate($input: RunCreateInput!) {
  runCreate(input: $input)
}
    `;
export type RunCreateMutationFn = Apollo.MutationFunction<RunCreateMutation, RunCreateMutationVariables>;

/**
 * __useRunCreateMutation__
 *
 * To run a mutation, you first call `useRunCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runCreateMutation, { data, loading, error }] = useRunCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunCreateMutation(baseOptions?: Apollo.MutationHookOptions<RunCreateMutation, RunCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunCreateMutation, RunCreateMutationVariables>(RunCreateDocument, options);
      }
export type RunCreateMutationHookResult = ReturnType<typeof useRunCreateMutation>;
export type RunCreateMutationResult = Apollo.MutationResult<RunCreateMutation>;
export type RunCreateMutationOptions = Apollo.BaseMutationOptions<RunCreateMutation, RunCreateMutationVariables>;
export const SuppliersDocument = gql`
    query Suppliers {
  suppliers: receiptSuppliers {
    id
    name
  }
}
    `;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
      }
export function useSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
        }
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const ScanPacksDocument = gql`
    mutation ScanPacks($input: ReceiptScanPacksInput!) {
  result: receiptScanPacks(input: $input) {
    scanEvent {
      id
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type ScanPacksMutationFn = Apollo.MutationFunction<ScanPacksMutation, ScanPacksMutationVariables>;

/**
 * __useScanPacksMutation__
 *
 * To run a mutation, you first call `useScanPacksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScanPacksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scanPacksMutation, { data, loading, error }] = useScanPacksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScanPacksMutation(baseOptions?: Apollo.MutationHookOptions<ScanPacksMutation, ScanPacksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScanPacksMutation, ScanPacksMutationVariables>(ScanPacksDocument, options);
      }
export type ScanPacksMutationHookResult = ReturnType<typeof useScanPacksMutation>;
export type ScanPacksMutationResult = Apollo.MutationResult<ScanPacksMutation>;
export type ScanPacksMutationOptions = Apollo.BaseMutationOptions<ScanPacksMutation, ScanPacksMutationVariables>;
export const GetStocktakesDocument = gql`
    query GetStocktakes($onlyActive: Boolean) {
  stocktakes(onlyActive: $onlyActive) {
    id
    dateStarted
    dateFinished
    location
  }
}
    `;

/**
 * __useGetStocktakesQuery__
 *
 * To run a query within a React component, call `useGetStocktakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStocktakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStocktakesQuery({
 *   variables: {
 *      onlyActive: // value for 'onlyActive'
 *   },
 * });
 */
export function useGetStocktakesQuery(baseOptions?: Apollo.QueryHookOptions<GetStocktakesQuery, GetStocktakesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStocktakesQuery, GetStocktakesQueryVariables>(GetStocktakesDocument, options);
      }
export function useGetStocktakesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStocktakesQuery, GetStocktakesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStocktakesQuery, GetStocktakesQueryVariables>(GetStocktakesDocument, options);
        }
export type GetStocktakesQueryHookResult = ReturnType<typeof useGetStocktakesQuery>;
export type GetStocktakesLazyQueryHookResult = ReturnType<typeof useGetStocktakesLazyQuery>;
export type GetStocktakesQueryResult = Apollo.QueryResult<GetStocktakesQuery, GetStocktakesQueryVariables>;
export const StocktakeCreateDocument = gql`
    mutation StocktakeCreate($input: StocktakeCreateInput!) {
  stocktakeCreate(input: $input)
}
    `;
export type StocktakeCreateMutationFn = Apollo.MutationFunction<StocktakeCreateMutation, StocktakeCreateMutationVariables>;

/**
 * __useStocktakeCreateMutation__
 *
 * To run a mutation, you first call `useStocktakeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStocktakeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stocktakeCreateMutation, { data, loading, error }] = useStocktakeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStocktakeCreateMutation(baseOptions?: Apollo.MutationHookOptions<StocktakeCreateMutation, StocktakeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StocktakeCreateMutation, StocktakeCreateMutationVariables>(StocktakeCreateDocument, options);
      }
export type StocktakeCreateMutationHookResult = ReturnType<typeof useStocktakeCreateMutation>;
export type StocktakeCreateMutationResult = Apollo.MutationResult<StocktakeCreateMutation>;
export type StocktakeCreateMutationOptions = Apollo.BaseMutationOptions<StocktakeCreateMutation, StocktakeCreateMutationVariables>;
export const GetStocktakeDocument = gql`
    query GetStocktake($input: ID!) {
  stocktake(id: $input) {
    id
    dateStarted
    dateFinished
    location
    addedPacks(mine: true) {
      id
      packId
      inventoryStatus
    }
  }
}
    `;

/**
 * __useGetStocktakeQuery__
 *
 * To run a query within a React component, call `useGetStocktakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStocktakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStocktakeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStocktakeQuery(baseOptions: Apollo.QueryHookOptions<GetStocktakeQuery, GetStocktakeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStocktakeQuery, GetStocktakeQueryVariables>(GetStocktakeDocument, options);
      }
export function useGetStocktakeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStocktakeQuery, GetStocktakeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStocktakeQuery, GetStocktakeQueryVariables>(GetStocktakeDocument, options);
        }
export type GetStocktakeQueryHookResult = ReturnType<typeof useGetStocktakeQuery>;
export type GetStocktakeLazyQueryHookResult = ReturnType<typeof useGetStocktakeLazyQuery>;
export type GetStocktakeQueryResult = Apollo.QueryResult<GetStocktakeQuery, GetStocktakeQueryVariables>;
export const AddStocktakePackDocument = gql`
    mutation AddStocktakePack($input: StocktakeAddPackInput!) {
  stocktakeAddPack(input: $input) {
    id
    packId
    inventoryStatus
  }
}
    `;
export type AddStocktakePackMutationFn = Apollo.MutationFunction<AddStocktakePackMutation, AddStocktakePackMutationVariables>;

/**
 * __useAddStocktakePackMutation__
 *
 * To run a mutation, you first call `useAddStocktakePackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStocktakePackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStocktakePackMutation, { data, loading, error }] = useAddStocktakePackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStocktakePackMutation(baseOptions?: Apollo.MutationHookOptions<AddStocktakePackMutation, AddStocktakePackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStocktakePackMutation, AddStocktakePackMutationVariables>(AddStocktakePackDocument, options);
      }
export type AddStocktakePackMutationHookResult = ReturnType<typeof useAddStocktakePackMutation>;
export type AddStocktakePackMutationResult = Apollo.MutationResult<AddStocktakePackMutation>;
export type AddStocktakePackMutationOptions = Apollo.BaseMutationOptions<AddStocktakePackMutation, AddStocktakePackMutationVariables>;
export const StocktakeFinishDocument = gql`
    mutation StocktakeFinish($input: StocktakeFinishInput!) {
  stocktakeFinish(input: $input)
}
    `;
export type StocktakeFinishMutationFn = Apollo.MutationFunction<StocktakeFinishMutation, StocktakeFinishMutationVariables>;

/**
 * __useStocktakeFinishMutation__
 *
 * To run a mutation, you first call `useStocktakeFinishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStocktakeFinishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stocktakeFinishMutation, { data, loading, error }] = useStocktakeFinishMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStocktakeFinishMutation(baseOptions?: Apollo.MutationHookOptions<StocktakeFinishMutation, StocktakeFinishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StocktakeFinishMutation, StocktakeFinishMutationVariables>(StocktakeFinishDocument, options);
      }
export type StocktakeFinishMutationHookResult = ReturnType<typeof useStocktakeFinishMutation>;
export type StocktakeFinishMutationResult = Apollo.MutationResult<StocktakeFinishMutation>;
export type StocktakeFinishMutationOptions = Apollo.BaseMutationOptions<StocktakeFinishMutation, StocktakeFinishMutationVariables>;